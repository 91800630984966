export const ERROR_RECEIVE_PROJECT = 'ERROR_RECEIVE_PROJECT';
export const ERROR_SAVE_PROJECT = 'ERROR_SAVE_PROJECT';
export const RECEIVE_PROJECT = 'RECEIVE_PROJECT';
export const REQUEST_PROJECT = 'REQUEST_PROJECT';
export const REQUEST_SAVE_PROJECT = 'REQUEST_SAVE_PROJECT';
export const SUCCESS_SAVE_PROJECT = 'SUCCESS_SAVE_PROJECT';

export const ERROR_UPDATE_PROJECT_DETAILS = 'ERROR_UPDATE_PROJECT_DETAILS';
export const REQUEST_UPDATE_PROJECT_DETAILS = 'REQUEST_UPDATE_PROJECT_DETAILS';
export const SUCCESS_UPDATE_PROJECT_DETAILS = 'SUCCESS_UPDATE_PROJECT_DETAILS';

export const RESET_REDUCER = 'RESET_REDUCER';
