const theme = {
  button: {
    primary: {
      color: {
        background: '#1192E8',
        text: '#FFFFFF',
      },
    },
    secondary: {
      color: {
        background: '#F8F8F8',
        text: '#111111',
      },
    },
  },
  card: {
    background: {
      edit: '#EBF1F6',
      error: '#F6CCCE',
      info: '#D9E5EE',
      paper: '#FFFFFF',
      success: '#D0E4D6',
      warning: '#FAEDD5',
    },
  },
  circularProgress: {
    color: '#1192E8',
  },
  colors: {
    black: '#111111',
    blueDark: '#D9E5EE',
    blueLight: '#EBF1F6',
    cobalt: '#1192E8',
    greenDark: '#2DC100',
    greenLight: '#D0E4D6',
    greyDark: '#777777',
    greyLight: '#E6E6E6',
    purpleLight: '#F3E5F5',
    redDark: '#D3000C',
    redLight: '#F6CCCE',
    white: '#FFFFFF',
    yellowDark: '#E9A631',
    yellowLight: '#FAEDD5',
  },
  header: {
    background: '#1192E8',
    height: 48,
  },
  hover: {
    background: 'rgba(0, 0, 0, 0.05)',
    backgroundLight: 'rgba(255, 255, 255, 0.15)',
    selected: {
      background: 'rgba(0, 0, 0, 0.10)',
    },
  },
  icon: {
    color: {
      button: '#FFFFFF',
      error: '#D3000C',
      header: '#FFFFFF',
      page: '#777777',
    },
  },
  iconButton: {
    border: {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
  link: {
    color: '#1C7FDB',
  },
  loading: {
    background: '#FFFFFF',
  },
  pageContainer: {
    border: '#E6E6E6',
    container: {
      background: '#F1F1F1',
    },
    content: {
      width: 1024,
    },
  },
  sideBar: {
    background: '#F1F1F1',
    border: '#E6E6E6',
    width: 220,
  },
  spacing: (x: number = 1) => x * 8,
  tabs: {
    background: '#FFFFFF',
  },
  typography: {
    color: {
      error: '#D3000C',
      info: '#1C7FDB',
      paper: '#FFFFFF',
      primary: '#111111',
      secondary: '#777777',
      success: '#007504',
      warning: '#E9A631',
    },
    variants: {
      capitalized: {
        fontFamily: 'sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0.03333em',
        lineHeight: 1.3,
        textTransform: 'capitalize',
      },
      caption: {
        fontFamily: 'sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.03333em',
        lineHeight: 1.3,
      },
      default: {
        fontFamily: 'sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0.03333em',
        lineHeight: 1.3,
      },
      subtitle: {
        fontFamily: 'sans-serif',
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '0.03333em',
        lineHeight: 1.3,
      },
      title: {
        fontFamily: 'sans-serif',
        fontSize: '20px',
        fontWeight: 400,
        letterSpacing: '0.03333em',
        lineHeight: 1.3,
      },
    },
  },
};

export default theme;
