import React from 'react';
import { makeStyles } from 'tss-react/mui';
import TableCell from '../TableCell';
import useTheme from 'hooks/useTheme';
import classNames from 'classnames';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  container: {
    visibility: 'hidden',
  },
}));

const TableCellActions = ({
  children,
}: IProps) => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);

  return (
    <TableCell>
      <div
        className={classNames(
          classes.container,
          'table-cell-actions'
        )}
      >
        {children}
      </div>
    </TableCell>
  );
};

interface IProps {
  children: React.ReactNode,
}

export default TableCellActions;
