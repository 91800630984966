import React from 'react';
import SvgIcon from '../SvgIcon';
import useTheme from 'hooks/useTheme';

/* eslint-disable max-len */
const Expense = ({
  color = 'page',
  size = 32,
}: IProps) => {
  const { theme } = useTheme();
  const actualColor = theme.icon.color[color] || color;
  return (
    <SvgIcon
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
      viewBox="0 0 24 24"
    >
      <g transform="translate(-709.000000, -65.000000)">
        <g transform="translate(709.000000, 65.000000)">
          <path
            fill={actualColor}
            d="M 18 23 H 4 c -1.1 0 -2 -0.9 -2 -2 V 7 h 2 v 14 h 14 v 2 Z M 15 1 H 8 c -1.1 0 -1.99 0.9 -1.99 2 L 6 17 c 0 1.1 0.89 2 1.99 2 H 19 c 1.1 0 2 -0.9 2 -2 V 7 l -6 -6 Z m 1.5 14 h -6 v -2 h 6 v 2 Z m 0 -6 h -2 v 2 h -2 V 9 h -2 V 7 h 2 V 5 h 2 v 2 h 2 v 2 Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

interface IProps {
  color?: 'header' | 'page' | string,
  size?: number,
}

export default Expense;
