import {
  RESET_REDUCER,
} from '../constants/actionTypes';

const resetReducer = () => (dispatch: any) => dispatch({
  type: RESET_REDUCER,
});

const forExport = {
  resetReducer,
};

export default forExport;
