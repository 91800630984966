import * as errorTypes from 'constants/errorTypes';
import {
  CHANGE_AUTHORITIES,
  ERROR_RECEIVE_USER,
  ERROR_SIGN_IN,
  ERROR_SIGN_OUT,
  RECEIVE_USER,
  REQUEST_SIGN_OUT,
  REQUEST_SIGN_IN,
  REQUEST_USER,
  SUCCESS_SIGN_IN,
  SUCCESS_SIGN_OUT,
} from '../constants/actionTypes';

const initialState = {
  authorities: [],
  email: '',
  errorType: '',
  id: '',
  isAuthorized: false,
  isFailedFetchUser: false,
  isFailedSignIn: false,
  isFailedSignOut: false,
  isFetchingSignOut: false,
  isFetchingUser: false,
  name: '',
};

type Action = {
  payload: any,
  type: string
}

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case ERROR_RECEIVE_USER: {
      return {
        ...state,
        isFailedFetchUser: true,
        isFetchingUser: false,
      };
    }
    case ERROR_SIGN_IN: {
      let errorType = initialState.errorType;
      if (action.payload?.response?.status === 403) {
        errorType = errorTypes.UNAUTHORIZED;
      } else {
        errorType = errorTypes.INTERNAL_SERVER_ERROR;
      }
      return {
        ...state,
        errorType,
        isFailedSignIn: true,
        isFetchingUser: false,
      };
    }

    case ERROR_SIGN_OUT: {
      return {
        ...state,
        isFailedSignOut: true,
        isFetchingSignOut: false,
      };
    }

    case RECEIVE_USER:
    case SUCCESS_SIGN_IN: {
      const user = action.payload;

      return {
        ...state,
        authorities: user.authorities || initialState.authorities,
        email: user.email || initialState.email,
        id: user.id || initialState.id,
        isAuthorized: true,
        isFailedFetchUser: false,
        isFailedSignIn: false,
        isFetchingUser: false,
        name: user.name || initialState.name,
      };
    }

    case REQUEST_SIGN_OUT: {
      return {
        ...state,
        isFailedSignOut: false,
        isFetchingSignOut: true,
      };
    }

    case REQUEST_SIGN_IN:
    case REQUEST_USER: {
      return {
        ...state,
        errorType: initialState.errorType,
        isFailedFetchUser: false,
        isFailedSignIn: false,
        isFetchingUser: true,
      };
    }

    case SUCCESS_SIGN_OUT: {
      return initialState;
    }

    case CHANGE_AUTHORITIES: {
      const authorities = action.payload;
      return {
        ...state,
        authorities,
      };
    }

    default: {
      return state;
    }
  }
}
