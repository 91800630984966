import {
  ERROR_CREATE_ITEM,
  ERROR_DELETE_ITEM,
  ERROR_RECEIVE_ITEMS,
  ERROR_UPDATE_ITEM,
  RECEIVE_ITEMS,
  REQUEST_CREATE_ITEM,
  REQUEST_DELETE_ITEM,
  REQUEST_ITEMS,
  REQUEST_UPDATE_ITEM,
  RESET_REDUCERS_DATA,
  SUCCESS_CREATE_ITEM,
  SUCCESS_DELETE_ITEM,
  SUCCESS_UPDATE_ITEM,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  errorMessage: '',
  isFailed: false,
  isFailedCreate: false,
  isFailedDelete: false,
  isFailedUpdate: false,
  isFetching: false,
  isFetchingCreate: false,
  isFetchingDelete: false,
  isFetchingUpdate: false,
  isFinishedCreate: false,
  isFinishedDelete: false,
  isFinishedUpdate: false,
  list: [],
};

const convertItem = (item: any) => ({
  active: item.active,
  id: item.id,
  name: item.name,
});

const convertItems = (input: any) => input
  .map((item: any) => convertItem(item));

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_ITEMS: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_ITEMS: {
      const list = convertItems(action.payload);
      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list: list,
      };
    }

    case ERROR_RECEIVE_ITEMS: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_CREATE_ITEM: {
      return {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: true,
        isFinishedCreate: false,
      };
    }

    case SUCCESS_CREATE_ITEM: {
      const list = convertItems(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: false,
        isFinishedCreate: true,
        list,
      };
    }

    case ERROR_CREATE_ITEM: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;
      return {
        ...state,
        errorMessage,
        isFailedCreate: true,
        isFetchingCreate: false,
        isFinishedCreate: true,
      };
    }

    case REQUEST_UPDATE_ITEM: {
      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: true,
        isFinishedUpdate: false,
      };
    }

    case SUCCESS_UPDATE_ITEM: {
      const list = convertItems(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
        list,
      };
    }

    case ERROR_UPDATE_ITEM: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;
      return {
        ...state,
        errorMessage,
        isFailedUpdate: true,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
      };
    }

    case REQUEST_DELETE_ITEM: {
      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: true,
        isFinishedDelete: false,
      };
    }

    case SUCCESS_DELETE_ITEM: {
      const list = convertItems(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: false,
        isFinishedDelete: true,
        list,
      };
    }

    case ERROR_DELETE_ITEM: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;
      return {
        ...state,
        errorMessage,
        isFailedDelete: true,
        isFetchingDelete: false,
        isFinishedDelete: true,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
}
