export const REQUEST_AUTHORITIES = 'REQUEST_AUTHORITIES';
export const RECEIVE_AUTHORITIES = 'RECEIVE_AUTHORITIES';
export const ERROR_RECEIVE_AUTHORITIES = 'ERROR_RECEIVE_AUTHORITIES';

export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const ERROR_RECEIVE_USERS = 'ERROR_RECEIVE_USERS';

export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER';
export const SUCCESS_CREATE_USER = 'SUCCESS_CREATE_USER';
export const ERROR_CREATE_USER = 'ERROR_CREATE_USER';

export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';
export const SUCCESS_UPDATE_USER = 'SUCCESS_UPDATE_USER';
export const ERROR_UPDATE_USER = 'ERROR_UPDATE_USER';

export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER';
export const SUCCESS_DELETE_USER = 'SUCCESS_DELETE_USER';
export const ERROR_DELETE_USER = 'ERROR_DELETE_USER';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
