import React from 'react';
import SvgIcon from '../SvgIcon';
import useTheme from 'hooks/useTheme';

/* eslint-disable max-len */
const Download = ({
  color = 'page',
  size = 32,
}: IProps) => {
  const { theme } = useTheme();
  const actualColor = theme.icon.color[color] || color;
  return (
    <SvgIcon
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
      viewBox="0 0 24 24"
    >
      <path
        d="M 18 15 v 3 H 6 v -3 H 4 v 3 c 0 1.1 0.9 2 2 2 h 12 c 1.1 0 2 -0.9 2 -2 v -3 h -2 Z m -1 -4 l -1.41 -1.41 L 13 12.17 V 4 h -2 v 8.17 L 8.41 9.59 L 7 11 l 5 5 l 5 -5 Z"
        fill={actualColor}
      />
    </SvgIcon>
  );
};

interface IProps {
  color?: 'header' | 'page' | string,
  size?: number,
}

export default Download;
