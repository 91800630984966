/* eslint-disable max-len */

export const REQUEST_INVOICES = 'REQUEST_INVOICES';
export const RECEIVE_INVOICES = 'RECEIVE_INVOICES';
export const ERROR_RECEIVE_INVOICES = 'ERROR_RECEIVE_INVOICES';

export const REQUEST_REFRESH_INVOICES = 'REQUEST_REFRESH_INVOICES';
export const SUCCESS_REFRESH_INVOICES = 'SUCCESS_REFRESH_INVOICES';
export const ERROR_REFRESH_INVOICES = 'ERROR_REFRESH_INVOICES';

export const REQUEST_REFRESH_INVOICE = 'REQUEST_REFRESH_INVOICE';
export const SUCCESS_REFRESH_INVOICE = 'SUCCESS_REFRESH_INVOICE';
export const ERROR_REFRESH_INVOICE = 'ERROR_REFRESH_INVOICE';

export const REQUEST_UPDATE_INVOICE_STATUS = 'REQUEST_UPDATE_INVOICE_STATUS';
export const SUCCESS_UPDATE_INVOICE_STATUS = 'SUCCESS_UPDATE_INVOICE_STATUS';
export const ERROR_UPDATE_INVOICE_STATUS = 'ERROR_UPDATE_INVOICE_STATUS';

export const REQUEST_DOWNLOAD_CALCULATION = 'REQUEST_DOWNLOAD_CALCULATION';
export const SUCCESS_DOWNLOAD_CALCULATION = 'SUCCESS_DOWNLOAD_CALCULATION';
export const ERROR_DOWNLOAD_CALCULATION = 'ERROR_DOWNLOAD_CALCULATION';

export const REQUEST_DOWNLOAD_INVOICE = 'REQUEST_DOWNLOAD_INVOICE';
export const SUCCESS_DOWNLOAD_INVOICE = 'SUCCESS_DOWNLOAD_INVOICE';
export const ERROR_DOWNLOAD_INVOICE = 'ERROR_DOWNLOAD_INVOICE';

export const REQUEST_INVOICE_CHANGE_HISTORY = 'REQUEST_INVOICE_CHANGE_HISTORY';
export const RECEIVE_INVOICE_CHANGE_HISTORY = 'RECEIVE_INVOICE_CHANGE_HISTORY';
export const ERROR_RECEIVE_INVOICE_CHANGE_HISTORY = 'ERROR_RECEIVE_INVOICE_CHANGE_HISTORY';

export const REQUEST_DELETE_INVOICE = 'REQUEST_DELETE_INVOICE';
export const SUCCESS_DELETE_INVOICE = 'SUCCESS_DELETE_INVOICE';
export const ERROR_DELETE_INVOICE = 'ERROR_DELETE_INVOICE';

export const REQUEST_INVOICE_ITEM = 'REQUEST_INVOICE_ITEM';
export const RECEIVE_INVOICE_ITEM = 'RECEIVE_INVOICE_ITEM';
export const ERROR_RECEIVE_INVOICE_ITEM = 'ERROR_RECEIVE_INVOICE_ITEM';

export const REQUEST_UPDATE_INVOICE_ITEM = 'REQUEST_UPDATE_INVOICE_ITEM';
export const SUCCESS_UPDATE_INVOICE_ITEM = 'SUCCESS_UPDATE_INVOICE_ITEM';
export const ERROR_UPDATE_INVOICE_ITEM = 'ERROR_UPDATE_INVOICE_ITEM';

export const CLEAR_AFTER_FETCH_STATUS = 'CLEAR_AFTER_FETCH_STATUS';
export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
