import {
  CLEAR_AFTER_FETCH_STATUS,
  ERROR_DELETE_INVOICE,
  ERROR_DOWNLOAD_CALCULATION,
  ERROR_DOWNLOAD_INVOICE,
  ERROR_RECEIVE_INVOICE_CHANGE_HISTORY,
  ERROR_RECEIVE_INVOICE_ITEM,
  ERROR_RECEIVE_INVOICES,
  ERROR_REFRESH_INVOICE,
  ERROR_REFRESH_INVOICES,
  ERROR_UPDATE_INVOICE_ITEM,
  ERROR_UPDATE_INVOICE_STATUS,
  RECEIVE_INVOICE_CHANGE_HISTORY,
  RECEIVE_INVOICE_ITEM,
  RECEIVE_INVOICES,
  REQUEST_DELETE_INVOICE,
  REQUEST_DOWNLOAD_CALCULATION,
  REQUEST_DOWNLOAD_INVOICE,
  REQUEST_INVOICE_CHANGE_HISTORY,
  REQUEST_INVOICE_ITEM,
  REQUEST_INVOICES,
  REQUEST_REFRESH_INVOICE,
  REQUEST_REFRESH_INVOICES,
  REQUEST_UPDATE_INVOICE_ITEM,
  REQUEST_UPDATE_INVOICE_STATUS,
  RESET_REDUCERS_DATA,
  SUCCESS_DELETE_INVOICE,
  SUCCESS_DOWNLOAD_CALCULATION,
  SUCCESS_DOWNLOAD_INVOICE,
  SUCCESS_REFRESH_INVOICE,
  SUCCESS_REFRESH_INVOICES,
  SUCCESS_UPDATE_INVOICE_ITEM,
  SUCCESS_UPDATE_INVOICE_STATUS,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const getInvoiceSum = (items: any) => {
  const sum = (items || [])
    .reduce((acc: number, item: any) => item.sum
      ? acc + item.sum
      : acc, 0);
  return +Number(sum).toFixed(2);
};

const getTotalHours = (items: any) => {
  const totalHours = (items || [])
    .reduce((acc: number, item: any) => item.hours
      ? acc + item.hours
      : acc, 0);
  return +Number(totalHours).toFixed(1);
};

const convertInvoiceItem = (item: any) => ({
  contractorName: item.contractorName,
  currency: item.currency,
  hours: item.hours,
  id: item.id,
  issue: item.issue,
  sourceHours: item.sourceHours,
  sum: item.sum,
});

const convertInvoice = (invoice: any) => ({
  id: invoice.id,
  items: (invoice.items || [])
    .map((item: any) => convertInvoiceItem(item)),
  number: invoice.invoiceNumber,
  status: invoice.status,
  sum: getInvoiceSum(invoice.items),
  title: invoice.title,
  totalHours: getTotalHours(invoice.items),
});

const initialState = {
  changes: [],
  isFailed: false,
  isFailedDeleteInvoice: false,
  isFailedDownloadCalculation: false,
  isFailedDownloadInvoice: false,
  isFailedInvoiceChangeHistory: false,
  isFailedInvoiceItemDetails: false,
  isFailedRefresh: false,
  isFailedRefreshInvoice: false,
  isFailedUpdateInvoiceItem: false,
  isFailedUpdateStatus: false,
  isFetching: false,
  isFetchingDeleteInvoice: false,
  isFetchingDownloadCalculation: false,
  isFetchingDownloadInvoice: false,
  isFetchingInvoiceChangeHistory: false,
  isFetchingInvoiceItemDetails: false,
  isFetchingRefresh: false,
  isFetchingRefreshInvoice: false,
  isFetchingUpdateInvoiceItem: false,
  isFetchingUpdateStatus: false,
  isFinishedInvoiceChangeHistory: false,
  isFinishedInvoiceItemDetails: false,
  isSuccessDeleteInvoice: false,
  isSuccessRefresh: false,
  isSuccessRefreshInvoice: false,
  isSuccessUpdateInvoiceItem: false,
  isSuccessUpdateStatus: false,
  itemDetails: {},
  list: [],
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_REFRESH_INVOICES: {
      return {
        ...state,
        isFailedRefresh: false,
        isFetchingRefresh: true,
        isSuccessRefresh: false,
      };
    }

    case SUCCESS_REFRESH_INVOICES: {
      return {
        ...state,
        isFailedRefresh: false,
        isFetchingRefresh: false,
        isSuccessRefresh: true,
      };
    }

    case ERROR_REFRESH_INVOICES: {
      return {
        ...state,
        isFailedRefresh: true,
        isFetchingRefresh: false,
        isSuccessRefresh: false,
      };
    }

    case REQUEST_INVOICES: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_INVOICES: {
      const invoices = action.payload;

      const list = invoices.map((invoice: any) => convertInvoice(invoice));

      return {
        ...state,
        isFetching: false,
        list,
      };
    }

    case ERROR_RECEIVE_INVOICES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_UPDATE_INVOICE_STATUS: {
      return {
        ...state,
        isFailedUpdateStatus: false,
        isFetchingUpdateStatus: true,
        isSuccessUpdateStatus: false,
      };
    }

    case SUCCESS_UPDATE_INVOICE_STATUS: {
      const invoice = action.payload;

      const updatedInvoice = convertInvoice(invoice);
      const updatedList = state.list
        .map((invoice: any) => invoice.id === updatedInvoice.id
          ? updatedInvoice
          : invoice);

      return {
        ...state,
        isFailedUpdateStatus: false,
        isFetchingUpdateStatus: false,
        isSuccessUpdateStatus: true,
        list: updatedList,
      };
    }

    case ERROR_UPDATE_INVOICE_STATUS: {
      return {
        ...state,
        isFailedUpdateStatus: true,
        isFetchingUpdateStatus: false,
        isSuccessUpdateStatus: false,
      };
    }

    case REQUEST_REFRESH_INVOICE: {
      return {
        ...state,
        isFailedRefreshInvoice: false,
        isFetchingRefreshInvoice: true,
        isSuccessRefreshInvoice: false,
      };
    }

    case SUCCESS_REFRESH_INVOICE: {
      const invoice = action.payload;

      const updatedInvoice = convertInvoice(invoice);
      const updatedList = state.list
        .map((invoice: any) => invoice.id === updatedInvoice.id
          ? updatedInvoice
          : invoice);

      return {
        ...state,
        isFailedRefreshInvoice: false,
        isFetchingRefreshInvoice: false,
        isSuccessRefreshInvoice: true,
        list: updatedList,
      };
    }

    case ERROR_REFRESH_INVOICE: {
      return {
        ...state,
        isFailedRefreshInvoice: true,
        isFetchingRefreshInvoice: false,
        isSuccessRefreshInvoice: false,
      };
    }

    case REQUEST_DOWNLOAD_CALCULATION: {
      return {
        ...state,
        isFailedDownloadCalculation: false,
        isFetchingDownloadCalculation: true,
      };
    }

    case SUCCESS_DOWNLOAD_CALCULATION: {
      return {
        ...state,
        isFailedDownloadCalculation: false,
        isFetchingDownloadCalculation: false,
      };
    }

    case ERROR_DOWNLOAD_CALCULATION: {
      return {
        ...state,
        isFailedDownloadCalculation: true,
        isFetchingDownloadCalculation: false,
      };
    }

    case REQUEST_DOWNLOAD_INVOICE: {
      return {
        ...state,
        isFailedDownloadInvoice: false,
        isFetchingDownloadInvoice: true,
      };
    }

    case SUCCESS_DOWNLOAD_INVOICE: {
      return {
        ...state,
        isFailedDownloadInvoice: false,
        isFetchingDownloadInvoice: false,
      };
    }

    case ERROR_DOWNLOAD_INVOICE: {
      return {
        ...state,
        isFailedDownloadInvoice: true,
        isFetchingDownloadInvoice: false,
      };
    }

    case REQUEST_DELETE_INVOICE: {
      return {
        ...state,
        isFailedDeleteInvoice: false,
        isFetchingDeleteInvoice: true,
        isSuccessDeleteInvoice: false,
      };
    }

    case SUCCESS_DELETE_INVOICE: {
      const invoices = action.payload;
      const list = invoices.map((invoice: any) => convertInvoice(invoice));

      return {
        ...state,
        isFailedDeleteInvoice: false,
        isFetchingDeleteInvoice: false,
        isSuccessDeleteInvoice: true,
        list,
      };
    }

    case ERROR_DELETE_INVOICE: {
      return {
        ...state,
        isFailedDeleteInvoice: true,
        isFetchingDeleteInvoice: false,
        isSuccessDeleteInvoice: false,
      };
    }

    case REQUEST_INVOICE_ITEM: {
      return {
        ...state,
        isFailedInvoiceItemDetails: false,
        isFetchingInvoiceItemDetails: true,
        isFinishedInvoiceItemDetails: false,
      };
    }

    case RECEIVE_INVOICE_ITEM: {
      const invoiceItem = action.payload;
      const itemDetails = {
        contractorName: invoiceItem.contractorName,
        hours: invoiceItem.hours,
        id: invoiceItem.id,
        payrollHours: invoiceItem.payrollHours,
        sourceHours: invoiceItem.sourceHours,
      };

      return {
        ...state,
        isFetchingInvoiceItemDetails: false,
        isFinishedInvoiceItemDetails: true,
        itemDetails,
      };
    }

    case ERROR_RECEIVE_INVOICE_ITEM: {
      return {
        ...state,
        isFailedInvoiceItemDetails: true,
        isFetchingInvoiceItemDetails: false,
        isFinishedInvoiceItemDetails: true,
      };
    }

    case REQUEST_INVOICE_CHANGE_HISTORY: {
      return {
        ...state,
        changes: initialState.changes,
        isFailedInvoiceChangeHistory: false,
        isFetchingInvoiceChangeHistory: true,
        isFinishedInvoiceChangeHistory: false,
      };
    }

    case RECEIVE_INVOICE_CHANGE_HISTORY: {
      const changeHistory = action.payload;

      const changes = changeHistory
        .reverse()
        .map((entry: any) => ({
          action: entry.action,
          description: entry.changeDescription,
          status: entry.status,
          timestamp: entry.date,
          title: entry.title,
          user: entry.user,
        }));

      return {
        ...state,
        changes,
        isFailedInvoiceChangeHistory: false,
        isFetchingInvoiceChangeHistory: false,
        isFinishedInvoiceChangeHistory: true,
      };
    }

    case ERROR_RECEIVE_INVOICE_CHANGE_HISTORY: {
      return {
        ...state,
        isFailedInvoiceChangeHistory: true,
        isFetchingInvoiceChangeHistory: false,
        isFinishedInvoiceChangeHistory: true,
      };
    }

    case REQUEST_UPDATE_INVOICE_ITEM: {
      return {
        ...state,
        isFailedUpdateInvoiceItem: false,
        isFetchingUpdateInvoiceItem: true,
        isSuccessUpdateInvoiceItem: false,
      };
    }

    case SUCCESS_UPDATE_INVOICE_ITEM: {
      const invoice = action.payload;
      const updatedInvoice = convertInvoice(invoice);
      const updatedList = state.list
        .map((invoice: any) => invoice.id === updatedInvoice.id
          ? updatedInvoice
          : invoice);

      return {
        ...state,
        isFailedUpdateInvoiceItem: false,
        isFetchingUpdateInvoiceItem: false,
        isSuccessUpdateInvoiceItem: true,
        list: updatedList,
      };
    }

    case ERROR_UPDATE_INVOICE_ITEM: {
      return {
        ...state,
        isFailedUpdateInvoiceItem: true,
        isFetchingUpdateInvoiceItem: false,
        isSuccessUpdateInvoiceItem: false,
      };
    }

    case CLEAR_AFTER_FETCH_STATUS: {
      return {
        ...state,
        isFailedDeleteInvoice: false,
        isFailedInvoiceItemDetails: false,
        isFailedRefresh: false,
        isFailedRefreshInvoice: false,
        isFailedUpdateInvoiceItem: false,
        isFailedUpdateStatus: false,
        isSuccessDeleteInvoice: false,
        isSuccessRefresh: false,
        isSuccessRefreshInvoice: false,
        isSuccessUpdateInvoiceItem: false,
        isSuccessUpdateStatus: false,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
