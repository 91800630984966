import { useEffect, useState } from 'react';

function Scheduler({
  action,
  interval = 1000,
  status,
}: IProps) {
  const [state, setState] = useState({
    timer: null,
  });

  useEffect(() => {
    if (status === 'active' && !state.timer) {
      const timerId = setInterval(action, interval);
      setState((prevState : any) => ({
        ...prevState,
        timer: timerId,
      }));
    } else if (status === 'inactive' && state.timer) {
      clearInterval(state.timer);
      setState(prevState => ({
        ...prevState,
        timer: null,
      }));
    }
  }, [status]);

  return null;
}


interface IProps {
  action: () => (event: any) => void,
  interval?: number,
  status: 'active' | 'inactive',
}

export default Scheduler;
