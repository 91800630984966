import {
  ERROR_RECEIVE_CONTRACTORS,
  ERROR_REFRESH_CONTRACTORS,
  RECEIVE_CONTRACTORS,
  REQUEST_CONTRACTORS,
  REQUEST_REFRESH_CONTRACTORS,
  RESET_REDUCERS_DATA,
  SUCCESS_REFRESH_CONTRACTORS,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  isFailed: false,
  isFailedRefresh: false,
  isFetching: false,
  isFetchingRefresh: false,
  isSuccessRefresh: false,
  list: [],
  totalCount: 0,
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_CONTRACTORS: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_CONTRACTORS: {
      const {
        list: contractors,
        totalSize,
      } = action.payload;

      const details = contractors.map((contractor: any) => ({
        documentsLink: contractor.documentsLink,
        email: contractor.email,
        id: contractor.id,
        name: contractor.name,
        phone: contractor.phone,
        status: contractor.status,
      }));

      return {
        ...state,
        isFetching: false,
        list: details,
        totalCount: totalSize,
      };
    }

    case ERROR_RECEIVE_CONTRACTORS: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_REFRESH_CONTRACTORS: {
      return {
        ...state,
        isFailedRefresh: false,
        isFetchingRefresh: true,
        isSuccessRefresh: false,
      };
    }

    case SUCCESS_REFRESH_CONTRACTORS: {
      return {
        ...state,
        isFailedRefresh: false,
        isFetchingRefresh: false,
        isSuccessRefresh: true,
      };
    }

    case ERROR_REFRESH_CONTRACTORS: {
      return {
        ...state,
        isFailedRefresh: true,
        isFetchingRefresh: false,
        isSuccessRefresh: false,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
