import React from 'react';
import MuiDivider from '@mui/material/Divider';

const Divider = ({ light = false }: IProps) => {
  return (
    <MuiDivider light={light} />
  );
};

interface IProps {
  light?: boolean,
}

export default Divider;
