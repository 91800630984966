import React from 'react';
import TableSortLabelMui from '@mui/material/TableSortLabel';

const TableSortLabel = ({
  active = false,
  children,
  direction = 'asc',
  hideSortIcon = false,
  onClick,
}: IProps) => {
  return (
    <TableSortLabelMui
      active={active}
      direction={direction}
      hideSortIcon={hideSortIcon}
      onClick={onClick}
    >
      {children}
    </TableSortLabelMui>
  );
};

TableSortLabel.pswName = 'TableSortLabel';

interface IProps {
  active?: boolean,
  children: React.ReactNode | React.ReactNode[];
  direction?: 'asc' | 'desc',
  hideSortIcon?: boolean,
  onClick?: (event: any) => void,
}

export default TableSortLabel;
