import {
  ERROR_DOWNLOAD_REPORT,
  ERROR_EXPORT_REPORT,
  ERROR_RECEIVE_REPORTS,
  RECEIVE_REPORTS,
  REQUEST_DOWNLOAD_REPORT,
  REQUEST_EXPORT_REPORT,
  REQUEST_REPORTS,
  RESET_REDUCERS_DATA,
  SUCCESS_DOWNLOAD_REPORT,
  SUCCESS_EXPORT_REPORT,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}


const initialState = {
  errorMessage: '',
  exportedReportFolderLink: '',
  isFailed: false,
  isFailedDownload: false,
  isFailedExport: false,
  isFetching: false,
  isFetchingDownload: false,
  isFetchingExport: false,
  isSuccessExport: false,
  list: [],
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case ERROR_RECEIVE_REPORTS: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case RECEIVE_REPORTS: {
      const reports = action.payload;
      const list = reports
        .map((report: any) => ({
          dateRange: report.reportInterval,
          description: report.description,
          exportable: report.exportable,
          id: report.reportType,
        }));

      return {
        ...state,
        isFetching: false,
        list,
      };
    }

    case REQUEST_EXPORT_REPORT: {
      return {
        ...state,
        isFailedExport: false,
        isFetchingExport: true,
        isSuccessExport: false,
      };
    }

    case SUCCESS_EXPORT_REPORT: {
      const { folderLink } = action.payload;
      return {
        ...state,
        errorMessage: '',
        exportedReportFolderLink: folderLink,
        isFailedExport: false,
        isFetchingExport: false,
        isSuccessExport: true,
      };
    }

    case ERROR_EXPORT_REPORT: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedExport: true,
        isFetchingExport: false,
        isSuccessExport: false,
      };
    }

    case REQUEST_REPORTS: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case ERROR_DOWNLOAD_REPORT: {
      const { message } = action.payload;

      return {
        ...state,
        errorMessage: message,
        isFailedDownload: true,
        isFetchingDownload: false,
      };
    }

    case SUCCESS_DOWNLOAD_REPORT: {
      return {
        ...state,
        errorMessage: '',
        isFailedDownload: false,
        isFetchingDownload: false,
      };
    }

    case REQUEST_DOWNLOAD_REPORT: {
      return {
        ...state,
        errorMessage: '',
        isFailedDownload: false,
        isFetchingDownload: true,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
