export const CONFIRMED = 'CONFIRMED';
export const NEW = 'NEW';
export const WITH_ISSUES = 'WITH_ISSUES';

const list: { [key: string]: string } = {
  CONFIRMED,
  NEW,
  WITH_ISSUES,
};

export default list;
