import { makeStyles } from 'tss-react/mui';
import React from 'react';
import IconHome from 'components/icons/Home';
import useTheme from 'hooks/useTheme';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
  label: {
    color: '#FFFFFF',
    fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  labelCompactContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelCompactMain: {
    color: '#FFFFFF',
    fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
  labelCompactSub: {
    color: '#FFFFFF',
    fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
  },
}));

function Logo({
  compact = false,
}: IProps) {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  return (
    <div className={classes.container}>
      {!compact && (
        <IconHome color="header"/>
      )}
      {!compact && (
        <div className={classes.label}>
          <strong>
            ProfITsoft Workspace
          </strong>
        </div>
      )}
      {compact && (
        <div className={classes.labelCompactContainer}>
          <div className={classes.labelCompactMain}>
            <strong>
              ProfITsoft
            </strong>
          </div>
          <div className={classes.labelCompactSub}>
            Workspace
          </div>
        </div>
      )}
    </div>
  );
}

interface IProps {
  compact?: boolean,
}

export default Logo;
