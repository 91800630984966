import React from 'react';
import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';
import useTheme from 'hooks/useTheme';
import useIsMobile from 'hooks/useIsMobile';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  bottomStub: {
    height: '16px',
  },
  container: {
    background: theme.pageContainer.container.background,
    display: 'flex',
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.pageContainer.content.width,
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    overflowX: 'auto',
    width: '100%',
  },
  fullWidth: {
    maxWidth: '100% !important',
  },
  innerContent: {
    height: '100%',
    padding: `${theme.spacing(2)}px`,
  },
  sideBarStub: {
    minWidth: `${theme.sideBar.width}px`,
    width: '20%',
  },
}));

function PageContainer({
  children,
  fullWidth = false,
}: IProps) {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const isMobile = useIsMobile();
  return (
    <div className={classes.container}>
      {!isMobile && (
        <div className={classes.sideBarStub} />
      )}
      <div className={classes.contentContainer}>
        <div
          className={classNames(
            classes.content,
            fullWidth && classes.fullWidth
          )}
        >
          <div className={classes.innerContent}>
            {children}
            <div className={classes.bottomStub} />
          </div>
        </div>
      </div>
    </div>
  );
}

interface IProps {
  children: React.ReactNode,
  fullWidth?: boolean,
}

export default PageContainer;
