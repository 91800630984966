import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import ExpensesPage from 'pages/expenses';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';

function Expenses() {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.EXPENSES}>
        <ExpensesPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default Expenses;
