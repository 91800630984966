// User
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const ERROR_RECEIVE_USER = 'ERROR_RECEIVE_USER';

export const REQUEST_SIGN_IN = 'REQUEST_SIGN_IN';
export const SUCCESS_SIGN_IN = 'SUCCESS_SIGN_IN';
export const ERROR_SIGN_IN = 'ERROR_SIGN_IN';

export const REQUEST_SIGN_UP = 'REQUEST_SIGN_UP';
export const SUCCESS_SIGN_UP = 'SUCCESS_SIGN_UP';
export const ERROR_SIGN_UP = 'ERROR_SIGN_UP';

export const REQUEST_SIGN_OUT = 'REQUEST_SIGN_OUT';
export const SUCCESS_SIGN_OUT = 'SUCCESS_SIGN_OUT';
export const ERROR_SIGN_OUT = 'ERROR_SIGN_OUT';

export const CHANGE_AUTHORITIES = 'CHANGE_AUTHORITIES';
