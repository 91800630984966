import React, { useEffect, useMemo, useState } from 'react';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import Chip from 'components/Chip';
import CircularProgress from 'components/CircularProgress';
import Dialog from 'components/Dialog';
import Divider from 'components/Divider';
import IconActions from 'components/icons/Actions';
import IconArticle from 'components/icons/Article';
import IconButton from 'components/IconButton';
import IconChart from 'components/icons/Chart';
import IconClose from 'components/icons/Close';
import IconDelete from 'components/icons/Delete';
import IconEdit from 'components/icons/Edit';
import IconError from 'components/icons/Error';
import IconExpandMore from 'components/icons/ExpandMore';
import IconHistory from 'components/icons/History';
import IconInfo from 'components/icons/Info';
import IconRefresh from 'components/icons/Refresh';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import moment from 'moment';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useIsMobile from 'hooks/useIsMobile';
import useTheme from 'hooks/useTheme';
import 'moment/locale/uk';

import actionsInvoices from '../actions/invoices';
import actionsSetup from '../actions/setup';
import invoiceStatuses from '../constants/invoiceStatuses';
import invoiceCurrencies from '../constants/invoiceCurrencies';

const getClasses = makeStyles<any>()((_, theme: any, classes: any) => ({
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionsInnerContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
  cell: {
    minWidth: '50%',
  },
  cellDetails: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(0.5)}px`,
    width: '60%',
  },
  cellHistory: {
    width: '40%',
  },
  chevron: {
    transform: 'rotate(0)',
    transition: 'all 0.2s linear',
  },
  chevronRotated: {
    transform: 'rotate(180deg)',
    transition: 'all 0.2s linear',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  detailsCell: {
    display: 'flex',
    flex: 1.5,
  },
  detailsCellInfo: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  detailsCellInfoMobile: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: `${theme.spacing(0.5)}px`,
    justifyContent: 'flex-end',
  },
  detailsCellNum: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
    padding: `${theme.spacing(1)}px 0px 0px ${theme.spacing(1)}px`,
  },
  detailsEdit: {
    display: 'flex',
    flex: 1.5,
    justifyContent: 'flex-end',
    visibility: 'hidden',
  },
  detailsMobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1.5)}px`,
    padding: `0px 0px ${theme.spacing(2)}px`,
  },
  detailsRowContainer: {
    [`&:hover .${classes.detailsEdit}`]: {
      visibility: 'visible',
    },
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(0.5)}px`,
  },
  dialogContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(3)}px`,
    overflow: 'hidden',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  dialogContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
  },
  historyItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(0.5)}px`,
  },
  invoiceDetailsContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1.5)}px`,
    padding: `0px ${theme.spacing(1)}px`,
  },
  invoiceSummaryContainerMobile: {
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
    justifyContent: 'space-between',
    width: '100%',
  },
  invoiceTitleActionsContainer: {
    display: 'flex',
    flex: 1.5,
    justifyContent: 'flex-end',
  },
  invoiceTitleContainerMobile: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
    justifyContent: 'space-between',
    padding: `0px ${theme.spacing(1)}px`,
    width: '100%',
  },
  menuItem: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1.5)}px`,
    padding: `${theme.spacing(0.5)}px 0px`,
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '20px',
  },
  selectMonth: {
    width: '150px',
  },
  selectYear: {
    width: '80px',
  },
  summaryContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  titleActions: {
    display: 'flex',
    flex: 1,
    gap: `${theme.spacing(1)}px`,
    justifyContent: 'flex-end',
  },
  titleCell: {
    display: 'flex',
    flex: 1.5,
    gap: `${theme.spacing(1)}px`,
  },
  titleCellActions: {
    display: 'flex',
    flex: 1.5,
  },
  titleCellMobile: {
    display: 'flex',
    flex: 1,
  },
  titleCellNum: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  titleStatus: {
    display: 'flex',
    flex: 9,
    justifyContent: 'center',
  },
}));

const errorTypes = {
  INVALID_FIELD_HOURS: 'INVALID_FIELD_HOURS',
};

const MIN_YEAR = 2023;
const CURRENT_YEAR = moment().year();
const DEFAULT_MONTH = moment().month()
  || moment().endOf('year').month() + 1;
const DEFAULT_YEAR = moment().month() ? CURRENT_YEAR : CURRENT_YEAR - 1;

const months = Array
  .from(new Array(12).keys())
  .map(key => key + 1);

const years = [
  CURRENT_YEAR + 1,
  CURRENT_YEAR,
  ...Array
    .from(new Array(CURRENT_YEAR - MIN_YEAR).keys())
    .map(index => MIN_YEAR + index)
    .reverse(),
];

const colorsToInvoiceStatuses: {
  [key: string]: 'warning' | 'success' | 'error',
} = {
  [invoiceStatuses.CONFIRMED]: 'success',
  [invoiceStatuses.NEW]: 'warning',
  [invoiceStatuses.WITH_ISSUES]: 'error',
};

const variantsToInvoiceStatuses: {
  [key: string]: 'warning' | 'success' | 'error',
} = {
  [invoiceStatuses.CONFIRMED]: 'success',
  [invoiceStatuses.NEW]: 'warning',
  [invoiceStatuses.WITH_ISSUES]: 'error',
};

const formatNumber = (value: any) => value
  ? value.toString().replace('.', ',')
  : value;

const formatCurrency = (value: any) => value
  ? value.toLocaleString('ru-RU', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  : value;

const currencyStrToSymbol: {
  [key: string] : string
} = {
  [invoiceCurrencies.EUR]: '€',
  [invoiceCurrencies.PLN]: 'zł',
  [invoiceCurrencies.UAH]: '₴',
  [invoiceCurrencies.USD]: '$',
};

function Invoices() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();
  const isMobile = useIsMobile();

  const {
    changes: invoiceChanges,
    isFailed: isFailedInvoices,
    isFailedDeleteInvoice,
    isFailedDownloadCalculation,
    isFailedDownloadInvoice,
    isFailedInvoiceChangeHistory,
    isFailedInvoiceItemDetails,
    isFailedRefresh,
    isFailedRefreshInvoice,
    isFailedUpdateInvoiceItem,
    isFailedUpdateStatus,
    isFetching: isFetchingInvoices,
    isFetchingDeleteInvoice,
    isFetchingDownloadCalculation,
    isFetchingDownloadInvoice,
    isFetchingInvoiceChangeHistory,
    isFetchingInvoiceItemDetails,
    isFetchingRefresh,
    isFetchingRefreshInvoice,
    isFetchingUpdateInvoiceItem,
    isFetchingUpdateStatus,
    isFinishedInvoiceChangeHistory,
    isFinishedInvoiceItemDetails,
    isSuccessDeleteInvoice,
    isSuccessRefresh,
    isSuccessRefreshInvoice,
    isSuccessUpdateInvoiceItem,
    isSuccessUpdateStatus,
    itemDetails,
    list: invoices,
  } = useSelector(({ invoices: reducerInvoices }: any) => reducerInvoices);

  const [state, setState] = useState({
    currentInvoiceStatus: null,
    editInvoiceId: null,
    editInvoiceItemId: null,
    editInvoiceStatusesId: null,
    expandedInvoicesIds: [] as string[],
    invoiceActionsId: null,
    invoiceActionsMenuAnchor: null,
    invoiceHistoryId: null,
    invoiceIdToDelete: null,
    invoiceItemHours: 0,
    isActionsMenuOpen: false,
    isEditInvoiceStatusesOpen: false,
    month: DEFAULT_MONTH,
    statusesMenuAnchor: null,
    validationErrors: [] as string[],
    year: DEFAULT_YEAR,
  });

  const isFetching = isFetchingInvoices || isFetchingRefresh;
  const isFetchingInvoiceAction = isFetchingRefreshInvoice
    || isFetchingDownloadCalculation
    || isFetchingDownloadInvoice;

  const showSuccessAfterSaveAlert = isSuccessRefresh
    || isSuccessRefreshInvoice
    || isSuccessUpdateStatus
    || isSuccessUpdateInvoiceItem
    || isSuccessDeleteInvoice;
  const showErrorAfterSaveAlert = isFailedRefresh
    || isFailedRefreshInvoice
    || isFailedUpdateStatus
    || isFailedDownloadCalculation
    || isFailedDownloadInvoice;

  const getValidationErrors = () => {
    const errors = [];
    if (state.invoiceItemHours < 0) {
      errors.push(errorTypes.INVALID_FIELD_HOURS);
    }

    return errors;
  };

  const getInvoiceProject = (invoiceId: any) => invoices
    .find((invoice: any) => invoice.id === invoiceId)
    ?.title;

  const onOpenStatusMenu = (invoice: any, event: React.SyntheticEvent<any>) => {
    event.stopPropagation();
    setState({
      ...state,
      currentInvoiceStatus: invoice.status,
      editInvoiceStatusesId: invoice.id,
      isEditInvoiceStatusesOpen: true,
      statusesMenuAnchor: event.currentTarget,
    });
  };

  const onOpenActionMenu = (invoice: any, event: React.SyntheticEvent<any>) => {
    event.stopPropagation();
    setState({
      ...state,
      invoiceActionsId: invoice.id,
      invoiceActionsMenuAnchor: event.currentTarget,
      isActionsMenuOpen: true,
    });
  };

  const onEditInvoiceItem = (invoiceId: any, itemId: any) => {
    dispatch(actionsSetup.clearAfterFetchStatus());
    dispatch(actionsInvoices.fetchInvoiceItemDetails({
      invoiceId,
      itemId,
    }));
    setState({
      ...state,
      editInvoiceId: invoiceId,
      editInvoiceItemId: itemId,
    });
  };

  const onSaveInvoiceItem = () => {
    const validationErrors = getValidationErrors();
    if (!validationErrors.length) {
      dispatch(actionsSetup.clearAfterFetchStatus());
      dispatch(actionsInvoices.fetchUpdateInvoiceItem({
        hours: state.invoiceItemHours,
        invoiceId: state.editInvoiceId,
        itemId: state.editInvoiceItemId,
      }));
    }
    setState({
      ...state,
      validationErrors,
    });
  };

  const onCloseStatusesMenu = () => setState({
    ...state,
    currentInvoiceStatus: null,
    editInvoiceStatusesId: null,
  });

  const onCloseActionsMenu = () => setState({
    ...state,
    isActionsMenuOpen: false,
  });

  const onCloseUpdateItemDialog = () => setState({
    ...state,
    editInvoiceItemId: null,
  });

  const onCloseHistoryDialog = () => setState({
    ...state,
    invoiceHistoryId: null,
  });

  const onCloseDeleteDialog = () => setState({
    ...state,
    invoiceIdToDelete: null,
  });

  const onExpandAccordion = (invoiceId: string) => {
    const expandedInvoicesIds = state.expandedInvoicesIds.includes(invoiceId)
      ? state.expandedInvoicesIds.filter(id => id !== invoiceId)
      : state.expandedInvoicesIds.concat(invoiceId);
    setState({
      ...state,
      expandedInvoicesIds,
    });
  };

  const errorAlertLabel = useMemo(() => {
    let intlValue = 'error';
    if (isFailedRefresh) {
      intlValue = 'errorRefresh';
    }
    if (isFailedDownloadCalculation) {
      intlValue = 'errorDownloadCalculation';
    }
    if (isFailedDownloadInvoice) {
      intlValue = 'errorDownloadInvoice';
    }
    if (isFailedUpdateStatus || isFailedRefreshInvoice) {
      intlValue = 'errorUpdateInvoice';
    }

    return formatMessage({ id: `loading.${intlValue}` });
  }, [showErrorAfterSaveAlert]);

  const successAlertLabel = useMemo(() => {
    let intlValue = 'success';
    if (isSuccessDeleteInvoice) {
      intlValue = 'successDelete';
    }
    if (isSuccessRefresh) {
      intlValue = 'successRefresh';
    }
    if (isSuccessUpdateStatus
      || isSuccessRefreshInvoice
      || isSuccessUpdateInvoiceItem
    ) {
      intlValue = 'successUpdateInvoice';
    }

    return formatMessage({ id: `loading.${intlValue}` });
  }, [showSuccessAfterSaveAlert]);

  const getCurrencySymbol = (invoiceItems: any[]) => {
    const currency = invoiceItems
      .find((item: any) => item.currency)?.currency;
    return currency ? currency : '';
  };

  const invoiceItemsToDisplay = (invoiceItems: any[]) => {
    return invoiceItems.map((item: any) => {
      const symbol = currencyStrToSymbol[item.currency];
      const itemSum = typeof item.sum === 'number'
        ? formatMessage(
          {
            id: 'sum',
          },
          {
            currency: symbol,
            sum: formatCurrency(item.sum),
          }
        )
        : item.issue;
      return {
        ...item,
        itemSum: itemSum,
      };
    });
  };

  const invoicesToDisplay = useMemo(() => {
    return invoices.map((invoice: any) => {
      const symbol = currencyStrToSymbol[getCurrencySymbol(invoice.items)];
      return {
        ...invoice,
        invoiceSum: formatMessage(
          {
            id: 'sum',
          },
          {
            currency: symbol,
            sum: formatCurrency(invoice.sum),
          }
        ),
        items: invoiceItemsToDisplay(invoice.items),
      };
    });
  }, [invoices, formatMessage]);

  useEffect(() => {
    dispatch(actionsSetup.clearAfterFetchStatus());
    dispatch(actionsInvoices.fetchInvoices({
      month: state.month,
      year: state.year,
    }));
  }, [
    state.month,
    state.year,
  ]);

  useEffect(() => {
    if (isSuccessUpdateStatus) {
      setState({
        ...state,
        currentInvoiceStatus: null,
        editInvoiceStatusesId: null,
      });
    }
  }, [isSuccessUpdateStatus]);

  useEffect(() => {
    if (isFinishedInvoiceItemDetails) {
      const invoiceItemHours = itemDetails.hours || 0;
      setState({
        ...state,
        invoiceItemHours,
      });
    }
  }, [isFinishedInvoiceItemDetails]);

  useEffect(() => {
    if (isSuccessUpdateInvoiceItem) {
      setState({
        ...state,
        editInvoiceItemId: null,
        invoiceItemHours: 0,
      });
    }
  }, [isSuccessUpdateInvoiceItem]);

  useEffect(() => {
    if (isSuccessDeleteInvoice) {
      setState({
        ...state,
        invoiceIdToDelete: null,
      });
    }
  }, [isSuccessDeleteInvoice]);

  useEffect(() => {
    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'invoices' })}
          variant="text"
        />
      </Breadcrumbs>
      <div className={classes.actionsContainer}>
        <div className={classes.actionsInnerContainer}>
          <div className={classes.selectMonth}>
            <Select
              fullWidth
              onChange={({ target }) => setState({
                ...state,
                month: target.value,
              })}
              size="small"
              value={state.month}
              variant="outlined"
            >
              {months.map((month) => (
                <MenuItem value={month}>
                  <Typography>
                    {formatMessage({ id: `month.${month}` })}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.selectYear}>
            <Select
              fullWidth
              onChange={({ target }) => setState({
                ...state,
                year: target.value,
              })}
              size="small"
              value={state.year}
              variant="outlined"
            >
              {years.map(year => (
                <MenuItem value={year}>
                  <Typography>
                    {year}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <Tooltip
          title={formatMessage({ id: 'refresh' })}
        >
          <IconButton
            disabled={isFetching}
            onClick={() => {
              dispatch(actionsSetup.clearAfterFetchStatus());
              dispatch(actionsInvoices.fetchRefreshInvoices({
                month: state.month,
                year: state.year,
              }));
            }}
          >
            {isFetching
              ? <CircularProgress size={24} />
              : <IconRefresh size={24} />
            }
          </IconButton>
        </Tooltip>
      </div>
      {(isFetchingInvoices || isFailedInvoices) && (
        <Loading variant={isFailedInvoices ? 'error' : 'loading'}>
          {isFailedInvoices && (
            <Typography color="secondary" variant="subtitle">
              {formatMessage({ id: 'loading.error' })}
            </Typography>
          )}
        </Loading>
      )}
      {(!isFetchingInvoices && !isFailedInvoices)
        && !invoices.length
        && (
          <Loading variant="noData">
            <Typography color="secondary" variant="subtitle">
              {formatMessage({ id: 'loading.noData' })}
            </Typography>
          </Loading>
        )}
      {(!isFetchingInvoices && !isFailedInvoices) && (
        <div>
          {invoicesToDisplay.map((invoice: any) => (
            <>
              {!isMobile && (
                <Accordion
                  expanded={state.expandedInvoicesIds.includes(invoice.id)}
                  onChange={() => onExpandAccordion(invoice.id)}
                >
                  <AccordionSummary>
                    <div className={classes.summaryContainer}>
                      <div className={classes.titleCell}>
                        {invoice.number && (
                          <Typography color="secondary">
                            {invoice.number}
                          </Typography>
                        )}
                        <Typography>
                          {invoice.title}
                        </Typography>
                      </div>
                      <div className={classes.titleCellNum}>
                        <Typography align="right">
                          {formatMessage(
                            { id: 'hours' },
                            { hours: formatNumber(invoice.totalHours) }
                          )}
                        </Typography>
                      </div>
                      <div className={classes.titleCellNum}>
                        <Typography>
                          <strong>
                            { invoice.invoiceSum }
                          </strong>
                        </Typography>
                      </div>
                      <div className={classes.titleCellActions}>
                        <div className={classes.titleStatus}>
                          {isFetchingUpdateStatus
                          && invoice.id === state.editInvoiceStatusesId
                            ? <CircularProgress />
                            : (
                              <Chip
                                color={colorsToInvoiceStatuses[invoice.status]}
                                disabled={isFetchingUpdateStatus && invoice
                                  .id !== state.editInvoiceStatusesId}
                                label={formatMessage({
                                  id: `status.${invoice.status}`,
                                })}
                                onClick={ev => onOpenStatusMenu(invoice, ev)}
                              />
                            )}
                        </div>
                        <div className={classes.titleActions}>
                          <IconButton
                            disabled={isFetchingInvoiceAction
                              && state.invoiceActionsId !== invoice.id}
                            disableHoverSpace
                            onClick={ev => onOpenActionMenu(invoice, ev)}
                          >
                            {isFetchingInvoiceAction
                            && state.invoiceActionsId === invoice.id
                              ? <CircularProgress size={24} />
                              : <IconActions size={24} />}
                          </IconButton>
                          <div
                            className={state.expandedInvoicesIds
                              .includes(invoice.id)
                              ? classes.chevronRotated
                              : classes.chevron}
                          >
                            <IconButton
                              disableHoverSpace
                              onClick={() => onExpandAccordion(invoice.id)}
                            >
                              <IconExpandMore size={24} />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    <div className={classes.detailsContainer}>
                      {invoice.items?.map((invoiceItem: any) => (
                        <div className={classes.detailsRowContainer}>
                          <div className={classes.detailsCell}>
                            <Typography>
                              {invoiceItem.contractorName}
                            </Typography>
                          </div>
                          <div className={classes.detailsCellNum}>
                            <Typography align="right">
                              {formatMessage(
                                { id: 'hours' },
                                { hours: formatNumber(invoiceItem.hours) }
                              )}
                            </Typography>
                          </div>
                          <div className={classes.detailsCellInfo}>
                            <div>
                              {invoiceItem.sourceHours !== invoiceItem.hours
                                && (
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={formatMessage(
                                      { id: 'hours.source.hint' },
                                      {
                                        value: formatNumber(invoiceItem
                                          .sourceHours),
                                      }
                                    )}
                                  >
                                    <IconInfo
                                      color={theme.colors.yellowDark}
                                      size={16}
                                    />
                                  </Tooltip>
                                )}
                            </div>
                            <Typography
                              align="right"
                              color={typeof invoiceItem.sum === 'number'
                                ? 'primary'
                                : 'error'}
                            >
                              { invoiceItem.itemSum }
                            </Typography>
                          </div>
                          <div className={classes.detailsEdit}>
                            <IconButton
                              disabled={isFetchingUpdateStatus
                                || isFetchingInvoiceAction}
                              disableHoverSpace
                              onClick={() =>
                                onEditInvoiceItem(invoice.id, invoiceItem.id)}
                            >
                              <IconEdit size={16} />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {isMobile && (
                <Accordion
                  expanded={state.expandedInvoicesIds.includes(invoice.id)}
                  onChange={() => onExpandAccordion(invoice.id)}
                >
                  <AccordionSummary disablePadding>
                    <div className={classes.invoiceTitleContainerMobile}>
                      <div className={classes.titleCellMobile}>
                        <Typography>
                          {invoice.title}
                        </Typography>
                      </div>
                      <div className={classes.titleCellNum}>
                        <Typography>
                          <strong>
                            { invoice.invoiceSum }
                          </strong>
                        </Typography>
                      </div>
                      <div className={classes.titleCellActions}>
                        <div className={classes.titleStatus}>
                          {isFetchingUpdateStatus
                          && invoice.id === state.editInvoiceStatusesId
                            ? <CircularProgress />
                            : (
                              <Chip
                                color={colorsToInvoiceStatuses[invoice.status]}
                                disabled={isFetchingUpdateStatus && invoice
                                  .id !== state.editInvoiceStatusesId}
                                label={formatMessage({
                                  id: `status.${invoice.status}`,
                                })}
                                onClick={ev => onOpenStatusMenu(invoice, ev)}
                              />
                            )}
                        </div>
                        <div className={classes.invoiceTitleActionsContainer}>
                          <IconButton
                            disabled={isFetchingInvoiceAction
                              && state.invoiceActionsId !== invoice.id}
                            disableHoverSpace
                            onClick={ev => onOpenActionMenu(invoice, ev)}
                          >
                            {isFetchingInvoiceAction
                            && state.invoiceActionsId === invoice.id
                              ? <CircularProgress size={24} />
                              : <IconActions size={24} />}
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails disablePadding>
                    <div className={classes.detailsMobileContainer}>
                      <div className={classes.invoiceDetailsContainerMobile}>
                        {invoice.number && (
                          <div
                            className={classes.invoiceSummaryContainerMobile}
                          >
                            <div className={classes.titleCellMobile}>
                              <Typography color="secondary">
                                {formatMessage({ id: 'number' })}
                              </Typography>
                            </div>
                            <div className={classes.titleCellNum}>
                              <Typography
                                align="right"
                                color="secondary"
                              >
                                {invoice.number}
                              </Typography>
                            </div>
                            <div
                              className={classes.invoiceTitleActionsContainer}
                            />
                          </div>
                        )}
                        <div className={classes.invoiceSummaryContainerMobile}>
                          <div className={classes.titleCellMobile}>
                            <Typography color="secondary">
                              {formatMessage({ id: 'totalHours' })}
                            </Typography>
                          </div>
                          <div className={classes.titleCellNum}>
                            <Typography align="right">
                              {formatNumber(invoice.totalHours)}
                            </Typography>
                          </div>
                          <div
                            className={classes.invoiceTitleActionsContainer}
                          />
                        </div>
                      </div>
                      <Divider />
                      {invoice.items?.map((invoiceItem: any, idx: number) => (
                        <>
                          <div
                            className={classes.invoiceDetailsContainerMobile}
                          >
                            <div
                              className={classes.invoiceSummaryContainerMobile}
                            >
                              <div className={classes.titleCellMobile}>
                                <Typography>
                                  {invoiceItem.contractorName}
                                </Typography>
                              </div>
                              <div className={classes.titleCellNum}>
                                <Typography
                                  align="right"
                                  color={typeof invoiceItem.sum === 'number'
                                    ? 'primary'
                                    : 'error'}
                                >
                                  {typeof invoiceItem.sum === 'number'
                                    ? invoiceItem.itemSum
                                    : invoiceItem.issue}
                                </Typography>
                              </div>
                              <div
                                className={classes.invoiceTitleActionsContainer}
                              >
                                <IconButton
                                  disabled={isFetchingUpdateStatus
                                    || isFetchingInvoiceAction}
                                  disableHoverSpace
                                  onClick={() => onEditInvoiceItem(
                                    invoice.id,
                                    invoiceItem.id
                                  )}
                                >
                                  <IconEdit size={16} />
                                </IconButton>
                              </div>
                            </div>
                            <div
                              className={classes.invoiceSummaryContainerMobile}
                            >
                              <div className={classes.titleCellMobile}>
                                <Typography color="secondary">
                                  {formatMessage({ id: 'totalHours' })}
                                </Typography>
                              </div>
                              <div className={classes.detailsCellInfoMobile}>
                                <div>
                                  {invoiceItem.sourceHours !== invoiceItem.hours
                                    && (
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        title={formatMessage(
                                          { id: 'hours.source.hint' },
                                          {
                                            value: formatNumber(invoiceItem
                                              .sourceHours),
                                          }
                                        )}
                                      >
                                        <IconInfo
                                          color={theme.colors.yellowDark}
                                          size={16}
                                        />
                                      </Tooltip>
                                    )}
                                </div>
                                <Typography align="right">
                                  {formatNumber(invoiceItem.hours)}
                                </Typography>
                              </div>
                              <div
                                className={classes.invoiceTitleActionsContainer}
                              />
                            </div>
                          </div>
                          {!!invoice.items[idx + 1] && <Divider />}
                        </>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          ))}
        </div>
      )}
      {state.editInvoiceStatusesId && (
        <Menu
          anchorEl={state.statusesMenuAnchor}
          open={state.isEditInvoiceStatusesOpen && state.editInvoiceStatusesId}
          onClose={onCloseStatusesMenu}
        >
          {Object
            .keys(invoiceStatuses)
            .map(status => (
              <MenuItem
                onClick={() => {
                  if (state.currentInvoiceStatus !== invoiceStatuses[status]) {
                    dispatch(actionsSetup.clearAfterFetchStatus());
                    dispatch(actionsInvoices.fetchUpdateInvoiceStatus({
                      invoiceId: state.editInvoiceStatusesId,
                      status,
                    }));
                  }
                  setState({
                    ...state,
                    isEditInvoiceStatusesOpen: false,
                  });
                }}
                selected={state
                  .currentInvoiceStatus === invoiceStatuses[status]}
                value={status}
              >
                <Typography>
                  {formatMessage({
                    id: `status.${invoiceStatuses[status]}`,
                  })}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      )}
      <Menu
        anchorEl={state.invoiceActionsMenuAnchor}
        open={state.isActionsMenuOpen}
        onClose={onCloseActionsMenu}
      >
        <MenuItem
          onClick={() => {
            dispatch(actionsSetup.clearAfterFetchStatus());
            dispatch(actionsInvoices.fetchRefreshInvoice({
              invoiceId: state.invoiceActionsId,
            }));
            onCloseActionsMenu();
          }}
        >
          <div className={classes.menuItem}>
            <IconRefresh size={24} />
            <Typography>
              {formatMessage({ id: 'refresh' })}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(actionsSetup.clearAfterFetchStatus());
            dispatch(actionsInvoices.fetchChangeHistory({
              invoiceId: state.invoiceActionsId,
            }));
            setState({
              ...state,
              invoiceHistoryId: state.invoiceActionsId,
              isActionsMenuOpen: false,
            });
          }}
        >
          <div className={classes.menuItem}>
            <IconHistory size={24} />
            <Typography>
              {formatMessage({ id: 'changeHistory' })}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(actionsSetup.clearAfterFetchStatus());
            dispatch(actionsInvoices.fetchDownloadCalculation({
              invoiceId: state.invoiceActionsId,
            }));
            onCloseActionsMenu();
          }}
        >
          <div className={classes.menuItem}>
            <IconChart size={24} />
            <Typography>
              {formatMessage({ id: 'downloadCalculation' })}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(actionsSetup.clearAfterFetchStatus());
            dispatch(actionsInvoices.fetchDownloadInvoice({
              invoiceId: state.invoiceActionsId,
            }));
            onCloseActionsMenu();
          }}
        >
          <div className={classes.menuItem}>
            <IconArticle size={24} />
            <Typography>
              {formatMessage({ id: 'downloadInvoice' })}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => setState({
            ...state,
            invoiceIdToDelete: state.invoiceActionsId,
            isActionsMenuOpen: false,
          })}
        >
          <div className={classes.menuItem}>
            <IconDelete size={24} />
            <Typography>
              {formatMessage({ id: 'delete' })}
            </Typography>
          </div>
        </MenuItem>
      </Menu>

      {/* Changes History Dialog */}
      <Dialog
        onClose={onCloseHistoryDialog}
        open={!!state.invoiceHistoryId}
      >
        {isFetchingInvoiceChangeHistory && (
          <Loading variant="loading" />
        )}
        {isFinishedInvoiceChangeHistory && (
          <Card>
            <CardTitle>
              <div>
                <Typography variant="title">
                  {invoices.find((invoice: any) =>
                    invoice.id === state.invoiceHistoryId)?.title}
                </Typography>
                <Typography color="secondary">
                  {formatMessage({ id: 'changeHistory' })}
                </Typography>
              </div>
              <IconButton
                disableHoverSpace
                onClick={onCloseHistoryDialog}
              >
                <IconClose size={24}/>
              </IconButton>
            </CardTitle>
            <div/>
            <CardContent>
              {isFailedInvoiceChangeHistory
                && !invoiceChanges.length
                && (
                  <Typography>
                    {formatMessage({ id: 'loading.error' })}
                  </Typography>
                )}
              {!isFailedInvoiceChangeHistory
                && !invoiceChanges.length
                && (
                  <Typography>
                    {formatMessage({ id: 'loading.noData' })}
                  </Typography>
                )}
              {!isFailedInvoiceChangeHistory
                && !!invoiceChanges.length
                && (
                  <div className={classes.dialogContent}>
                    {invoiceChanges.map((historyEntry: any) => (
                      <Card
                        variant={variantsToInvoiceStatuses[historyEntry.status]}
                      >
                        <CardTitle>
                          <div>
                            <Typography>
                              <strong>
                                {historyEntry.user}
                              </strong>
                            </Typography>
                            <Typography color="secondary">
                              <strong>
                                {moment(historyEntry.timestamp)
                                  .format('DD/MM/YYYY, hh:mm:ss')}
                              </strong>
                            </Typography>
                          </div>
                        </CardTitle>
                        <CardContent>
                          <div className={classes.historyItemContainer}>
                            <div className={classes.row}>
                              <div className={classes.cellHistory}>
                                <Typography color="secondary">
                                  {formatMessage({ id: 'history.action' })}
                                </Typography>
                              </div>
                              <div className={classes.cellDetails}>
                                <Typography>
                                  {formatMessage({
                                    id: `history.action.${historyEntry.action}`,
                                  })}
                                </Typography>
                                {historyEntry.description && (
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={historyEntry.description}
                                  >
                                    <IconInfo
                                      color={theme.colors.yellowDark}
                                      size={16}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                            <div className={classes.row}>
                              <div className={classes.cellHistory}>
                                <Typography color="secondary">
                                  {formatMessage({ id: 'history.status' })}
                                </Typography>
                              </div>
                              <Typography>
                                <strong>
                                  {formatMessage({
                                    id: `status.${historyEntry.status}`,
                                  })}
                                </strong>
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                )}
            </CardContent>
            <div/>
            <CardActions>
              <Button
                onClick={onCloseHistoryDialog}
                variant="secondary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'close' })}
                </Typography>
              </Button>
            </CardActions>
          </Card>
        )}
      </Dialog>

      {/* Item Update Dialog. Desktop */}
      <Dialog
        onClose={onCloseUpdateItemDialog}
        open={!!state.editInvoiceItemId && !isMobile}
        maxWidth="xs"
      >
        {isFetchingInvoiceItemDetails && (
          <Loading variant="loading" />
        )}
        {isFailedUpdateInvoiceItem && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage({ id: 'loading.errorUpdateInvoice' })}
              </Typography>
            </CardTitle>
          </Card>
        )}
        {isFinishedInvoiceItemDetails && (
          <Card variant="edit">
            <CardTitle>
              <div>
                <Typography variant="title">
                  {formatMessage({ id: 'edit.title' })}
                </Typography>
                {!isFailedInvoiceItemDetails && (
                  <Typography color="secondary">
                    {formatMessage(
                      { id: 'edit.subtitle' },
                      {
                        contractor: itemDetails.contractorName,
                        project: getInvoiceProject(state.editInvoiceId),
                      }
                    )}
                  </Typography>
                )}
              </div>
              <IconButton
                disableHoverSpace
                onClick={onCloseUpdateItemDialog}
              >
                <IconClose size={24}/>
              </IconButton>
            </CardTitle>
            <div/>
            <CardContent>
              {isFailedInvoiceItemDetails && (
                <Typography>
                  {formatMessage({ id: 'loading.error' })}
                </Typography>
              )}
              {!isFailedInvoiceItemDetails && (
                <div className={classes.dialogContent}>
                  <div className={classes.row}>
                    <div className={classes.cell}>
                      <Typography color="secondary">
                        {formatMessage({ id: 'hours.source' })}
                      </Typography>
                    </div>
                    <Typography color="secondary">
                      {formatNumber(itemDetails.sourceHours)}
                    </Typography>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.cell}>
                      <Typography color="secondary">
                        {formatMessage({ id: 'hours.worked' })}
                      </Typography>
                    </div>
                    <TextField
                      AdornmentEnd={
                        state.validationErrors
                          .includes(errorTypes.INVALID_FIELD_HOURS) && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={formatMessage({
                              id: `validationError.${errorTypes
                                .INVALID_FIELD_HOURS}`,
                            })}
                          >
                            <IconError
                              color={theme.colors.redDark}
                              size={20}
                            />
                          </Tooltip>
                        )
                      }
                      disabled={isFetchingUpdateInvoiceItem}
                      fullWidth
                      inputType="number"
                      isError={state.validationErrors
                        .includes(errorTypes.INVALID_FIELD_HOURS)}
                      onChange={({ target }) => setState({
                        ...state,
                        invoiceItemHours: target.value,
                      })}
                      placeholder={formatMessage({
                        id: 'hours.worked',
                      })}
                      size="small"
                      value={state.invoiceItemHours}
                      variant="standard"
                    />
                  </div>
                  {!isNaN(itemDetails.payrollHours) && (
                    <div className={classes.row}>
                      <div className={classes.cell}>
                        <Typography color="secondary">
                          {formatMessage({ id: 'hours.payroll' })}
                        </Typography>
                      </div>
                      <Typography color="secondary">
                        {formatNumber(itemDetails.payrollHours)}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
            <div/>
            <CardActions>
              <Button
                onClick={onCloseUpdateItemDialog}
                variant="secondary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'cancel' })}
                </Typography>
              </Button>
              <Button
                disabled={isFailedInvoiceItemDetails}
                isLoading={isFetchingUpdateInvoiceItem}
                onClick={onSaveInvoiceItem}
                variant="primary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'save' })}
                </Typography>
              </Button>
            </CardActions>
          </Card>
        )}
      </Dialog>

      {/* Item Update Dialog. Mobile */}
      <Dialog
        onClose={onCloseUpdateItemDialog}
        open={!!state.editInvoiceItemId && isMobile}
      >
        {isFetchingInvoiceItemDetails && (
          <Loading variant="loading" />
        )}
        {isFailedUpdateInvoiceItem && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage({ id: 'loading.errorUpdateInvoice' })}
              </Typography>
            </CardTitle>
          </Card>
        )}
        {isFinishedInvoiceItemDetails && (
          <Card variant="edit">
            <CardTitle>
              <div>
                <Typography variant="title">
                  {formatMessage({ id: 'edit.title' })}
                </Typography>
                {!isFailedInvoiceItemDetails && (
                  <Typography color="secondary">
                    {formatMessage(
                      { id: 'edit.subtitle' },
                      {
                        contractor: itemDetails.contractorName,
                        project: getInvoiceProject(state.editInvoiceId),
                      }
                    )}
                  </Typography>
                )}
              </div>
              <IconButton
                disableHoverSpace
                onClick={onCloseUpdateItemDialog}
              >
                <IconClose size={24}/>
              </IconButton>
            </CardTitle>
            <div/>
            <CardContent>
              {isFailedInvoiceItemDetails && (
                <Typography>
                  {formatMessage({ id: 'loading.error' })}
                </Typography>
              )}
              {!isFailedInvoiceItemDetails && (
                <div className={classes.dialogContainerMobile}>
                  <div className={classes.dialogContentMobile}>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      {formatMessage({ id: 'hours.source' })}
                    </Typography>
                    <Typography color="secondary">
                      {formatNumber(itemDetails.sourceHours)}
                    </Typography>
                  </div>
                  <div className={classes.dialogContentMobile}>
                    <Typography
                      color="secondary"
                      variant="subtitle"
                    >
                      {formatMessage({ id: 'hours.worked' })}
                    </Typography>
                    <TextField
                      AdornmentEnd={
                        state.validationErrors
                          .includes(errorTypes.INVALID_FIELD_HOURS) && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={formatMessage({
                              id: `validationError.${errorTypes
                                .INVALID_FIELD_HOURS}`,
                            })}
                          >
                            <IconError
                              color={theme.colors.redDark}
                              size={20}
                            />
                          </Tooltip>
                        )
                      }
                      disabled={isFetchingUpdateInvoiceItem}
                      fullWidth
                      inputType="number"
                      isError={state.validationErrors
                        .includes(errorTypes.INVALID_FIELD_HOURS)}
                      onChange={({ target }) => setState({
                        ...state,
                        invoiceItemHours: target.value,
                      })}
                      placeholder={formatMessage({
                        id: 'hours.worked',
                      })}
                      size="small"
                      value={state.invoiceItemHours}
                      variant="standard"
                    />
                  </div>
                  {!isNaN(itemDetails.payrollHours) && (
                    <div className={classes.dialogContentMobile}>
                      <Typography
                        color="secondary"
                        variant="subtitle"
                      >
                        {formatMessage({ id: 'hours.payroll' })}
                      </Typography>
                      <Typography color="secondary">
                        {formatNumber(itemDetails.payrollHours)}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
            <div/>
            <CardActions>
              <Button
                onClick={onCloseUpdateItemDialog}
                variant="secondary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'cancel' })}
                </Typography>
              </Button>
              <Button
                disabled={isFailedInvoiceItemDetails}
                isLoading={isFetchingUpdateInvoiceItem}
                onClick={onSaveInvoiceItem}
                variant="primary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'save' })}
                </Typography>
              </Button>
            </CardActions>
          </Card>
        )}
      </Dialog>

      {/* Delete Dialog */}
      <Dialog
        onClose={onCloseDeleteDialog}
        open={!!state.invoiceIdToDelete}
      >
        {isFailedDeleteInvoice && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage({ id: 'loading.errorDelete' })}
              </Typography>
            </CardTitle>
          </Card>
        )}
        <Card>
          <CardTitle>
            <Typography variant="title">
              {formatMessage({ id: 'delete.title' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseDeleteDialog}
            >
              <IconClose size={24} />
            </IconButton>
          </CardTitle>
          <CardContent>
            <Typography color="secondary">
              {formatMessage({ id: 'delete.subtitle' })}
            </Typography>
          </CardContent>
          <div />
          <CardActions>
            <Button
              onClick={onCloseDeleteDialog}
              variant='secondary'
            >
              <Typography color="inherit">
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </Button>
            <Button
              isLoading={isFetchingDeleteInvoice}
              onClick={() => {
                dispatch(actionsSetup.clearAfterFetchStatus());
                dispatch(actionsInvoices.fetchDeleteInvoice({
                  invoiceId: state.invoiceIdToDelete,
                  month: state.month,
                  year: state.year,
                }));
              }}
              variant="primary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'delete' })}
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </Dialog>

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={() => dispatch(actionsSetup.clearAfterFetchStatus())}
        open={showSuccessAfterSaveAlert}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {successAlertLabel}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar
        onClose={(event: any, reason: string) => {
          if (reason !== 'clickaway') {
            dispatch(actionsSetup.clearAfterFetchStatus());
          }
        }}
        open={showErrorAfterSaveAlert}
      >
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {errorAlertLabel}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={() => dispatch(actionsSetup.clearAfterFetchStatus())}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default Invoices;
