import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';
import ProjectMembersAndRatesPage from 'pages/projectMembersAndRates';

function ProjectMembersAndRates() {
  return (
    <PageContainer>
      <PageAccessValidator
        neededAuthorities={authoritiesUI.CLIENTS_AND_PROJECTS}
      >
        <ProjectMembersAndRatesPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default ProjectMembersAndRates;
