import { combineReducers } from 'redux';

import compensations from './compensations';
import compensationTypes from './compensationTypes';
import contractor from './contractor';

const rootReducer = combineReducers({
  compensationTypes,
  compensations,
  contractor,
});

export default rootReducer;
