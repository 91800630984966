export const UAH = 'UAH';
export const PLN = 'PLN';
export const USD = 'USD';
export const EUR = 'EUR';

const list: { [key: string]: string } = {
  EUR,
  PLN,
  UAH,
  USD,
};

export default list;
