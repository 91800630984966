import { useContext } from 'react';
import { UserContext } from 'components/UserProvider';

/**
@return {
  userEmail,
  userId,
  userName,
}
**/

const useUser = () => useContext(UserContext);

export default useUser;
