import {
  ERROR_RECEIVE_AUTHORITIES,
  RECEIVE_AUTHORITIES,
  REQUEST_AUTHORITIES,
  RESET_REDUCERS_DATA,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  isFailed: false,
  isFetching: false,
  list: [],
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_AUTHORITIES: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_AUTHORITIES: {
      const list = action.payload;

      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list,
      };
    }

    case ERROR_RECEIVE_AUTHORITIES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
