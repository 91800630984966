import React from 'react';
import SvgIcon from '../SvgIcon';
import useTheme from 'hooks/useTheme';

const Invoice = ({ color = 'page', size = 32 }: IProps) => {
  const { theme } = useTheme();
  const actualColor = theme.icon.color[color] || color;
  return (
    <SvgIcon
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
      viewBox="0 0 24 24"
    >
      <path
        // eslint-disable-next-line max-len
        d="M 14 2 H 6 c -1.1 0 -2 0.9 -2 2 v 16 c 0 1.1 0.9 2 2 2 h 12 c 1.1 0 2 -0.9 2 -2 V 8 l -6 -6 Z m 1 9 h -4 v 1 h 3 c 0.55 0 1 0.45 1 1 v 3 c 0 0.55 -0.45 1 -1 1 h -1 v 1 h -2 v -1 H 9 v -2 h 4 v -1 h -3 c -0.55 0 -1 -0.45 -1 -1 v -3 c 0 -0.55 0.45 -1 1 -1 h 1 V 8 h 2 v 1 h 2 v 2 Z"
        fill={actualColor}
      />
    </SvgIcon>
  );
};

interface IProps {
  color?: 'header' | 'page' | string;
  size?: number;
}

export default Invoice;
