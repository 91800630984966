import { compactVariants } from 'components/TableCellHead';
import { makeStyles } from 'tss-react/mui';
import MenuItem from 'components/MenuItem';
import React, { cloneElement, useMemo } from 'react';
import Select from 'components/Select';
import TableMui from '@mui/material/Table';
import Typography from 'components/Typography';
import useTheme from 'hooks/useTheme';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  fixed: {
    tableLayout: 'fixed',
  },
}));

const TableItems = {
  body: 'TableBody',
  head: 'TableHead',
  row: 'TableRow',
};

const makeArray = (input: any) => {
  if (!input) {
    return [];
  }
  return Array.isArray(input) ? input : [input];
};

const NO_INDEX = -1;

const Table = ({
  children: inputChildren,
  compact = false,
  compactColumnIndex = NO_INDEX,
  fixed = false,
  onChangeCompactColumnIndex = () => ({}),
  padding = 'normal',
  size = 'medium',
}: IProps) => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);

  const children = useMemo(() => {
    if (compact) {
      const filteredChildren = makeArray(inputChildren)
        .filter(child => !!child);

      const tableHead = (filteredChildren || [] as any)
        .find((child: any) => child.type.pswName === TableItems.head);

      const tableHeadRow = makeArray(tableHead.props.children)
        .find((child: any) => child.type.pswName === TableItems.row);

      const indexesOfRemovableChildren = [] as number[];
      const compactColumns = makeArray(tableHeadRow.props.children)
        .filter(child => !!child)
        .reduce((acc, child, index: number) => {
          if (child.props.compactVariant === compactVariants.dynamic) {
            indexesOfRemovableChildren.push(index);
            // We expects that children of TableCellHead will be always like:
            // <Typography>
            //  {value}
            // </Typography>
            acc.push({
              index,
              title: child.props.children.props.children,
            });
          }
          return acc;
        }, []);

      if (indexesOfRemovableChildren.length === 1) {
        return filteredChildren;
      }
      // Now we need to remove all dynamic TableCellHeads and place Select
      // component instead of children of last dynamic TableCellHead'
      // eslint-disable-next-line max-len
      const indexForSelectComponent = indexesOfRemovableChildren[indexesOfRemovableChildren
        .length - 1];
      const newTableHeadRow = makeArray(tableHeadRow.props.children)
        .filter(child => !!child)
        .map((child: any, index: number) => {
          if (index === indexForSelectComponent) {
            return cloneElement(
              child, // TableCellHead
              child.props,
              (
                <Select
                  fullWidth
                  onChange={({ target }) => onChangeCompactColumnIndex(target
                    .value)}
                  renderValue={(value) => cloneElement(
                    child.props.children,
                    child.props.children.props,
                    compactColumns
                      .find(({ index }: any) => index === value)
                      ?.title
                  )}
                  value={compactColumnIndex}
                >
                  {compactColumns.map((column: any) => (
                    <MenuItem value={column.index}>
                      <Typography>
                        {column.title}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              )
            );
          }
          return child;
        })
        .filter((_: any, index: number) => (
          index === indexForSelectComponent
            || !indexesOfRemovableChildren.includes(index)
        ));

      const tableBody = filteredChildren
        .find((child: any) => child.type.pswName === TableItems.body);

      const newTableBodyRows = makeArray(tableBody.props.children)
        .filter(child => !!child)
        .map((bodyRow: any) => {
          const bodyRowChildren = [];
          for (let i = 0; i < bodyRow.props.children.length; i++) {
            if (i === indexForSelectComponent) {
              bodyRowChildren.push(bodyRow.props.children[compactColumnIndex]);
            } else if (!indexesOfRemovableChildren.includes(i)) {
              bodyRowChildren.push(bodyRow.props.children[i]);
            }
          }
          return cloneElement(
            bodyRow,
            bodyRow.props,
            bodyRowChildren
          );
        });

      return filteredChildren
        .map((child: any) => {
          if (child.type.pswName === TableItems.head) {
            return cloneElement(
              child,
              child.props,
              newTableHeadRow
            );
          } else if (child.type.pswName === TableItems.body) {
            return cloneElement(
              child,
              child.props,
              newTableBodyRows
            );
          }
          return child;
        });
    }
    return inputChildren;
  }, [inputChildren, compact, compactColumnIndex]);

  return (
    <TableMui
      className={fixed ? classes.fixed : ''}
      padding={padding}
      size={size}
    >
      {children}
    </TableMui>
  );
};

interface IProps {
  children: React.ReactNode | React.ReactNode[],
  compact?: boolean,
  compactColumnIndex?: number,
  fixed?: boolean,
  onChangeCompactColumnIndex?: (input: number) => void,
  padding?: 'checkbox' | 'none' | 'normal',
  size?: 'medium' | 'small',
}

export default Table;
