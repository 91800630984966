import {
  ERROR_CREATE_USER,
  ERROR_DELETE_USER,
  ERROR_RECEIVE_USERS,
  ERROR_UPDATE_USER,
  RECEIVE_USERS,
  REQUEST_CREATE_USER,
  REQUEST_DELETE_USER,
  REQUEST_USERS,
  REQUEST_UPDATE_USER,
  RESET_REDUCERS_DATA,
  SUCCESS_CREATE_USER,
  SUCCESS_DELETE_USER,
  SUCCESS_UPDATE_USER,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  errorMessage: '',
  isFailed: false,
  isFailedCreate: false,
  isFailedDelete: false,
  isFailedUpdate: false,
  isFetching: false,
  isFetchingCreate: false,
  isFetchingDelete: false,
  isFetchingUpdate: false,
  isFinishedCreate: false,
  isFinishedDelete: false,
  isFinishedUpdate: false,
  list: [],
};

const convertUser = (user: any) => ({
  authorities: user.authorities,
  email: user.email,
  id: user.id,
  name: user.name,
});

const convertUsers = (input: any) => input
  .map((user: any) => convertUser(user));


export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_USERS: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }
    case RECEIVE_USERS: {
      const list = convertUsers(action.payload);
      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list,
      };
    }
    case ERROR_RECEIVE_USERS: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_CREATE_USER: {
      return  {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: true,
        isFinishedCreate: false,
      };
    }
    case SUCCESS_CREATE_USER: {
      const list = convertUsers(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: false,
        isFinishedCreate: true,
        list,
      };
    }
    case ERROR_CREATE_USER: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedCreate: true,
        isFetchingCreate: false,
        isFinishedCreate: true,
      };
    }

    case REQUEST_DELETE_USER: {
      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: true,
        isFinishedDelete: false,
      };
    }
    case SUCCESS_DELETE_USER: {
      const list = convertUsers(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: false,
        isFinishedDelete: true,
        list,
      };
    }
    case ERROR_DELETE_USER: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedDelete: true,
        isFetchingDelete: false,
        isFinishedDelete: true,
      };
    }

    case REQUEST_UPDATE_USER: {
      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: true,
        isFinishedUpdate: false,
      };
    }
    case SUCCESS_UPDATE_USER: {
      const list = convertUsers(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
        list,
      };
    }
    case ERROR_UPDATE_USER: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedUpdate: true,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
