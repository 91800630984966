import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import UserManagementPage from '../pages/userManagement';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';

function UserManagement(props: any) {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.SETTINGS_USERS}>
        <UserManagementPage {...props} />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default UserManagement;
