const authorities = {
  CLIENTS_AND_PROJECTS: 'CLIENTS_AND_PROJECTS',
  COMMON_SETTINGS: 'COMMON_SETTINGS',
  COMPENSATIONS: 'COMPENSATIONS',
  CONTRACTORS: 'CONTRACTORS',
  EXPENSES: 'EXPENSES',
  INVOICES: 'INVOICES',
  PAYROLLS: 'PAYROLLS',
  REPORTS_FINANCES: 'REPORTS_FINANCES',
  SETTINGS_FINANCES: 'SETTINGS_FINANCES',
  SETTINGS_USERS: 'SETTINGS_USERS',
};

export default authorities;
