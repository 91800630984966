import React, { useMemo } from 'react';

import useAccessValidate from 'hooks/useAccessValidate';
import AuthModes from 'constants/authoritiesValidationModes';

function AccessValidator({
  children,
  neededAuthorities,
  mode = AuthModes.ANY,
}: IProps) {
  const validateAccess = useAccessValidate();
  const hasAccess = useMemo(
    () => validateAccess(neededAuthorities, mode),
    [neededAuthorities, validateAccess]);
  return (
    <>
      {hasAccess ? children : null}
    </>
  );
}

interface IProps {
  children: React.ReactNode,
  neededAuthorities: string | string[],
  mode?: AuthModes,
}

export default AccessValidator;
