import {
  ERROR_RECEIVE_CLIENTS_AND_PROJECTS,
  RECEIVE_CLIENTS_AND_PROJECTS,
  REQUEST_CLIENTS_AND_PROJECTS,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  isFailed: false,
  isFetching: false,
  list: [],
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case ERROR_RECEIVE_CLIENTS_AND_PROJECTS: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }
    case REQUEST_CLIENTS_AND_PROJECTS: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
        list: initialState.list,
      };
    }
    case RECEIVE_CLIENTS_AND_PROJECTS: {
      const list = (action.payload || []).map((project: any) => ({
        clientId: project.clientId,
        clientName: project.clientName,
        id: project.id,
        membersCount: project.membersCount,
        name: project.name,
        status: project.status,
      }));
      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list,
      };
    }

    default: return state;
  }
};
