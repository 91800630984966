import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import ReportsPage from 'pages/reports';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';

function Reports() {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.REPORTS_FINANCES}>
        <ReportsPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default Reports;
