import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import IconCase from '../icons/Case';
import IconExpense from '../icons/Expense';
import IconInvoice from '../icons/Invoice';
import IconPayments from '../icons/Payments';
import IconPeople from '../icons/People';
import IconReport from '../icons/Report';
import IconSettings from '../icons/Settings';
import Hover from '../Hover';
import Link from '../Link';
import Typography from '../Typography';
import useAccessValidate from '../../hooks/useAccessValidate';
import useCurrentPage from '../../hooks/useCurrentPage';
import useTheme from 'hooks/useTheme';

import authoritiesUI from '../../constants/authoritiesUI';
import * as pages from '../../constants/pages';
import pagesURLs from 'constants/pagesURLs';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  container: {
    backgroundColor: `${theme.sideBar.background}`,
    height: '100%',
  },
  content: {
    paddingTop: `${theme.spacing(8)}px`,
  },
  navItem: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
  },
}));

const allNavPages = [
  pages.contractors,
  pages.payrolls,
  pages.clientsAndProjects,
  pages.invoices,
  pages.expenses,
  pages.reports,
  pages.settings,
];

const neededAuthoritiesToPages: { [index: string]: string | string[] } = {
  [pages.clientsAndProjects]: authoritiesUI.CLIENTS_AND_PROJECTS,
  [pages.contractors]: authoritiesUI.CONTRACTORS,
  [pages.expenses]: authoritiesUI.EXPENSES,
  [pages.invoices]: authoritiesUI.INVOICES,
  [pages.payrolls]: authoritiesUI.PAYROLLS,
  [pages.reports]: authoritiesUI.REPORTS_FINANCES,
  [pages.settings]: [
    authoritiesUI.SETTINGS_FINANCES,
    authoritiesUI.SETTINGS_USERS,
  ],
};

const iconsToPages: { [index: string]: React.ReactNode } = {
  [pages.clientsAndProjects]: <IconCase color="page" size={24} />,
  [pages.contractors]: <IconPeople color="page" size={24} />,
  [pages.expenses]: <IconExpense color="page" size={24} />,
  [pages.invoices]: <IconInvoice color="page" size={24} />,
  [pages.payrolls]: <IconPayments color="page" size={24} />,
  [pages.reports]: <IconReport color="page" size={24} />,
  [pages.settings]: <IconSettings color="page" size={24} />,
};

const SideBar = ({
  onChange,
}: IProps) => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const currentPage = useCurrentPage();
  const validateAccess = useAccessValidate();

  const availableNavPages = useMemo(
    () => allNavPages
      .filter((page) => validateAccess(neededAuthoritiesToPages[page])),
    [validateAccess]
  );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {availableNavPages.map((page: string) => (
          <Link
            onClick={() => onChange && onChange(page)}
            to={{
              pathname: `${(pagesURLs as any)[page]}`,
            }}
          >
            <Hover selected={currentPage === page}>
              <div className={classes.navItem}>
                {iconsToPages[page]}
                <Typography color="secondary" variant="subtitle">
                  <strong>
                    {formatMessage({ id: `page.${page}` })}
                  </strong>
                </Typography>
              </div>
            </Hover>
          </Link>
        ))}
      </div>
    </div>
  );
};

interface IProps {
  onChange?: (input: any) => any,
}

export default SideBar;
