import React from 'react';
import { makeStyles } from 'tss-react/mui';
import CircularProgress from 'components/CircularProgress';
import IconError from 'components/icons/Error';
import IconNoData from 'components/icons/NoData';
import useTheme from 'hooks/useTheme';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  container: {
    alignItems: 'center',
    background: theme.loading.background,
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
    height: '100%',
    justifyContent: 'center',
    minHeight: '200px',
    width: '100%',
  },
}));

type TVariants = 'error' | 'loading' | 'noData';

type iconsTypesToTVariants = {
  [K in TVariants]: React.ReactNode;
};

const iconsToVariants: iconsTypesToTVariants = {
  error: (<IconError size={56} />),
  loading: (<CircularProgress size={56} />),
  noData: (<IconNoData size={56} />),
};

function Loading({
  children,
  variant = 'loading',
}: IProps) {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  return (
    <div className={classes.container}>
      {iconsToVariants[variant]}
      {children}
    </div>
  );
}

interface IProps {
  children?: React.ReactNode,
  variant?: TVariants,
}

export default Loading;
