import CostItemsPage from 'pages/costItems';
import authoritiesUI from 'constants/authoritiesUI';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';

function CostItems() {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.SETTINGS_FINANCES}>
        <CostItemsPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default CostItems;
