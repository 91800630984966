import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import {
  IntlProvider as ReactIntlProvider,
} from 'react-intl';

import reduxUtils from 'utils/redux';

import Payrolls from './containers/Payrolls';
import rootReducer from './reducers';
import useLocationSearch from 'hooks/useLocationSearch';
import getMessages from './intl';
import { DEFAULT_LANGUAGE, locales } from 'constants/languages';

const store = reduxUtils.configureStore(rootReducer);

function Index(props: any) {
  const { lang } = useLocationSearch();

  const messages = useMemo(() => getMessages(lang), [lang]);

  return (
    <ReactIntlProvider
      defaultLocale={locales[DEFAULT_LANGUAGE]}
      locale={locales[lang]}
      messages={messages}
    >
      <Provider store={store}>
        <Payrolls {...props} />
      </Provider>
    </ReactIntlProvider>
  );
}

export default Index;
