import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';
import PayrollsPage from '../pages/payrolls';

function Payrolls() {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.PAYROLLS}>
        <PayrollsPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default Payrolls;
