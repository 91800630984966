import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';
import SettingsPage from 'pages/settings';

function Settings() {
  return (
    <PageContainer>
      <PageAccessValidator
        neededAuthorities={[
          authoritiesUI.SETTINGS_USERS,
          authoritiesUI.SETTINGS_FINANCES,
        ]}
      >
        <SettingsPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default Settings;
