import React from 'react';
import TableBodyMui from '@mui/material/TableBody';

const TableBody = ({ children }: IProps) => {
  return <TableBodyMui>{children}</TableBodyMui>;
};

TableBody.pswName = 'TableBody';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

export default TableBody;
