import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Card from 'components/Card';
import HighlightText from 'components/HighlightText';
import IconPerson from 'components/icons/Person';
import IconSearch from 'components/icons/Search';
import Loading from 'components/Loading';
import Table from 'components/Table';
import TableBody from 'components/TableBody';
import TableCell from 'components/TableCell';
import TableCellHead from 'components/TableCellHead';
import TableHead from 'components/TableHead';
import TablePagination from 'components/TablePagination';
import TableRow from 'components/TableRow';
import TableSortLabel from 'components/TableSortLabel';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import useChangePage from 'hooks/useChangePage';
import useDebounce from 'hooks/useDebounce';
import useIsMobile from 'hooks/useIsMobile';
import useTheme from 'hooks/useTheme';

import * as statusTypes from '../constants/statusTypes';
import actionsClientsAndProjects from '../actions/clientsAndProjects';
import paginationSizesList, * as paginationSizes
  from '../constants/paginationSizes';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  contractorCount: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  contractorIcon: {
    display: 'flex',
    flex: 1,
  },
  contractorsContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
}));

const TABLE_COLUMNS = {
  CLIENT: 'CLIENT',
  CONTRACTORS: 'CONTRACTORS',
  PROJECT: 'PROJECT',
};

const SEARCHABLE_COLUMNS = [
  TABLE_COLUMNS.PROJECT,
  TABLE_COLUMNS.CLIENT,
];

const SORT_TYPES: {
  [key: string]: ('asc' | 'desc'),
} = {
  ASC: 'asc',
  DESC: 'desc',
};

const ICON_SIZE = 24;
const DEFAULT_COMPACT_COLUMN_INDEX = 1;
const DEFAULT_PAGE_INDEX = 0;

const compare = ({
  sortType,
  valueA,
  valueB,
}: any) => {
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return sortType === SORT_TYPES.ASC
      ? valueB.toLowerCase().localeCompare(valueA.toLowerCase())
      : valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  }

  return sortType === SORT_TYPES.ASC
    ? valueA - valueB
    : valueB - valueA;
};

function ClientsAndProjects() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();
  const isMobile = useIsMobile();
  const changePage = useChangePage();

  const {
    isFailed: isFailedClientsAndProjects,
    isFetching: isFetchingClientsAndProjects,
    list: clientsAndProjectsList,
  } = useSelector(({ clientsAndProjects }: any) => clientsAndProjects);

  const [state, setState] = useState({
    compactColumnIndex: DEFAULT_COMPACT_COLUMN_INDEX,
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: paginationSizes.SMALL,
    searchText: '',
    sortingCriteria: isMobile
      ? TABLE_COLUMNS.PROJECT
      : TABLE_COLUMNS.CONTRACTORS,
    sortingOrder: SORT_TYPES.DESC,
  });

  const debouncedSearchText = useDebounce({ value: state.searchText });

  const colorsToTypes = useMemo(() => ({
    [statusTypes.GREEN]: theme.colors.greenDark,
    [statusTypes.RED]: theme.colors.redDark,
    [statusTypes.YELLOW]: theme.colors.yellowDark,
  } as any), [theme]);

  const tableRows = useMemo(() => clientsAndProjectsList
    .map((project: any) => ({
      id: project.id,
      [TABLE_COLUMNS.PROJECT]: {
        label: project.name,
        value: project.name,
      },
      [TABLE_COLUMNS.CLIENT]: {
        label: project.clientName,
        value: project.clientName,
      },
      [TABLE_COLUMNS.CONTRACTORS]: {
        label: project.membersCount,
        status: project.status,
        value: project.membersCount,
      },
    })), [clientsAndProjectsList]);

  const filteredTableRows = useMemo(() => (tableRows
    .filter((row: any) => SEARCHABLE_COLUMNS
      .some(column => row[column].value.toLowerCase()
        .includes(debouncedSearchText.toLowerCase())))
  ), [
    debouncedSearchText,
    tableRows,
  ]);

  const sortedTableRows = useMemo(() => (filteredTableRows
    .sort((row1: any, row2: any) => compare({
      sortType: state.sortingOrder,
      valueA: row1[state.sortingCriteria].value,
      valueB: row2[state.sortingCriteria].value,
    }))
  ), [
    filteredTableRows,
    state.sortingCriteria,
    state.sortingOrder,
  ]);

  const tableRowsToDisplay = useMemo(() => (sortedTableRows
    .slice(
      state.pageIndex * state.pageSize,
      (state.pageIndex * state.pageSize) + state.pageSize
    )
  ), [
    sortedTableRows,
    state.pageIndex,
    state.pageSize,
    state.sortingCriteria,
    state.sortingOrder,
  ]);

  const isFailed = isFailedClientsAndProjects;
  const isFetching = isFetchingClientsAndProjects;

  const onColumnClick = (sortingCriteria: string) => {
    let sortingOrder;
    if (state.sortingCriteria === sortingCriteria) {
      sortingOrder = state.sortingOrder === SORT_TYPES.ASC
        ? SORT_TYPES.DESC
        : SORT_TYPES.ASC;
    } else {
      sortingOrder = SORT_TYPES.DESC;
    }
    setState({
      ...state,
      sortingCriteria,
      sortingOrder,
    });
  };

  const onChangeSearchField = (event: any) => {
    let { pageIndex } = state;
    if (state.pageIndex !== DEFAULT_PAGE_INDEX) {
      pageIndex = DEFAULT_PAGE_INDEX;
    }
    setState({
      ...state,
      pageIndex,
      searchText: event.target.value,
    });
  };

  const onChangeMobileColumnSelector = (columnIdx: any) => setState({
    ...state,
    compactColumnIndex: columnIdx,
  });

  const onPageChange = (event: any, pageIndex: number) => setState({
    ...state,
    pageIndex,
  });

  const onRowsPerPageChange = (event: any) => setState({
    ...state,
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: event.target.value,
  });

  useEffect(() => {
    dispatch(actionsClientsAndProjects.fetchClientsAndProjects());
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'clientsAndProjects' })}
          variant="text"
        />
      </Breadcrumbs>
      <div className={classes.actionsContainer}>
        <TextField
          AdornmentStart={<IconSearch size={ICON_SIZE} />}
          autoFocus
          fullWidth={isMobile}
          onChange={onChangeSearchField}
          placeholder={formatMessage({ id: 'search' })}
          size="small"
          value={state.searchText}
        />
      </div>
      {(isFetching || isFailed)
      && (
        <Loading variant={isFailed ? 'error' : 'loading'}>
          {isFailed && (
            <Typography
              color="secondary"
              variant="subtitle"
            >
              {formatMessage({ id: 'loading.error' })}
            </Typography>
          )}
        </Loading>
      )}
      {!isFetching
      && !isFailed
      && (
        <Card disablePaddings>
          <Table
            compactColumnIndex={state.compactColumnIndex}
            compact={isMobile}
            fixed
            onChangeCompactColumnIndex={(index: number) =>
              onChangeMobileColumnSelector(index)}
          >
            <TableHead>
              <TableRow>
                <TableCellHead
                  compactVariant="static"
                  size={!isMobile ? 'largeField' : undefined}
                  sortDirection={state.sortingOrder}
                >
                  <TableSortLabel
                    active={state.sortingCriteria === TABLE_COLUMNS.PROJECT}
                    direction={state.sortingOrder}
                    onClick={() => onColumnClick(TABLE_COLUMNS.PROJECT)}
                  >
                    <Typography
                      color="secondary"
                      variant="caption"
                    >
                      {formatMessage({ id: 'project' })}
                    </Typography>
                  </TableSortLabel>
                </TableCellHead>
                <TableCellHead
                  compactVariant="dynamic"
                  sortDirection={state.sortingOrder}
                >
                  <TableSortLabel
                    active={state.sortingCriteria === TABLE_COLUMNS.CLIENT}
                    direction={state.sortingOrder}
                    hideSortIcon={isMobile}
                    onClick={() => onColumnClick(TABLE_COLUMNS.CLIENT)}
                  >
                    <Typography
                      color="secondary"
                      variant="caption"
                    >
                      {formatMessage({ id: 'client' })}
                    </Typography>
                  </TableSortLabel>
                </TableCellHead>
                <TableCellHead
                  compactVariant="dynamic"
                  size={!isMobile ? 'smallField' : undefined}
                  sortDirection={state.sortingOrder}
                >
                  <TableSortLabel
                    active={state.sortingCriteria === TABLE_COLUMNS.CONTRACTORS}
                    direction={state.sortingOrder}
                    hideSortIcon={isMobile}
                    onClick={() => onColumnClick(TABLE_COLUMNS.CONTRACTORS)}
                  >
                    <Typography
                      align="center"
                      color="secondary"
                      variant="caption"
                    >
                      {formatMessage({ id: 'contractors' })}
                    </Typography>
                  </TableSortLabel>
                </TableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRowsToDisplay.map((row: any) => (
                <TableRow
                  hover
                  onClick={() => changePage({
                    pathname: row.id,
                  })}
                >
                  <TableCell>
                    <HighlightText search={debouncedSearchText}>
                      <Typography>
                        {row[TABLE_COLUMNS.PROJECT].label}
                      </Typography>
                    </HighlightText>
                  </TableCell>
                  <TableCell>
                    <HighlightText search={debouncedSearchText}>
                      <Typography>
                        {row[TABLE_COLUMNS.CLIENT].label}
                      </Typography>
                    </HighlightText>
                  </TableCell>
                  <TableCell>
                    <div
                      className={classes.contractorsContainer}
                      style={{
                        color: colorsToTypes[row[TABLE_COLUMNS.CONTRACTORS]
                          .status],
                      }}
                    >
                      <div className={classes.contractorCount}>
                        <Typography
                          align="right"
                          color="inherit"
                        >
                          {row[TABLE_COLUMNS.CONTRACTORS].label}
                        </Typography>
                      </div>
                      <div className={classes.contractorIcon}>
                        <IconPerson
                          color={colorsToTypes[row[TABLE_COLUMNS.CONTRACTORS]
                            .status]}
                          size={ICON_SIZE}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            count={filteredTableRows.length}
            page={state.pageIndex}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPage={state.pageSize}
            rowsPerPageOptions={paginationSizesList}
            labelRowsPerPage={(
              <Typography
                color="secondary"
                variant="caption"
              >
                {formatMessage({
                  id: isMobile
                    ? 'projectsPerPage.short'
                    : 'projectsPerPage',
                })}
              </Typography>
            )}
          />
        </Card>
      )}
    </div>
  );
}

export default ClientsAndProjects;
