const config = {
  AUTHORITIES_SERVICE: '/api/authorities',
  // Services
  AUTH_SERVICE: '/api/oauth',
  BASE_URL: process.env.REACT_APP_BASE_URL,
  COMMON_SETTINGS_SERVICE: '/api/common-settings',
  COMPENSATION_TYPES_SERVICE: '/api/compensationTypes',
  CONTRACTORS_SERVICE: '/api/contractors',
  COST_ITEMS_SERVICE: '/api/cost-items' ,
  CURRENCY_SERVICE: '/api/currency-rates',
  EXPENSES_SERVICE: '/api/expenses',
  INVOICES_SERVICE: '/api/invoices',
  // OAUTH
  OAUTH_ACCESS_TYPE: 'offline',
  OAUTH_AUTH_URL: 'https://accounts.google.com/o/oauth2/v2/auth',
  OAUTH_CLIENT_ID: process.env.REACT_APP_OAUTH_CLIENT_ID,
  OAUTH_PROMPT: 'select_account',
  OAUTH_REDIRECT_URI: process.env.REACT_APP_OAUTH_REDIRECT_URI,
  OAUTH_RESPONSE_TYPE: 'code',
  OAUTH_SCOPE: 'https://www.googleapis.com/auth/userinfo.email',
  PAYROLLS_SERVICE: '/api/payrolls',
  PROJECT_SERVICE: '/api/projects',
  REPORTS_SERVICE: '/api/reports',
  UI_URL_PREFIX: process.env.REACT_APP_UI_URL_PREFIX || '',
  USERS_SERVICE: '/api/users',
};

export default config;
