import {
  ERROR_RECEIVE_EXPENSES,
  ERROR_UPDATE_EXPENSES,
  RECEIVE_EXPENSES,
  REQUEST_EXPENSES,
  REQUEST_UPDATE_EXPENSES,
  SUCCESS_UPDATE_EXPENSES,
} from '../constants/actionTypes';

import config from 'config';
import axios from 'utils/requests';

const requestExpenses = () => ({
  type: REQUEST_EXPENSES,
});

const receiveExpenses = (response: any) => ({
  payload: response,
  type: RECEIVE_EXPENSES,
});

const errorReceiveExpenses = () => ({
  type: ERROR_RECEIVE_EXPENSES,
});

const getExpenses = ({
  quarter,
  year,
}: any) => {
  const {
    BASE_URL,
    EXPENSES_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${EXPENSES_SERVICE}/${year}/${quarter}`)
    .then(({ data }) => data);
};

const fetchExpenses = ({
  quarter,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestExpenses());
  return getExpenses({
    quarter,
    year,
  }).then(expenses => dispatch(receiveExpenses(expenses)))
    .catch(() => dispatch(errorReceiveExpenses()));
};

const requestUpdateExpenses = () => ({
  type: REQUEST_UPDATE_EXPENSES,
});

const successUpdateExpenses = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_EXPENSES,
});

const errorUpdateExpenses = () => ({
  type: ERROR_UPDATE_EXPENSES,
});

const updateExpenses = ({
  expenses,
  quarter,
  year,
}: any) => {
  const {
    BASE_URL,
    EXPENSES_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${EXPENSES_SERVICE}/${year}/${quarter}`, expenses);
};

const fetchUpdateExpenses = ({
  expenses,
  quarter,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateExpenses());
  return updateExpenses({
    expenses,
    quarter,
    year,
  }).then(() => {
    return getExpenses({
      quarter,
      year,
    }).then((expenses) => dispatch(successUpdateExpenses(expenses)))
      .catch(() => dispatch(errorReceiveExpenses()));
  }).catch(() => dispatch(errorUpdateExpenses()));
};

const exportFunctions = {
  fetchExpenses,
  fetchUpdateExpenses,
};

export default exportFunctions;
