import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import ContractorPage from '../pages/contractor';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';

function Contractor() {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.CONTRACTORS}>
        <ContractorPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default Contractor;
