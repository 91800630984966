import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import CommonSettingsPage from 'pages/commonSettings';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';


function CommonSettings() {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.COMMON_SETTINGS}>
        <CommonSettingsPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default CommonSettings;
