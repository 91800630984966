import {
  ERROR_CREATE_TYPE,
  ERROR_DELETE_TYPE,
  ERROR_RECEIVE_TYPES,
  ERROR_UPDATE_TYPE,
  RECEIVE_TYPES,
  REQUEST_CREATE_TYPE,
  REQUEST_DELETE_TYPE,
  REQUEST_TYPES,
  REQUEST_UPDATE_TYPE,
  SUCCESS_CREATE_TYPE,
  SUCCESS_DELETE_TYPE,
  SUCCESS_UPDATE_TYPE,
} from '../constants/actionTypes';

import axios from 'axios';
import config from 'config';

const requestTypes = () => ({
  type: REQUEST_TYPES,
});

const receiveTypes = (response: any) => ({
  payload: response,
  type: RECEIVE_TYPES,
});

const errorReceiveTypes = () => ({
  type: ERROR_RECEIVE_TYPES,
});

const getTypes = () => {
  const {
    BASE_URL,
    COMPENSATION_TYPES_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${COMPENSATION_TYPES_SERVICE}`)
    .then(({ data }) => data);
};

const fetchTypes = () => (dispatch: any) => {
  dispatch(requestTypes());
  return getTypes()
    .then(types => dispatch(receiveTypes(types)))
    .catch(() => dispatch(errorReceiveTypes()));
};

const requestCreateType = () => ({
  type: REQUEST_CREATE_TYPE,
});

const successCreateType = (response: any) => ({
  payload: response,
  type: SUCCESS_CREATE_TYPE,
});

const errorCreateType = (response: any) => ({
  payload: response,
  type: ERROR_CREATE_TYPE,
});

const createType = ({
  businessProcess,
  name,
}: any) => {
  const {
    BASE_URL,
    COMPENSATION_TYPES_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${COMPENSATION_TYPES_SERVICE}`, {
      businessProcess,
      name,
    });
};

const fetchCreateType = ({
  businessProcess,
  name,
}: any) => (dispatch: any) => {
  dispatch(requestCreateType());
  return createType({
    businessProcess,
    name,
  }).then(() => (
    getTypes()
      .then((types) => dispatch(successCreateType(types)))
      .catch(() => dispatch(errorReceiveTypes()))
  )).catch((error) => dispatch(errorCreateType(error)));
};

const requestDeleteType = () => ({
  type: REQUEST_DELETE_TYPE,
});

const successDeleteType = (response: any) => ({
  payload: response,
  type: SUCCESS_DELETE_TYPE,
});

const errorDeleteType = (response: any) => ({
  payload: response,
  type: ERROR_DELETE_TYPE,
});

const deleteType = ({ typeId }: any) => {
  const {
    BASE_URL,
    COMPENSATION_TYPES_SERVICE,
  } = config;

  return axios
    .delete(`${BASE_URL}${COMPENSATION_TYPES_SERVICE}/${typeId}`);
};

const fetchDeleteType = ({
  typeId,
}: any) => (dispatch: any) => {
  dispatch(requestDeleteType());
  return deleteType({
    typeId,
  }).then(() => (
    getTypes()
      .then((types) => dispatch(successDeleteType(types)))
      .catch(() => dispatch(errorReceiveTypes()))
  )).catch((error) => dispatch(errorDeleteType(error)));
};

const requestUpdateType = () => ({
  type: REQUEST_UPDATE_TYPE,
});

const successUpdateType = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_TYPE,
});

const errorUpdateType = (response: any) => ({
  payload: response,
  type: ERROR_UPDATE_TYPE,
});

const updateType = ({
  businessProcess,
  name,
  typeId,
}: any) => {
  const {
    BASE_URL,
    COMPENSATION_TYPES_SERVICE,
  } = config;

  return axios
    .put(`${BASE_URL}${COMPENSATION_TYPES_SERVICE}/${typeId}`, {
      businessProcess,
      name,
    });
};

const fetchUpdateType = ({
  businessProcess,
  name,
  typeId,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateType());
  return updateType({
    businessProcess,
    name,
    typeId,
  }).then(() => (
    getTypes()
      .then((types) => dispatch(successUpdateType(types)))
      .catch(() => dispatch(errorReceiveTypes()))
  )).catch((error) => dispatch(errorUpdateType(error)));
};

const exportFunctions = {
  fetchCreateType,
  fetchDeleteType,
  fetchTypes,
  fetchUpdateType,
};

export default exportFunctions;
