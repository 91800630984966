import React from 'react';
import TableHeadMui from '@mui/material/TableHead';

const TableHead = ({ children }: IProps) => {
  return (
    <TableHeadMui>
      {children}
    </TableHeadMui>
  );
};

TableHead.pswName = 'TableHead';

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

export default TableHead;
