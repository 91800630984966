import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import CurrencyRatesPage from 'pages/currencyRates';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';

function CurrencyRates() {
  return (
    <PageContainer>
      <PageAccessValidator neededAuthorities={authoritiesUI.SETTINGS_FINANCES}>
        <CurrencyRatesPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default CurrencyRates;
