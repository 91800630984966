import {
  CLEAR_AFTER_FETCH_STATUS,
  ERROR_DOWNLOAD_INVOICE,
  ERROR_DOWNLOAD_REGISTRY,
  ERROR_SEND_CONFIRMED_INVOICES,
  ERROR_SEND_INVOICE,
  REQUEST_DOWNLOAD_INVOICE,
  REQUEST_DOWNLOAD_REGISTRY,
  REQUEST_SEND_CONFIRMED_INVOICES,
  REQUEST_SEND_INVOICE,
  RESET_REDUCERS_DATA,
  SUCCESS_DOWNLOAD_INVOICE,
  SUCCESS_DOWNLOAD_REGISTRY,
  SUCCESS_SEND_CONFIRMED_INVOICES,
  SUCCESS_SEND_INVOICE,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  message: string,
  type: string,
}


const initialState = {
  errorMessage: '',
  isFailedDownloadInvoice: false,
  isFailedDownloadRegistry: false,
  isFailedSendInvoice: false,
  isFailedSendingConfirmedInvoices: false,
  isFetchingDownloadInvoice: false,
  isFetchingDownloadRegistry: false,
  isFetchingSendConfirmedInvoices: false,
  isFetchingSendInvoice: false,
  isSuccessSendConfirmedInvoices: false,
  isSuccessSendInvoice: false,
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_DOWNLOAD_INVOICE: {
      return {
        ...state,
        errorMessage: '',
        isFailedDownloadInvoice: false,
        isFetchingDownloadInvoice: true,
      };
    }

    case SUCCESS_DOWNLOAD_INVOICE: {
      return {
        ...state,
        errorMessage: '',
        isFailedDownloadInvoice: false,
        isFetchingDownloadInvoice: false,
      };
    }

    case ERROR_DOWNLOAD_INVOICE: {
      const error = action.payload;
      const errorMessage = error?.message;

      return {
        ...state,
        errorMessage: errorMessage,
        isFailedDownloadInvoice: true,
        isFetchingDownloadInvoice: false,
      };
    }

    case REQUEST_DOWNLOAD_REGISTRY: {
      return {
        ...state,
        errorMessage: '',
        isFailedDownloadRegistry: false,
        isFetchingDownloadRegistry: true,
      };
    }

    case SUCCESS_DOWNLOAD_REGISTRY: {
      return {
        ...state,
        errorMessage: '',
        isFailedDownloadRegistry: false,
        isFetchingDownloadRegistry: false,
      };
    }

    case ERROR_DOWNLOAD_REGISTRY: {
      const error = action.payload;
      const errorMessage = error?.message;

      return {
        ...state,
        errorMessage: errorMessage,
        isFailedDownloadRegistry: true,
        isFetchingDownloadRegistry: false,
      };
    }

    case REQUEST_SEND_INVOICE: {
      return {
        ...state,
        errorMessage: '',
        isFailedSendInvoice: false,
        isFetchingSendInvoice: true,
        isSuccessSendInvoice: false,
      };
    }

    case SUCCESS_SEND_INVOICE: {
      return {
        ...state,
        errorMessage: '',
        isFailedSendInvoice: false,
        isFetchingSendInvoice: false,
        isSuccessSendInvoice: true,
      };
    }

    case ERROR_SEND_INVOICE: {
      return {
        ...state,
        errorMessage: action.message,
        isFailedSendInvoice: true,
        isFetchingSendInvoice: false,
        isSuccessSendInvoice: false,
      };
    }

    case REQUEST_SEND_CONFIRMED_INVOICES: {
      return {
        ...state,
        errorMessage: '',
        isFailedSendingConfirmedInvoices: false,
        isFetchingSendConfirmedInvoices: true,
        isSuccessSendConfirmedInvoices: false,
      };
    }

    case SUCCESS_SEND_CONFIRMED_INVOICES: {
      return {
        ...state,
        errorMessage: '',
        isFailedSendingConfirmedInvoices: false,
        isFetchingSendConfirmedInvoices: false,
        isSuccessSendConfirmedInvoices: true,
      };
    }

    case ERROR_SEND_CONFIRMED_INVOICES: {
      return {
        ...state,
        errorMessage: action.payload,
        isFailedSendingConfirmedInvoices: true,
        isFetchingSendConfirmedInvoices: false,
        isSuccessSendConfirmedInvoices: false,
      };
    }

    case CLEAR_AFTER_FETCH_STATUS: {
      return {
        ...state,
        errorMessage: '',
        isFailedDownloadInvoice: false,
        isFailedDownloadRegistry: false,
        isFailedSendInvoice: false,
        isSuccessSendInvoice: false,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
