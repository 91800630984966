import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import classNames from 'classnames';
import IconButton from 'components/IconButton';
import IconClose from 'components/icons/Close';
import IconEdit from 'components/icons/Edit';
import IconPlus from 'components/icons/Plus';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Popper from 'components/Popper';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import useTheme from 'hooks/useTheme';

import * as pages from 'constants/pages';
import orderedCurrenciesList, * as currencies from 'constants/currencies';
import actionsCurrency from '../actions/currency';
import pagesURLs from 'constants/pagesURLs';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  addCurrency: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: `${theme.spacing(1)}px`,
    width: '50px',
  },
  addMonth: {
    paddingLeft: `${theme.spacing(1)}px`,
  },
  cellCurrency: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  cellCurrencyContainer: {
    background: theme.card.background.paper,
    border: `1px solid ${theme.colors.greyLight}`,
    boxSizing: 'border-box',
    height: '40px',
    maxWidth: '50px',
    minWidth: '50px',
    padding: `0px ${theme.spacing(0.5)}px`,
  },
  cellMonth: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  cellMonthContainer: {
    background: theme.card.background.paper,
    border: `1px solid ${theme.colors.greyLight}`,
    boxSizing: 'border-box',
    flex: 1,
    height: '40px',
    maxWidth: '80px',
    minWidth: '0px',
    padding: `0px ${theme.spacing(0.5)}px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  editable: {
    background: `${theme.card.background.edit} !important`,
  },
  error: {
    border: `1px solid ${theme.colors.redDark}`,
  },
  hovered: {
    background: `${theme.hover.background} !important`,
    cursor: 'pointer',
  },
  paper: {
    background: theme.card.background.paper,
  },
  popoverContentLeftValue: {
    display: 'flex',
    justifyContent: 'end',
    width: '60px',
  },
  popoverContentRow: {
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
  popperContent: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.cobalt}`,
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
    padding: `${theme.spacing(1)}px`,
  },
  row: {
    display: 'flex',
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '900px',
  },
  selectYear: {
    width: '80px',
  },
}));

const totalMonthsCount = 12;

const orderedMonths = Array
  .from(new Array(totalMonthsCount).keys())
  .map(key => key + 1);

const orderedCurrencies = orderedCurrenciesList
  .filter((currency: any) => currency !== currencies.USD);

const currentMonth = (new Date()).getMonth() + 1;
const currentYear = (new Date()).getFullYear();

const availableOrderedYears = [
  currentYear + 1,
  currentYear,
  ...Array
    .from(new Array(3).keys())
    .map(index => currentYear - (index + 1)),
];

const groupCurrencies = (list: any) => list
  .reduce((acc: any, currency: any) => {
    acc[currency.year] = {
      ...(acc[currency.year] || {}),
      [currency.month]: {
        ...(acc[currency.year]?.[currency.month] || {}),
        [currency.currency]: {
          value: currency.value,
        },
      },
    };
    return acc;
  }, {} as any);

const ungroupCurrencies = (object: any) => Object
  .entries(object)
  .reduce((acc: any, [year, currenciesToMonths]) => {
    Object
      .entries(currenciesToMonths as any)
      .forEach(([month, dataToCurrencies]) => {
        Object
          .entries(dataToCurrencies as any)
          .forEach(([currency, data]: any) => {
            acc.push({
              currency,
              month,
              value: data.value,
              year,
            });
          });
      });
    return acc;
  }, []);

const formatNumber = (value: any) => value
  ? value.toString().replace('.', ',')
  : value;

function CurrencyRates() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();
  const componentDidMount = useRef(false);

  const {
    failedSaveItems,
    isFailed: isFailedCurrencies,
    isFetching: isFetchingCurrencies,
    isFetchingSave,
    list: currenciesList,
    successSavedItems,
  } = useSelector(({
    currency: reducerCurrency,
  }: any) => reducerCurrency);

  const [state, setState]: any = useState({
    addCurrency: {
      anchor: null,
    },
    editableItems: [],
    groupedCurrencies: {},
    isCreatingMode: false,
    isEditingMode: false,
    popover: {
      anchor: null,
      currency: null,
      month: null,
    },
    selectedYear: currentYear,
    showAfterSaveAlert: false,
    usedCurrencies: [],
  });

  const availableCurrenciesToAdd = useMemo(
    () => orderedCurrencies
      .filter((currency: any) => !state.usedCurrencies
        .includes(currency)),
    [state.usedCurrencies]
  );

  const usedOrderedMonths = orderedMonths;

  const isEditable = ({
    currency,
    month,
    year,
  }: any) => state.editableItems
    .some((item: any) => item.currency === currency
      && item.month === month
      && item.year === year);

  const isEnableToAddMonth = () => {
    return (state.isEditingMode || state.isCreatingMode)
      && usedOrderedMonths.length < totalMonthsCount;
  };

  const isEnableToAddCurrency = () => {
    return (state.isEditingMode || state.isCreatingMode)
      && !!availableCurrenciesToAdd.length;
  };

  const getValue = ({
    currency,
    month,
    year,
  }: any) => {
    const foundEditableItem = state.editableItems
      .find((item: any) => item.year === year
        && item.month === month
        && item.currency === currency
      );
    return foundEditableItem
      ? foundEditableItem.value
      : state.groupedCurrencies[year]?.[month]?.[currency]?.value;
  };

  const onAddCurrency = (currency: any) => {
    const newGroupedCurrencies = { ...state.groupedCurrencies };
    newGroupedCurrencies[state.selectedYear] = Object
      .entries(newGroupedCurrencies[state.selectedYear])
      .reduce((acc: any, [month, currencies]) => {
        acc[month] = {
          ...(currencies as any),
          [currency]: {},
        };
        return acc;
      }, {});
    setState({
      ...state,
      addCurrency: {
        anchor: null,
      },
      groupedCurrencies: newGroupedCurrencies,
      usedCurrencies: state.usedCurrencies.concat(currency),
    });
  };

  const onAddMonth = (month: any) => {
    const newGroupedCurrencies = { ...state.groupedCurrencies };
    newGroupedCurrencies[state.selectedYear] = {
      ...newGroupedCurrencies[state.selectedYear],
      [month]: state.usedCurrencies
        .reduce((acc: any, currency: any) => {
          acc[currency] = {};
          return acc;
        }, {}),
    };
    setState({
      ...state,
      groupedCurrencies: newGroupedCurrencies,
    });
  };

  const onAddNewYear = (year: any) => setState({
    ...state,
    groupedCurrencies: {
      [year]: Array
        .from(new Array(currentMonth).keys())
        .map(key => key + 1)
        .reduce((acc: any, month: any) => {
          acc[month] = {};
          return acc;
        }, {}),
    },
    isCreatingMode: true,
  });

  const onCancelEdit = () => {
    let {
      editableItems,
      groupedCurrencies,
      isCreatingMode,
      usedCurrencies,
    } = state;
    if (isCreatingMode) {
      const newGroupedCurrencies = { ...groupedCurrencies };
      delete newGroupedCurrencies[state.selectedYear];
      groupedCurrencies = newGroupedCurrencies;
      usedCurrencies = [];
    } else {
      const ungroupedCurrencies = ungroupCurrencies(groupedCurrencies);
      const filteredUngroupedCurrencies = ungroupedCurrencies
        .filter((currency: any) => !!currency.value);
      usedCurrencies = usedCurrencies
        .filter((usedCurrency: any) => filteredUngroupedCurrencies
          .some((item: any) => item.currency === usedCurrency));
      groupedCurrencies = groupCurrencies(filteredUngroupedCurrencies);
    }
    editableItems = [];
    setState({
      ...state,
      editableItems,
      groupedCurrencies,
      isCreatingMode: false,
      isEditingMode: false,
      usedCurrencies,
    });
    dispatch(actionsCurrency.clearSavingResults());
  };

  const onValueClick = ({
    currency,
    month,
    value,
    year,
  }: any) => {
    if (!isEditable({
      currency,
      month,
      year,
    })) {
      const editableItems = state.editableItems.concat({
        currency,
        month,
        value,
        year,
      });
      setState({
        ...state,
        editableItems,
        isEditingMode: true,
      });
    }
  };

  const onValueChange = ({
    currency,
    month,
    value,
    year,
  }: any) => setState({
    ...state,
    editableItems: state.editableItems
      .map((item: any) => (item.year === year
        && item.month === month
        && item.currency === currency)
        ? {
          ...item,
          value,
        } : item
      ),
  });

  const onValueBlur = ({
    currency,
    month,
    year,
  }: any) => {
    const oldValue = state.groupedCurrencies[year]?.[month]?.[currency]?.value;
    const newValue = state.editableItems
      .find((item: any) => item.year === year
        && item.month === month
        && item.currency === currency
      )?.value;
    if ((!oldValue && !newValue) || (oldValue === newValue)) {
      setState({
        ...state,
        editableItems: state.editableItems
          .filter((item: any) => item.year !== year
            || item.month !== month
            || item.currency !== currency),
      });
    }
  };

  const onSave = () => {
    const groupedSaveList = state.editableItems
      .reduce((acc: any, item: any) => ({
        ...acc,
        [item.year]: {
          ...(acc[item.year] || {}),
          [item.currency]: {
            ...(acc[item.year]?.[item.currency] || {}),
            [item.month]: item.value,
          },
        },
      }), {});
    const saveList = Object
      .entries(groupedSaveList)
      .reduce((acc: any, [year, monthsToCurrenciesObj]: any) => {
        Object
          .entries(monthsToCurrenciesObj)
          .forEach(([currency, valuesToMonthsObj]: any) => {
            const saveRates = Object
              .entries(valuesToMonthsObj)
              .map(([month, value]: any) => ({
                month: +month,
                rateToUsd: value,
              }));
            acc.push({
              currency,
              saveRates,
              year: +year,
            });
          });
        return acc;
      }, []);

    dispatch(actionsCurrency.fetchSaveCurrencies({ saveList }));
  };

  const onCloseAlert = () => setState((prevState: any) => ({
    ...prevState,
    showAfterSaveAlert: false,
  }));

  useEffect(() => {
    if (componentDidMount.current && successSavedItems.length) {
      const groupedCurrencies = { ...state.groupedCurrencies };
      const deletedItems = [] as any;
      const updatedItems = [] as any;
      successSavedItems.forEach((item: any) => {
        if (item.value) {
          updatedItems.push(item);
        } else {
          deletedItems.push(item);
        }
      });
      updatedItems.forEach((item: any) => {
        groupedCurrencies[item.year] = {
          ...groupedCurrencies[item.year],
          [item.month]: {
            ...groupedCurrencies[item.year]?.[item.month],
            [item.currency]: {
              value: item.value,
            },
          },
        };
      });
      deletedItems.forEach((item: any) => {
        delete groupedCurrencies[item.year][item.month][item.currency];
      });
      const isEqual = (item1: any, item2: any) => item1.year === item2.year
        && item1.month === item2.month
        && item1.currency === item2.currency;
      const editableItems = state.editableItems
        .filter((editableItem: any) => !successSavedItems
          .some((item: any) => isEqual(item, editableItem)));
      let {
        isCreatingMode,
        isEditingMode,
      } = state;
      if (!failedSaveItems.length) {
        isCreatingMode = false;
        isEditingMode = false;
      }
      setState((prevState: any) => ({
        ...prevState,
        editableItems,
        groupedCurrencies,
        isCreatingMode,
        isEditingMode,
      }));
    }
  }, [successSavedItems]);

  useEffect(() => {
    if (currenciesList) {
      const groupedCurrencies = groupCurrencies(currenciesList);
      const currenciesToMonths = groupedCurrencies[state.selectedYear] || {};
      const usedCurrencies = Object
        .values(currenciesToMonths)
        .reduce((acc: any, valuesToCurrencies: any) => {
          Object
            .keys(valuesToCurrencies)
            .forEach((currency) => {
              if (!acc.includes(currency)) {
                acc.push(currency);
              }
            });
          return acc;
        }, []);
      setState((prevState: any) => ({
        ...prevState,
        groupedCurrencies,
        usedCurrencies,
      }));
    }
  }, [currenciesList]);

  useEffect(() => {
    dispatch(actionsCurrency.fetchCurrencies(state.selectedYear));
  }, [state.selectedYear]);

  useEffect(() => {
    if (componentDidMount.current
      && (failedSaveItems.length || successSavedItems.length)
    ) {
      setState((prevState: any) => ({
        ...prevState,
        showAfterSaveAlert: true,
      }));
    }
  }, [failedSaveItems, successSavedItems]);

  useEffect(() => {
    componentDidMount.current = true;
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'settings' })}
          to={{
            pathname: `${pagesURLs[pages.settings]}`,
          }}
          variant="link"
        />
        <Breadcrumb
          label={formatMessage({ id: 'currencyRates' })}
          variant="text"
        />
      </Breadcrumbs>
      <div className={classes.selectYear}>
        <Select
          disabled={state.isEditingMode || state.isCreatingMode}
          fullWidth
          onChange={({ target }) => setState({
            ...state,
            selectedYear: target.value,
          })}
          size="small"
          value={state.selectedYear}
          variant="outlined"
        >
          {availableOrderedYears.map(year => (
            <MenuItem value={year}>
              <Typography>
                {year}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </div>
      {(isFetchingCurrencies || isFailedCurrencies)
      && (
        <Loading variant={isFailedCurrencies ? 'error' : 'loading'}>
          {isFailedCurrencies && (
            <Typography color="secondary" variant="subtitle">
              {formatMessage({ id: 'loading.error' })}
            </Typography>
          )}
        </Loading>
      )}
      {!state.groupedCurrencies[state.selectedYear]
      && !isFetchingCurrencies
      && !isFailedCurrencies && (
        <Card>
          <CardTitle>
            <Typography
              color="secondary"
            >
              {formatMessage(
                { id: 'addNewYear' },
                { value: state.selectedYear }
              )}
            </Typography>
            <Button
              onClick={() => onAddNewYear(state.selectedYear)}
              variant='primary'
              startIcon={(
                <IconPlus
                  color='button'
                  size={20}
                />
              )}
            >
              <Typography color='inherit'>
                {formatMessage({ id: 'add' })}
              </Typography>
            </Button>
          </CardTitle>
        </Card>
      )}
      {!isFetchingCurrencies
      && !isFailedCurrencies
      && state.groupedCurrencies[state.selectedYear]
      && (
        <Card
          variant={(state.isEditingMode || state.isCreatingMode) && 'edit'}
        >
          <CardTitle>
            <Typography
              color="secondary"
              variant="subtitle"
            >
              {formatMessage(
                { id: 'title' },
                { value: state.selectedYear }
              )}
            </Typography>
            {(state.isCreatingMode || state.isEditingMode) && (
              <IconButton
                disableHoverSpace
                onClick={() => onCancelEdit()}
              >
                <IconClose />
              </IconButton>
            )}
            {!state.isEditingMode && !state.isCreatingMode && (
              <IconButton
                disableHoverSpace
                onClick={() => setState({
                  ...state,
                  isEditingMode: true,
                })}
              >
                <IconEdit size={20} />
              </IconButton>
            )}
          </CardTitle>
          <CardContent>
            <div className={classes.rows}>
              <div className={classes.row}>
                <div className={classes.cellCurrencyContainer}>
                  <div className={classes.cellCurrency} />
                </div>
                {usedOrderedMonths.map((month: any) => (
                  <div
                    className={classNames(
                      classes.cellMonthContainer,
                      month === state.popover.month && classes.hovered
                    )}
                  >
                    <div className={classes.cellMonth}>
                      {month === currentMonth
                      && state.selectedYear === currentYear
                      && (
                        <Typography variant="caption">
                          <strong>
                            {formatMessage({ id: `month.${month}` })}
                          </strong>
                        </Typography>
                      )}
                      {(month !== currentMonth
                        || state.selectedYear !== currentYear
                      ) && (
                        <Typography color="secondary" variant="caption">
                          {formatMessage({ id: `month.${month}` })}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
                {isEnableToAddMonth() && (
                  <div className={classes.addMonth}>
                    <IconButton
                      onClick={() => onAddMonth(usedOrderedMonths
                        .slice(-1)[0] + 1)
                      }
                    >
                      <IconPlus size={32}/>
                    </IconButton>
                  </div>
                )}
              </div>
              {state.usedCurrencies.map((currency: any) => (
                <div className={classes.row}>
                  <div
                    className={classNames(
                      classes.cellCurrencyContainer,
                      currency === state.popover.currency && classes.hovered
                    )}
                  >
                    <div className={classes.cellCurrency}>
                      <Typography color="secondary" variant="caption">
                        {currency}
                      </Typography>
                    </div>
                  </div>
                  {usedOrderedMonths.map(month => (
                    <div
                      className={classNames(
                        classes.cellMonthContainer,
                        month === state.popover.month
                          && currency === state.popover.currency
                          && !isEditable({
                            currency,
                            month,
                            year: state.selectedYear,
                          })
                          && classes.hovered,
                        isEditable({
                          currency,
                          month,
                          year: state.selectedYear,
                        }) && classes.editable,
                        failedSaveItems
                          .some((item: any) => item.month === month
                            && item.currency === currency)
                          && classes.error
                      )}
                      onClick={() => onValueClick({
                        currency,
                        month,
                        value: state.groupedCurrencies[state.selectedYear]
                          ?.[month]
                          ?.[currency]?.value,
                        year: state.selectedYear,
                      })}
                      onMouseEnter={({ currentTarget }) => setState({
                        ...state,
                        popover: {
                          ...state.popover,
                          anchor: currentTarget,
                          currency,
                          month,
                        },
                      })}
                      onMouseLeave={() => setState({
                        ...state,
                        popover: {
                          ...state.popover,
                          anchor: null,
                          currency: null,
                          month: null,
                        },
                      })}
                    >
                      <div className={classes.cellMonth}>
                        {isEditable({
                          currency,
                          month,
                          year: state.selectedYear,
                        })
                          ? (
                            <TextField
                              autoFocus
                              isError={failedSaveItems
                                .some((item: any) => item.month === month
                                  && item.currency === currency)}
                              onBlur={() => onValueBlur({
                                currency,
                                month,
                                year: state.selectedYear,
                              })}
                              onChange={({ target }) => onValueChange({
                                currency,
                                month,
                                value: target.value,
                                year: state.selectedYear,
                              })}
                              inputType="number"
                              value={getValue({
                                currency,
                                month,
                                year: state.selectedYear,
                              })}
                              variant="standard"
                            />
                          )
                          : (
                            <Typography noWrap>
                              {formatNumber(getValue({
                                currency,
                                month,
                                year: state.selectedYear,
                              }))}
                            </Typography>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              {isEnableToAddCurrency() && (
                <div className={classes.row}>
                  <div className={classes.addCurrency}>
                    <IconButton onClick={({ currentTarget }) => setState({
                      ...state,
                      addCurrency: {
                        anchor: currentTarget,
                      },
                    })}>
                      <IconPlus size={32}/>
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
          </CardContent>
          {(state.isEditingMode || state.isCreatingMode) && (
            <CardActions>
              <Button
                onClick={() => onCancelEdit()}
                variant='secondary'
              >
                <Typography color='inherit'>
                  {formatMessage({ id: 'cancel' })}
                </Typography>
              </Button>
              <Button
                isLoading={isFetchingSave}
                disabled={!state.editableItems.length}
                onClick={() => onSave()}
                variant='primary'
              >
                <Typography color='inherit'>
                  {formatMessage({ id: 'save' })}
                </Typography>
              </Button>
            </CardActions>
          )}
        </Card>
      )}
      {state.addCurrency.anchor && (
        <Menu
          anchorEl={state.addCurrency.anchor}
          open
          onClose={() => setState({
            ...state,
            addCurrency: {
              anchor: null,
            },
          })}
        >
          {availableCurrenciesToAdd.map((currency: any) => (
            <MenuItem
              onClick={() => onAddCurrency(currency)}
            >
              <Typography>
                {currency}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
      {!!state.popover.anchor && !!getValue({
        currency: state.popover.currency,
        month: state.popover.month,
        year: state.selectedYear,
      }) && (
        <Popper
          anchorEl={state.popover.anchor}
          open
          placement="bottom-start"
        >
          <div className={classes.popperContent}>
            <Typography
              color="secondary"
            >
              {`${formatMessage({
                id: `month.${state.popover.month}`,
              })} ${state.selectedYear}:`}
            </Typography>
            <div />
            {[1, 100, 1000].map(value => (
              <div className={classes.popoverContentRow}>
                <div className={classes.popoverContentLeftValue}>
                  <Typography variant="caption">
                    {`${value} ${state.popover.currency}`}
                  </Typography>
                </div>
                <Typography variant="caption">
                  =
                </Typography>
                <Typography variant="caption">
                  {`${formatNumber((value / getValue({
                    currency: state.popover.currency,
                    month: state.popover.month,
                    year: state.selectedYear,
                  })).toFixed(2))}$`}
                </Typography>
              </div>
            ))}
          </div>
        </Popper>
      )}

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={onCloseAlert}
        open={state.showAfterSaveAlert && !failedSaveItems.length}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {formatMessage({ id: 'save.success' })}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar
        onClose={(event: any, reason: string) => {
          if (reason !== 'clickaway') {
            onCloseAlert();
          }
        }}
        open={state.showAfterSaveAlert && !!failedSaveItems.length}
      >
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {formatMessage({ id: 'save.error' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseAlert}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default CurrencyRates;
