import {
  ERROR_CREATE_COMPENSATION,
  ERROR_DELETE_COMPENSATION,
  ERROR_RECEIVE_COMPENSATIONS,
  ERROR_UPDATE_COMPENSATION,
  RECEIVE_COMPENSATIONS,
  REQUEST_COMPENSATIONS,
  REQUEST_CREATE_COMPENSATION,
  REQUEST_DELETE_COMPENSATION,
  REQUEST_UPDATE_COMPENSATION,
  SUCCESS_CREATE_COMPENSATION,
  SUCCESS_DELETE_COMPENSATION,
  SUCCESS_UPDATE_COMPENSATION,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const requestCompensations = () => ({
  type: REQUEST_COMPENSATIONS,
});

const receiveCompensations = (response: any) => ({
  payload: response,
  type: RECEIVE_COMPENSATIONS,
});

const errorReceiveCompensations = () => ({
  type: ERROR_RECEIVE_COMPENSATIONS,
});

const getCompensations = (contractorId: any) => {
  const {
    BASE_URL,
    CONTRACTORS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${CONTRACTORS_SERVICE}/${contractorId}/compensations/list`)
    .then(({ data }) => data);
};

const fetchCompensations = (contractorId: any) => (dispatch: any) => {
  dispatch(requestCompensations());
  return getCompensations(contractorId)
    .then(compensations => dispatch(receiveCompensations(compensations)))
    .catch(() => dispatch(errorReceiveCompensations()));
};

const requestUpdateCompensation = () => ({
  type: REQUEST_UPDATE_COMPENSATION,
});

const successUpdateCompensation = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_COMPENSATION,
});

const errorUpdateCompensation = (response: any) => ({
  payload: response,
  type: ERROR_UPDATE_COMPENSATION,
});

const updateCompensation = ({
  comment,
  compensationId,
  contractorId,
  currency,
  from,
  rateMeasure,
  sum,
  to,
  typeId,
}: any) => {
  const {
    BASE_URL,
    CONTRACTORS_SERVICE,
  } = config;

  return axios.put(
    `${BASE_URL}${CONTRACTORS_SERVICE}/${contractorId}/compensations/${
      compensationId}`,
    {
      comment,
      currency,
      periodicity: {
        from,
        to,
      },
      rateMeasure,
      sum,
      typeId,
    }
  );
};

const fetchUpdateCompensation = ({
  comment,
  compensationId,
  contractorId,
  currency,
  from,
  rateMeasure,
  sum,
  to,
  typeId,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateCompensation());
  return updateCompensation({
    comment,
    compensationId,
    contractorId,
    currency,
    from,
    rateMeasure,
    sum,
    to,
    typeId,
  }).then(() => (
    getCompensations(contractorId)
      // eslint-disable-next-line max-len
      .then(compensations => dispatch(successUpdateCompensation(compensations)))
  )).catch((error) => dispatch(errorUpdateCompensation(error)));
};

const requestCreateCompensation = () => ({
  type: REQUEST_CREATE_COMPENSATION,
});

const successCreateCompensation = (response: any) => ({
  payload: response,
  type: SUCCESS_CREATE_COMPENSATION,
});

const errorCreateCompensation = (response: any) => ({
  payload: response,
  type: ERROR_CREATE_COMPENSATION,
});

const createCompensation = ({
  comment,
  contractorId,
  currency,
  from,
  rateMeasure,
  sum,
  to,
  typeId,
}: any) => {
  const {
    BASE_URL,
    CONTRACTORS_SERVICE,
  } = config;

  return axios.post(
    `${BASE_URL}${CONTRACTORS_SERVICE}/${contractorId}/compensations`,
    {
      comment,
      currency,
      periodicity: {
        from,
        to,
      },
      rateMeasure,
      sum,
      typeId,
    }
  );
};

const fetchCreateCompensation = ({
  comment,
  contractorId,
  currency,
  from,
  rateMeasure,
  sum,
  to,
  typeId,
}: any) => (dispatch: any) => {
  dispatch(requestCreateCompensation());
  return createCompensation({
    comment,
    contractorId,
    currency,
    from,
    rateMeasure,
    sum,
    to,
    typeId,
  }).then(() => (
    getCompensations(contractorId)
      // eslint-disable-next-line max-len
      .then(compensations => dispatch(successCreateCompensation(compensations))))
  ).catch((error) => dispatch(errorCreateCompensation(error)));
};

const requestDeleteCompensation = () => ({
  type: REQUEST_DELETE_COMPENSATION,
});

const successDeleteCompensation = (response: any) => ({
  payload: response,
  type: SUCCESS_DELETE_COMPENSATION,
});

const errorDeleteCompensation = (response: any) => ({
  payload: response,
  type: ERROR_DELETE_COMPENSATION,
});

const deleteCompensation = ({
  compensationId,
  contractorId,
}: any) => {
  const {
    BASE_URL,
    CONTRACTORS_SERVICE,
  } = config;

  return axios.delete(
    `${BASE_URL}${CONTRACTORS_SERVICE}/${contractorId}/compensations/${
      compensationId}`
  );
};

const fetchDeleteCompensation = ({
  contractorId,
  compensationId,
}: any) => (dispatch: any) => {
  dispatch(requestDeleteCompensation());
  return deleteCompensation({
    compensationId,
    contractorId,
  }).then(() => (
    getCompensations(contractorId)
      // eslint-disable-next-line max-len
      .then(compensations => dispatch(successDeleteCompensation(compensations)))
  )).catch((error) => dispatch(errorDeleteCompensation(error)));
};

const exportFunctions = {
  fetchCompensations,
  fetchCreateCompensation,
  fetchDeleteCompensation,
  fetchUpdateCompensation,
};

export default exportFunctions;
