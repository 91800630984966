import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import yaml from 'yaml';

import * as contentTypes from '../constants/contentTypes';
import * as pages from 'constants/pages';
import actionsSettings from '../actions/settings';
import actionsSetup from '../actions/setup';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import Dialog from 'components/Dialog';
import IconButton from 'components/IconButton';
import IconClose from 'components/icons/Close';
import IconEdit from 'components/icons/Edit';
import IconError from 'components/icons/Error';
import Loading from 'components/Loading';
import pagesURLs from 'constants/pagesURLs';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useTheme from 'hooks/useTheme';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
    overflow: 'hidden',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
  },
}));

const ICON_SIZE = 24;
const ICON_SIZE_COMPACT = 20;
const TEXT_AREA_ROWS_MIN = 6;

const errorTypes = {
  INVALID_YAML: 'INVALID_YAML',
};

const CommonSettings = () => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();

  const {
    details: settingsDetails,
    isFailed,
    isFailedDetails,
    isFailedUpdate,
    isFetching,
    isFetchingDetails,
    isFetchingUpdate,
    isFinishedDetails,
    isFinishedUpdate,
    list: settingsList,
  } = useSelector(({ settings: reducerSettings }) => reducerSettings);

  const [state, setState] = useState({
    editItemId: null,
    settingsText: '',
    showErrorAfterSaveAlert: false,
    validationErrors: [] as any,
  });

  const getValidationErrors = () => {
    const errors = [];
    try {
      yaml.parse(state.settingsText);
    } catch (error) {
      errors.push(errorTypes.INVALID_YAML);
    }

    return errors;
  };

  const onOpenEditDialog = (itemId: any) => {
    dispatch(actionsSettings.fetchSettingsDetails({ id: itemId }));
    setState({
      ...state,
      editItemId: itemId,
      validationErrors: [],
    });
  };

  const onTextAreaChange = (settingsText: string) => setState({
    ...state,
    settingsText,
  });

  const onUpdateSettings = () => {
    const validationErrors = getValidationErrors();
    if (!validationErrors.length) {
      dispatch(actionsSettings.fetchUpdateSettings({
        id: state.editItemId,
        value: state.settingsText,
      }));
    }
    setState({
      ...state,
      validationErrors,
    });
  };

  const onCloseEditDialog = () => setState({
    ...state,
    editItemId: null,
  });

  useEffect(() => {
    if (isFinishedDetails) {
      const settingsText = settingsDetails.value || '';
      setState({
        ...state,
        settingsText,
      });
    }
  }, [isFinishedDetails]);

  useEffect(() => {
    if (isFinishedUpdate) {
      setState({
        ...state,
        editItemId: isFailedUpdate ? state.editItemId : null,
        showErrorAfterSaveAlert: isFailedUpdate,
      });
    }
  }, [
    isFailedUpdate,
    isFinishedUpdate,
  ]);

  useEffect(() => {
    dispatch(actionsSettings.fetchSettingsList());
    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'settings' })}
          to={{ pathname: `${pagesURLs[pages.settings]}` }}
          variant="link"
        />
        <Breadcrumb
          label={formatMessage({ id: 'commonSettings' })}
          variant="text"
        />
      </Breadcrumbs>
      {(isFetching || isFailed) && (
        <Loading variant={isFailed ? 'error' : 'loading'}>
          {isFailed && (
            <Typography
              color='secondary'
              variant='subtitle'
            >
              {formatMessage({ id: 'loading.error' })}
            </Typography>
          )}
        </Loading>
      )}
      {(!isFetching && !isFailed) && (
        <div className={classes.itemsContainer}>
          {settingsList.map((item: any) => (
            <Card>
              <CardTitle>
                <Typography>
                  {item.name}
                </Typography>
                <IconButton onClick={() => onOpenEditDialog(item.id)}>
                  <IconEdit size={ICON_SIZE} />
                </IconButton>
              </CardTitle>
            </Card>
          ))}
        </div>
      )}

      {/* EDIT DIALOG */}
      <Dialog
        open={!!state.editItemId}
        onClose={onCloseEditDialog}
      >
        {state.showErrorAfterSaveAlert && (
          <Card variant='error'>
            <CardTitle>
              <Typography color="error">
                {formatMessage({ id: 'loading.error' })}
              </Typography>
            </CardTitle>
          </Card>
        )}
        {isFetchingDetails && (
          <Loading variant="loading" />
        )}
        {isFinishedDetails && (
          <Card variant="edit">
            <CardTitle>
              <Typography variant="title">
                {settingsList.find((item: any) =>
                  item.id === state.editItemId)?.name}
              </Typography>
              <IconButton
                disableHoverSpace
                onClick={onCloseEditDialog}
              >
                <IconClose size={ICON_SIZE} />
              </IconButton>
            </CardTitle>
            <div />
            <CardContent>
              {isFailedDetails && (
                <Typography>
                  {formatMessage({ id: 'loading.error' })}
                </Typography>
              )}
              {!isFailedDetails && (
                <div className={classes.dialogContent}>
                  {settingsDetails.type === contentTypes.TEXTAREA && (
                    <TextField
                      AdornmentEnd={
                        state.validationErrors.includes(errorTypes.INVALID_YAML)
                        && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={formatMessage({
                              id: `validationError.${errorTypes.INVALID_YAML}`,
                            })}
                          >
                            <IconError
                              color={theme.colors.redDark}
                              size={ICON_SIZE_COMPACT}
                            />
                          </Tooltip>
                        )
                      }
                      adornmentPosition="top"
                      autoFocus
                      isError={state.validationErrors
                        .includes(errorTypes.INVALID_YAML)}
                      minRows={TEXT_AREA_ROWS_MIN}
                      multiline
                      onChange={({ target }) => onTextAreaChange(target.value)}
                      placeholder={formatMessage({ id: 'yaml' })}
                      value={state.settingsText}
                    />
                  )}
                  {settingsDetails.type === contentTypes.STRING && (
                    <TextField
                      autoFocus
                      onChange={({ target }) => onTextAreaChange(target.value)}
                      value={state.settingsText}
                    />
                  )}
                </div>
              )}
            </CardContent>
            <div/>
            <CardActions>
              <Button
                onClick={onCloseEditDialog}
                variant='secondary'
              >
                <Typography color='inherit'>
                  {formatMessage({ id: 'cancel' })}
                </Typography>
              </Button>
              <Button
                disabled={isFailedDetails}
                isLoading={isFetchingUpdate}
                onClick={onUpdateSettings}
                variant='primary'
              >
                <Typography color='inherit'>
                  {formatMessage({ id: 'save' })}
                </Typography>
              </Button>
            </CardActions>
          </Card>
        )}
      </Dialog>
    </div>
  );
};

export default CommonSettings;
