import React from 'react';
import SelectMui from '@mui/material/Select';

const Select = ({
  disabled = false,
  disableUnderline = false,
  children,
  fullHeight = true,
  fullWidth = false,
  multiple = false,
  onChange,
  renderValue,
  size = 'medium',
  value,
  variant = 'standard',
}: IProps) => {
  return (
    <SelectMui
      disabled={disabled}
      disableUnderline={disableUnderline}
      fullWidth={fullWidth}
      MenuProps={{
        PaperProps: {
          sx: { maxHeight: fullHeight ? '100%' : '300px' },
        },
      }}
      multiple={multiple}
      onChange={onChange}
      renderValue={renderValue}
      sx={{
        '.MuiSelect-select': {
          alignItems: 'center',
          display: 'flex',
        },
      }}
      size={size}
      value={value}
      variant={variant}
    >
      {children}
    </SelectMui>
  );
};

interface IProps {
  disabled?: boolean,
  disableUnderline?: boolean,
  children: React.ReactNode,
  fullWidth?: boolean,
  fullHeight?: boolean,
  multiple?: boolean,
  onChange: (event: any) => void,
  renderValue?: (value: any) => React.ReactNode,
  size?: 'medium' | 'small',
  value: any,
  variant?: 'standard' | 'outlined',
}

export default Select;
