import {
  ERROR_CREATE_USER,
  ERROR_DELETE_USER,
  ERROR_RECEIVE_USERS,
  ERROR_UPDATE_USER,
  RECEIVE_USERS,
  REQUEST_CREATE_USER,
  REQUEST_DELETE_USER,
  REQUEST_USERS,
  REQUEST_UPDATE_USER,
  SUCCESS_CREATE_USER,
  SUCCESS_DELETE_USER,
  SUCCESS_UPDATE_USER,
} from '../constants/actionTypes';

import axios from 'axios';
import config from 'config';

const requestUsers = () => ({
  type: REQUEST_USERS,
});

const receiveUsers = (response: any) => ({
  payload: response,
  type: RECEIVE_USERS,
});

const errorReceiveUsers = () => ({
  type: ERROR_RECEIVE_USERS,
});

const getUsers = () => {
  const {
    BASE_URL,
    USERS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${USERS_SERVICE}`)
    .then(({ data }) => data);
};

const fetchUsers = () => (dispatch: any) => {
  dispatch(requestUsers());
  return getUsers()
    .then(users => dispatch(receiveUsers(users)))
    .catch(() => dispatch(errorReceiveUsers()));
};

const requestCreateUser = () => ({
  type: REQUEST_CREATE_USER,
});

const successCreateUser = (response: any) => ({
  payload: response,
  type: SUCCESS_CREATE_USER,
});

const errorCreateUser = (response: any) => ({
  payload: response,
  type: ERROR_CREATE_USER,
});

const createUser = ({
  authorities,
  email,
  name,
}: any) => {
  const {
    BASE_URL,
    USERS_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${USERS_SERVICE}`, {
      authorities,
      email,
      name,
    });
};

const fetchCreateUser = ({
  authorities,
  email,
  name,
}: any) => (dispatch: any) => {
  dispatch(requestCreateUser());
  return createUser({
    authorities,
    email,
    name,
  }).then(() => (
    getUsers()
      .then((users) => dispatch(successCreateUser(users)))
      .catch(() => dispatch(errorReceiveUsers()))
  )).catch((error) => dispatch(errorCreateUser(error)));
};

const requestUpdateUser = () => ({
  type: REQUEST_UPDATE_USER,
});

const successUpdateUser = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_USER,
});

const errorUpdateUser = (response: any) => ({
  payload: response,
  type: ERROR_UPDATE_USER,
});

const updateUser = ({
  authorities,
  email,
  name,
  userId,
}: any) => {
  const {
    BASE_URL,
    USERS_SERVICE,
  } = config;

  return axios
    .put(`${BASE_URL}${USERS_SERVICE}/${userId}`, {
      authorities,
      email,
      name,
    });
};

const fetchUpdateUser = ({
  authorities,
  email,
  name,
  userId,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateUser());
  return updateUser({
    authorities,
    email,
    name,
    userId,
  }).then(() => (
    getUsers()
      .then((users) => dispatch(successUpdateUser(users)))
      .catch(() => dispatch(errorReceiveUsers()))
  )).catch((error) => dispatch(errorUpdateUser(error)));
};

const requestDeleteUser = () => ({
  type: REQUEST_DELETE_USER,
});

const successDeleteUser = (response: any) => ({
  payload: response,
  type: SUCCESS_DELETE_USER,
});

const errorDeleteUser = (response: any) => ({
  payload: response,
  type: ERROR_DELETE_USER,
});

const deleteUser = ({ userId }: any) => {
  const {
    BASE_URL,
    USERS_SERVICE,
  } = config;

  return axios
    .delete(`${BASE_URL}${USERS_SERVICE}/${userId}`);
};

const fetchDeleteUser = ({
  userId,
}: any) => (dispatch: any) => {
  dispatch(requestDeleteUser());
  return deleteUser({
    userId,
  }).then(() => (
    getUsers()
      .then((users) => dispatch(successDeleteUser(users)))
      .catch(() => dispatch(errorReceiveUsers()))
  )).catch((error) => dispatch(errorDeleteUser(error)));
};

const exportFunctions = {
  fetchCreateUser,
  fetchDeleteUser,
  fetchUpdateUser,
  fetchUsers,
};

export default exportFunctions;
