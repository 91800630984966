import React from 'react';
import ButtonMUI from '@mui/material/Button';
import CircularProgress from '../CircularProgress';

type TVariants = 'primary' | 'secondary' | 'text';

type TMUIVariants = 'contained' | 'outlined' | 'text';

type TVariantsToMUIVariants = {
  [K in TVariants]: TMUIVariants;
};

const variantsToMUIVariants: TVariantsToMUIVariants = {
  primary: 'contained',
  secondary: 'outlined',
  text: 'text',
};

function Button({
  children,
  disabled,
  endIcon,
  isLoading = false,
  onClick,
  startIcon,
  variant = 'secondary',
}: IProps) {
  return (
    <>
      {isLoading && (
        <ButtonMUI
          disabled
          variant="contained"
          sx={{
            height: '40px',
          }}
        >
          <CircularProgress size={24} />
        </ButtonMUI>
      )}
      {!isLoading && (
        <ButtonMUI
          disabled={disabled}
          endIcon={endIcon}
          onClick={onClick}
          startIcon={startIcon}
          sx={{
            height: '40px',
          }}
          variant={variantsToMUIVariants[variant]}
        >
          {children}
        </ButtonMUI>
      )}
    </>
  );
}

interface IProps {
  children: React.ReactNode,
  disabled?: boolean,
  endIcon?: React.ReactNode,
  isLoading?: boolean,
  onClick: (input: any) => void,
  startIcon?: React.ReactNode,
  variant?: TVariants,
}

export default Button;
