import {
  ERROR_RECEIVE_PROJECT,
  ERROR_SAVE_PROJECT,
  ERROR_UPDATE_PROJECT_DETAILS,
  RECEIVE_PROJECT,
  REQUEST_PROJECT,
  REQUEST_SAVE_PROJECT,
  REQUEST_UPDATE_PROJECT_DETAILS,
  SUCCESS_SAVE_PROJECT,
  SUCCESS_UPDATE_PROJECT_DETAILS,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const errorReceiveProject = () => ({
  type: ERROR_RECEIVE_PROJECT,
});

const receiveProject = (project: any) => ({
  payload: project,
  type: RECEIVE_PROJECT,
});

const requestProject = () => ({
  type: REQUEST_PROJECT,
});

const getProject = ({
  projectId,
}: any) => {
  const {
    BASE_URL,
    PROJECT_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${PROJECT_SERVICE}/${projectId}`)
    .then(({ data }) => data);
};

const fetchProject = ({
  projectId,
}: any) => (dispatch: any) => {
  dispatch(requestProject());
  return getProject({
    projectId,
  }).then(project => dispatch(receiveProject(project)))
    .catch(() => dispatch(errorReceiveProject()));
};

const errorSaveProject = (message: any) => ({
  payload: message,
  type: ERROR_SAVE_PROJECT,
});

const requestSaveProject = () => ({
  type: REQUEST_SAVE_PROJECT,
});

const successSaveProject = (project: any) => ({
  payload: project,
  type: SUCCESS_SAVE_PROJECT,
});

const saveProject = ({
  members,
  projectId,
}: any) => {
  const {
    BASE_URL,
    PROJECT_SERVICE,
  } = config;
  return axios.post(
    `${BASE_URL}${PROJECT_SERVICE}/${projectId}/rates`,
    members
  ).then(({ data }) => data);
};

const fetchSaveProject = ({
  members,
  projectId,
}: any) => (dispatch: any) => {
  dispatch(requestSaveProject());
  return saveProject({
    members,
    projectId,
  }).then(() => {
    return getProject({
      projectId,
    }).then((project) => {
      dispatch(successSaveProject(project));
    }).catch(() => dispatch(errorReceiveProject()));
  }).catch((message) => dispatch(errorSaveProject(message)));
};

const errorUpdateDetails = () => ({
  type: ERROR_UPDATE_PROJECT_DETAILS,
});

const successUpdateDetails = (project: any) => ({
  payload: project,
  type: SUCCESS_UPDATE_PROJECT_DETAILS,
});

const requestUpdateDetails = () => ({
  type: REQUEST_UPDATE_PROJECT_DETAILS,
});

const updateDetails = ({
  accountNumber,
  address,
  addressPol,
  bankAddress,
  bankName,
  bankSwiftCode,
  cif,
  emailPersonal,
  fullName,
  invoiceNumberPrefix,
  isNeedGenerateInvoice,
  krs,
  nip,
  projectId,
  signatory,
  taxId,
}: any) => {
  const {
    BASE_URL,
    PROJECT_SERVICE,
  } = config;
  return axios.put(
    `${BASE_URL}${PROJECT_SERVICE}/${projectId}/details`,
    {
      address,
      addressPol,
      cif,
      emailPersonal,
      fullName,
      invoiceNumberPrefix,
      isNeedGenerateInvoice,
      signatory,
      supplierBankDetails: {
        accountNumber,
        bankAddress,
        bankName,
        bankSwiftCode,
        krs,
        nip,
      },
      taxId,
    }
  ).then(({ data }) => data);
};

const fetchUpdateProjectDetails = ({
  accountNumber,
  address,
  addressPol,
  bankAddress,
  bankName,
  bankSwiftCode,
  cif,
  emailPersonal,
  fullName,
  invoiceNumberPrefix,
  isNeedGenerateInvoice,
  krs,
  nip,
  projectId,
  signatory,
  taxId,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateDetails());
  return updateDetails({
    accountNumber,
    address,
    addressPol,
    bankAddress,
    bankName,
    bankSwiftCode,
    cif,
    emailPersonal,
    fullName,
    invoiceNumberPrefix,
    isNeedGenerateInvoice,
    krs,
    nip,
    projectId,
    signatory,
    taxId,
  }).then(() => getProject({
    projectId,
  }).then(project => dispatch(successUpdateDetails(project)))
  ).catch(() => dispatch(errorUpdateDetails()));
};

const exportFunctions = {
  fetchProject,
  fetchSaveProject,
  fetchUpdateProjectDetails,
};

export default exportFunctions;
