export const PL = 'PL';
export const UA = 'UA';
export const NOT_APPLIED = 'NOT_APPLIED';

const LIST = [
  PL,
  UA,
  NOT_APPLIED,
];

export default LIST;
