import {
  ERROR_RECEIVE_AUTHORITIES,
  RECEIVE_AUTHORITIES,
  REQUEST_AUTHORITIES,
} from '../constants/actionTypes';

import axios from 'axios';
import config from 'config';

const requestAuthorities = () => ({
  type: REQUEST_AUTHORITIES,
});

const receiveAuthorities = (response: any) => ({
  payload: response,
  type: RECEIVE_AUTHORITIES,
});

const errorReceiveAuthorities = () => ({
  type: ERROR_RECEIVE_AUTHORITIES,
});

const getAuthorities = () => {
  const {
    AUTHORITIES_SERVICE,
    BASE_URL,
  } = config;

  return axios
    .get(`${BASE_URL}${AUTHORITIES_SERVICE}`)
    .then(({ data }) => data);
};

const fetchAuthorities = () => (dispatch: any) => {
  dispatch(requestAuthorities());
  return getAuthorities()
    .then(authorities => dispatch(receiveAuthorities(authorities)))
    .catch(() => dispatch(errorReceiveAuthorities()));
};

const exportFunctions = {
  fetchAuthorities,
};

export default exportFunctions;
