export const REQUEST_TYPES = 'REQUEST_TYPES';
export const RECEIVE_TYPES = 'RECEIVE_TYPES';
export const ERROR_RECEIVE_TYPES = 'ERROR_RECEIVE_TYPES';

export const REQUEST_CREATE_TYPE = 'REQUEST_CREATE_TYPE';
export const SUCCESS_CREATE_TYPE = 'SUCCESS_CREATE_TYPE';
export const ERROR_CREATE_TYPE = 'ERROR_CREATE_TYPE';

export const REQUEST_UPDATE_TYPE = 'REQUEST_UPDATE_TYPE';
export const SUCCESS_UPDATE_TYPE = 'SUCCESS_UPDATE_TYPE';
export const ERROR_UPDATE_TYPE = 'ERROR_UPDATE_TYPE';

export const REQUEST_DELETE_TYPE = 'REQUEST_DELETE_TYPE';
export const SUCCESS_DELETE_TYPE = 'SUCCESS_DELETE_TYPE';
export const ERROR_DELETE_TYPE = 'ERROR_DELETE_TYPE';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
