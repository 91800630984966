export const SMALL = 10;
export const MEDIUM = 25;
export const LARGE = 100;

const LIST = [
  SMALL,
  MEDIUM,
  LARGE,
];

export default LIST;
