import {
  ERROR_DELETE_INVOICE,
  ERROR_DOWNLOAD_CALCULATION,
  ERROR_DOWNLOAD_INVOICE,
  ERROR_RECEIVE_INVOICE_CHANGE_HISTORY,
  ERROR_RECEIVE_INVOICE_ITEM,
  ERROR_RECEIVE_INVOICES,
  ERROR_REFRESH_INVOICE,
  ERROR_REFRESH_INVOICES,
  ERROR_UPDATE_INVOICE_ITEM,
  ERROR_UPDATE_INVOICE_STATUS,
  RECEIVE_INVOICE_CHANGE_HISTORY,
  RECEIVE_INVOICE_ITEM,
  RECEIVE_INVOICES,
  REQUEST_DELETE_INVOICE,
  REQUEST_DOWNLOAD_CALCULATION,
  REQUEST_DOWNLOAD_INVOICE,
  REQUEST_INVOICE_CHANGE_HISTORY,
  REQUEST_INVOICE_ITEM,
  REQUEST_INVOICES,
  REQUEST_REFRESH_INVOICE,
  REQUEST_REFRESH_INVOICES,
  REQUEST_UPDATE_INVOICE_ITEM,
  REQUEST_UPDATE_INVOICE_STATUS,
  SUCCESS_DELETE_INVOICE,
  SUCCESS_DOWNLOAD_CALCULATION,
  SUCCESS_DOWNLOAD_INVOICE,
  SUCCESS_REFRESH_INVOICE,
  SUCCESS_REFRESH_INVOICES,
  SUCCESS_UPDATE_INVOICE_ITEM,
  SUCCESS_UPDATE_INVOICE_STATUS,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';
import fileSaver from 'file-saver';

const extractFileName = (header: string) => header
  // get suggested file name
  ?.match(/filename="([^"]+)"/)
  ?.[1];

const requestInvoices = () => ({
  type: REQUEST_INVOICES,
});

const receiveInvoices = (response: any) => ({
  payload: response,
  type: RECEIVE_INVOICES,
});

const errorReceiveInvoices = () => ({
  type: ERROR_RECEIVE_INVOICES,
});

const getInvoices = ({
  month,
  year,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${INVOICES_SERVICE}/${year}/${month}`)
    .then(({ data }) => data);
};

const fetchInvoices = ({
  month,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestInvoices());
  return getInvoices({
    month,
    year,
  }).then(invoices => dispatch(receiveInvoices(invoices)))
    .catch(() => dispatch(errorReceiveInvoices()));
};
const requestRefreshInvoices = () => ({
  type: REQUEST_REFRESH_INVOICES,
});

const successRefreshInvoices = () => ({
  type: SUCCESS_REFRESH_INVOICES,
});

const errorRefreshInvoices = () => ({
  type: ERROR_REFRESH_INVOICES,
});

const refreshInvoices = ({
  month,
  year,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${INVOICES_SERVICE}/generate/${year}/${month}`)
    .then(({ data }) => data);
};

const fetchRefreshInvoices = ({
  month,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestRefreshInvoices());
  return refreshInvoices({
    month,
    year,
  })
    .then(() => {
      dispatch(successRefreshInvoices());
      return dispatch(fetchInvoices({
        month,
        year,
      }));
    })
    .catch(() => dispatch(errorRefreshInvoices()));
};

const requestUpdateStatus = () => ({
  type: REQUEST_UPDATE_INVOICE_STATUS,
});

const successUpdateStatus = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_INVOICE_STATUS,
});

const errorUpdateStatus = () => ({
  type: ERROR_UPDATE_INVOICE_STATUS,
});

const updateStatus = ({
  invoiceId,
  status,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .put(`${BASE_URL}${INVOICES_SERVICE}/${invoiceId}/status/${status}`)
    .then(({ data }) => data);
};

const fetchUpdateInvoiceStatus = ({
  invoiceId,
  status,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateStatus());
  return updateStatus({
    invoiceId,
    status,
  }).then(invoice => dispatch(successUpdateStatus(invoice)))
    .catch(() => dispatch(errorUpdateStatus()));
};

const requestRefreshInvoice = () => ({
  type: REQUEST_REFRESH_INVOICE,
});

const successRefreshInvoice = (response: any) => ({
  payload: response,
  type: SUCCESS_REFRESH_INVOICE,
});

const errorRefreshInvoice = () => ({
  type: ERROR_REFRESH_INVOICE,
});

const refreshInvoice = ({
  invoiceId,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${INVOICES_SERVICE}/${invoiceId}/refresh`)
    .then(({ data }) => data);
};

const fetchRefreshInvoice = ({
  invoiceId,
}: any) => (dispatch: any) => {
  dispatch(requestRefreshInvoice());
  return refreshInvoice({
    invoiceId,
  }).then(invoice => dispatch(successRefreshInvoice(invoice)))
    .catch(() => dispatch(errorRefreshInvoice()));
};

const requestDownloadCalculation = () => ({
  type: REQUEST_DOWNLOAD_CALCULATION,
});

const successDownloadCalculation = () => ({
  type: SUCCESS_DOWNLOAD_CALCULATION,
});

const errorDownloadCalculation = () => ({
  type: ERROR_DOWNLOAD_CALCULATION,
});

const downloadCalculation = ({
  invoiceId,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${INVOICES_SERVICE}/calculation/${invoiceId}`, {
      responseType: 'blob',
    })
    .then(response => ({
      invoice: response.data,
      title: extractFileName(response.headers['content-disposition'])
        || 'invoice.docx',
    }));
};

const fetchDownloadCalculation = ({
  invoiceId,
}: any) => (dispatch: any) => {
  dispatch(requestDownloadCalculation());
  return downloadCalculation({
    invoiceId,
  }).then(({ invoice, title }) => {
    fileSaver.saveAs(invoice, title);
    return dispatch(successDownloadCalculation());
  }).catch(() => dispatch(errorDownloadCalculation()));
};

const requestDownloadInvoice = () => ({
  type: REQUEST_DOWNLOAD_INVOICE,
});

const successDownloadInvoice = () => ({
  type: SUCCESS_DOWNLOAD_INVOICE,
});

const errorDownloadInvoice = () => ({
  type: ERROR_DOWNLOAD_INVOICE,
});

const downloadInvoice = ({
  invoiceId,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${INVOICES_SERVICE}/document/${invoiceId}`, {
      responseType: 'blob',
    })
    .then(response => ({
      invoice: response.data,
      title: extractFileName(response.headers['content-disposition'])
        || 'invoice.docx',
    }));
};

const fetchDownloadInvoice = ({
  invoiceId,
}: any) => (dispatch: any) => {
  dispatch(requestDownloadInvoice());
  return downloadInvoice({
    invoiceId,
  }).then(({ invoice, title }) => {
    fileSaver.saveAs(invoice, title);
    return dispatch(successDownloadInvoice());
  }).catch(() => dispatch(errorDownloadInvoice()));
};

const requestChangeHistory = () => ({
  type: REQUEST_INVOICE_CHANGE_HISTORY,
});

const receiveChangeHistory = (response: any) => ({
  payload: response,
  type: RECEIVE_INVOICE_CHANGE_HISTORY,
});

const errorReceiveChangeHistory = () => ({
  type: ERROR_RECEIVE_INVOICE_CHANGE_HISTORY,
});

const getChangeHistory = ({
  invoiceId,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${INVOICES_SERVICE}/${invoiceId}/history`)
    .then(({ data }) => data);
};

const fetchChangeHistory = ({
  invoiceId,
}: any) => (dispatch: any) => {
  dispatch(requestChangeHistory());
  return getChangeHistory({ invoiceId })
    .then(changes => dispatch(receiveChangeHistory(changes)))
    .catch(() => dispatch(errorReceiveChangeHistory()));
};

const requestDeleteInvoice = () => ({
  type: REQUEST_DELETE_INVOICE,
});

const successDeleteInvoice = (response: any) => ({
  payload: response,
  type: SUCCESS_DELETE_INVOICE,
});

const errorDeleteInvoice = () => ({
  type: ERROR_DELETE_INVOICE,
});

const deleteInvoice = ({
  invoiceId,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .delete(`${BASE_URL}${INVOICES_SERVICE}/${invoiceId}`)
    .then(({ data }) => data);
};

const fetchDeleteInvoice = ({
  invoiceId,
  month,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestDeleteInvoice());
  return deleteInvoice({
    invoiceId,
  }).then(() => (
    getInvoices({
      month,
      year,
    }).then(invoices => dispatch(successDeleteInvoice(invoices)))
      .catch(() => dispatch(errorReceiveInvoices()))
  )).catch(() => dispatch(errorDeleteInvoice()));
};

const requestInvoiceItemDetails = () => ({
  type: REQUEST_INVOICE_ITEM,
});

const receiveInvoiceItemDetails = (response: any) => ({
  payload: response,
  type: RECEIVE_INVOICE_ITEM,
});

const errorReceiveInvoiceItemDetails = () => ({
  type: ERROR_RECEIVE_INVOICE_ITEM,
});

const getInvoiceItemDetails = ({
  invoiceId,
  itemId,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${INVOICES_SERVICE}/${invoiceId}/item/${itemId}`)
    .then(({ data }) => data);
};

const fetchInvoiceItemDetails = ({
  invoiceId,
  itemId,
}: any) => (dispatch: any) => {
  dispatch(requestInvoiceItemDetails());
  return getInvoiceItemDetails({
    invoiceId,
    itemId,
  }).then(itemDetails => dispatch(receiveInvoiceItemDetails(itemDetails)))
    .catch(() => dispatch(errorReceiveInvoiceItemDetails()));
};

const requestUpdInvoiceItem = () => ({
  type: REQUEST_UPDATE_INVOICE_ITEM,
});

const successUpdInvoiceItem = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_INVOICE_ITEM,
});

const errorUpdInvoiceItem = () => ({
  type: ERROR_UPDATE_INVOICE_ITEM,
});

const updateInvoiceItem = ({
  hours,
  invoiceId,
  itemId,
}: any) => {
  const {
    BASE_URL,
    INVOICES_SERVICE,
  } = config;

  return axios
    .put(`${BASE_URL}${INVOICES_SERVICE}/${invoiceId}/item/${itemId}`, {
      hours,
    }).then(({ data }) => data);
};

const fetchUpdateInvoiceItem = ({
  hours,
  invoiceId,
  itemId,
}: any) => (dispatch: any) => {
  dispatch(requestUpdInvoiceItem());
  return updateInvoiceItem({
    hours,
    invoiceId,
    itemId,
  }).then(invoice => dispatch(successUpdInvoiceItem(invoice)))
    .catch(() => dispatch(errorUpdInvoiceItem()));
};

const exportFunctions = {
  fetchChangeHistory,
  fetchDeleteInvoice,
  fetchDownloadCalculation,
  fetchDownloadInvoice,
  fetchInvoiceItemDetails,
  fetchInvoices,
  fetchRefreshInvoice,
  fetchRefreshInvoices,
  fetchUpdateInvoiceItem,
  fetchUpdateInvoiceStatus,
};

export default exportFunctions;
