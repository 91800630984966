import {
  ERROR_CREATE_ITEM,
  ERROR_DELETE_ITEM,
  ERROR_RECEIVE_ITEMS,
  ERROR_UPDATE_ITEM,
  RECEIVE_ITEMS,
  REQUEST_CREATE_ITEM,
  REQUEST_DELETE_ITEM,
  REQUEST_ITEMS,
  REQUEST_UPDATE_ITEM,
  SUCCESS_CREATE_ITEM,
  SUCCESS_DELETE_ITEM,
  SUCCESS_UPDATE_ITEM,
} from '../constants/actionTypes';

import axios from 'axios';
import config from 'config';

const requestItems = () => ({
  type: REQUEST_ITEMS,
});

const receiveItems = (response: any) => ({
  payload: response,
  type: RECEIVE_ITEMS,
});

const errorReceiveItems = () => ({
  type: ERROR_RECEIVE_ITEMS,
});

const getItems = () => {
  const {
    BASE_URL,
    COST_ITEMS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${COST_ITEMS_SERVICE}`)
    .then(({ data }) => data);
};

const fetchItems = () => (dispatch: any) => {
  dispatch(requestItems());
  return getItems()
    .then(items => dispatch(receiveItems(items)))
    .catch(() => dispatch(errorReceiveItems()));
};

const requestCreateItem = () => ({
  type: REQUEST_CREATE_ITEM,
});

const successCreateItem = (response: any) => ({
  payload: response,
  type: SUCCESS_CREATE_ITEM,
});

const errorCreateItem = (response: any) => ({
  payload: response,
  type: ERROR_CREATE_ITEM,
});

const createItem = ({
  active,
  name,
}: any) => {
  const {
    BASE_URL,
    COST_ITEMS_SERVICE,
  } = config;
  return axios
    .post(`${BASE_URL}${COST_ITEMS_SERVICE}`, {
      active,
      name,
    });
};

const fetchCreateItem = ({
  active,
  name,
}: any) => (dispatch: any) => {
  dispatch(requestCreateItem());
  return createItem({
    active,
    name,
  }).then(() => (
    getItems()
      .then((items) => dispatch(successCreateItem(items)))
      .catch(() => dispatch(errorReceiveItems()))
  )).catch((error) => dispatch(errorCreateItem(error)));

};

const requestUpdateItem = () => ({
  type: REQUEST_UPDATE_ITEM,
});

const successUpdateItem = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_ITEM,
});

const errorUpdateItem = (response: any) => ({
  payload: response,
  type: ERROR_UPDATE_ITEM,
});

const updateItem = ({
  active,
  name,
  itemId,
}: any) => {
  const {
    BASE_URL,
    COST_ITEMS_SERVICE,
  } = config;

  return axios
    .put(`${BASE_URL}${COST_ITEMS_SERVICE}/${itemId}`, {
      active,
      name,
    });
};

const fetchUpdateItem = ({
  active,
  name,
  itemId,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateItem());
  return updateItem({
    active,
    itemId,
    name,
  }).then(() => (
    getItems()
      .then((items) => dispatch(successUpdateItem(items)))
      .catch(() => dispatch(errorReceiveItems()))
  )).catch((error) => dispatch(errorUpdateItem(error)));
};

const requestDeleteItem = () => ({
  type: REQUEST_DELETE_ITEM,
});

const successDeleteItem = (response: any) => ({
  payload: response,
  type: SUCCESS_DELETE_ITEM,
});

const errorDeleteItem = (response: any) => ({
  payload: response,
  type: ERROR_DELETE_ITEM,
});

const deleteItem = ({
  itemId,
}: any) => {
  const {
    BASE_URL,
    COST_ITEMS_SERVICE,
  } = config;

  return axios
    .delete(`${BASE_URL}${COST_ITEMS_SERVICE}/${itemId}`);
};

const fetchDeleteItem = ({
  itemId,
}: any) => (dispatch: any) => {
  dispatch(requestDeleteItem());
  return deleteItem({
    itemId,
  }).then(() => (
    getItems()
      .then((items) => dispatch(successDeleteItem(items)))
      .catch(() => dispatch(errorReceiveItems()))
  )).catch((error) => dispatch(errorDeleteItem(error)));
};

const exportFunctions = {
  fetchCreateItem,
  fetchDeleteItem,
  fetchItems,
  fetchUpdateItem,
};

export default exportFunctions;
