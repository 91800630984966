import {
  ERROR_RECEIVE_PROJECT,
  ERROR_SAVE_PROJECT,
  ERROR_UPDATE_PROJECT_DETAILS,
  RECEIVE_PROJECT,
  REQUEST_PROJECT,
  REQUEST_SAVE_PROJECT,
  REQUEST_UPDATE_PROJECT_DETAILS,
  RESET_REDUCER,
  SUCCESS_SAVE_PROJECT,
  SUCCESS_UPDATE_PROJECT_DETAILS,
} from '../constants/actionTypes';
import moment from 'moment/moment';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  errorMessage: '',
  isFailed: false,
  isFailedSave: false,
  isFailedUpdate: false,
  isFetching: false,
  isFetchingSave: false,
  isFetchingUpdate: false,
  isSuccessSave: false,
  isSuccessUpdate: false,
  project: null,
};

const getMonth = (value: any) => !!value
  ? moment(value).month() + 1
  : value;

const getYear = (value: any) => !!value
  ? moment(value).year()
  : value;

const convertProject = (projectBE: any) => ({
  address: projectBE.address,
  addressPol: projectBE.addressPol,
  bankAccountNumber: projectBE.supplierBankDetails?.accountNumber,
  bankAddress: projectBE.supplierBankDetails?.bankAddress,
  bankName: projectBE.supplierBankDetails?.bankName,
  bankSwift: projectBE.supplierBankDetails?.bankSwiftCode,
  cif: projectBE.cif,
  emailPersonal: projectBE.emailPersonal,
  invoiceNumberPrefix: projectBE.invoiceNumberPrefix,
  isNeedGenerateInvoice: projectBE.isNeedGenerateInvoice,
  krs: projectBE.supplierBankDetails?.krs,
  members: (projectBE.members || []).map((member: any) => ({
    contractorId: member.contractorId,
    currency: member.currency,
    id: member.id,
    name: member.contractorName,
    range: {
      fromMonth: getMonth(member.dateRange?.from),
      fromYear: getYear(member.dateRange?.from),
      toMonth: getMonth(member.dateRange?.to),
      toYear: getYear(member.dateRange?.to),
    },
    rateType: member.rateType,
    sum: member.sum,
    title: member.title,
    workload: member.workload,
  })),
  name: projectBE.fullName,
  nip: projectBE.supplierBankDetails?.nip,
  projectName: projectBE.name,
  signatory: projectBE.signatory,
  taxId: projectBE.taxId,
});


export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case ERROR_RECEIVE_PROJECT: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }
    case ERROR_SAVE_PROJECT: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;
      return {
        ...state,
        errorMessage,
        isFailedSave: true,
        isFetchingSave: false,
      };
    }
    case REQUEST_PROJECT: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }
    case REQUEST_SAVE_PROJECT: {
      return {
        ...state,
        errorMessage: '',
        isFailedSave: false,
        isFetchingSave: true,
        isSuccessSave: false,
      };
    }
    case RECEIVE_PROJECT: {
      const project = convertProject(action.payload);
      return {
        ...state,
        isFailed: false,
        isFetching: false,
        project,
      };
    }
    case SUCCESS_SAVE_PROJECT: {
      const project = convertProject(action.payload);
      return {
        ...state,
        errorMessage: '',
        isFetchingSave: false,
        isSuccessSave: true,
        project,
      };
    }
    case REQUEST_UPDATE_PROJECT_DETAILS: {
      return {
        ...state,
        isFailedUpdate: false,
        isFetchingUpdate: true,
        isSuccessUpdate: false,
      };
    }
    case SUCCESS_UPDATE_PROJECT_DETAILS: {
      const project = convertProject(action.payload);
      return {
        ...state,
        isFailedUpdate: false,
        isFetchingUpdate: false,
        isSuccessUpdate: true,
        project,
      };
    }
    case ERROR_UPDATE_PROJECT_DETAILS: {
      return {
        ...state,
        isFailedUpdate: true,
        isFetchingUpdate: false,
        isSuccessUpdate: false,
      };
    }
    case RESET_REDUCER: {
      return initialState;
    }

    default: return state;
  }
};
