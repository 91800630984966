import React from 'react';
import SvgIcon from '../SvgIcon';
import useTheme from 'hooks/useTheme';

const Widget = ({ color = 'page', size = 32 }: IProps) => {
  const { theme } = useTheme();
  const actualColor = theme.icon.color[color] || color;
  return (
    <SvgIcon
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
      viewBox="0 0 24 24"
    >
      <path
        // eslint-disable-next-line max-len
        d="M 13 13 v 8 h 8 v -8 Z M 3 21 h 8 v -8 H 3 Z M 3 3 v 8 h 8 V 3 Z m 13.66 -1.31 L 11 7.34 L 16.66 13 l 5.66 -5.66 Z"
        fill={actualColor}
      />
    </SvgIcon>
  );
};

interface IProps {
  color?: 'header' | 'page' | string;
  size?: number;
}

export default Widget;
