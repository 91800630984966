import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Breadcrumbs from 'components/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Link from 'components/Link';
import Loading from 'components/Loading';
import Typography from 'components/Typography';
import useChangePage from 'hooks/useChangePage';
import useLocationSearch from 'hooks/useLocationSearch';
import useTheme from 'hooks/useTheme';

import config from 'config';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  login: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '200px',
    justifyContent: 'center',
  },
}));

function Login({
  onSignIn,
}: IProps) {
  const { formatMessage } = useIntl();
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const changePage = useChangePage();
  const locationSearch = useLocationSearch();
  const user = useSelector(({ user }: any) => user);
  const [state, setState] = useState({
    googleSignedInCode: '',
  });

  const googleAuthLocationSearch = {
    access_type: config.OAUTH_ACCESS_TYPE,
    client_id: config.OAUTH_CLIENT_ID,
    prompt: config.OAUTH_PROMPT,
    redirect_uri: config.OAUTH_REDIRECT_URI,
    response_type: config.OAUTH_RESPONSE_TYPE,
    scope: config.OAUTH_SCOPE,
    state: `${new URLSearchParams(locationSearch).toString()}`,
  };

  useEffect(() => {
    if (locationSearch.code && !state.googleSignedInCode) {
      setState({
        ...state,
        googleSignedInCode: locationSearch.code,
      });
      changePage({
        locationSearch: Object
          .fromEntries(new URLSearchParams(locationSearch.state)),
        replace: true,
      });
    }
  }, [locationSearch.code]);

  useEffect(() => {
    if (state.googleSignedInCode) {
      onSignIn({
        code: state.googleSignedInCode,
      });
    }
  }, [state.googleSignedInCode]);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'signIn' })}
          variant="text"
        />
      </Breadcrumbs>
      <Card disablePaddings>
        {user.isFailedSignIn && user.errorType && (
          <Card variant="error">
            <CardContent>
              <Typography color="error">
                {formatMessage({
                  id: `error.${user.errorType}`,
                })}
              </Typography>
            </CardContent>
          </Card>
        )}
        {locationSearch.code && (
          <Loading />
        )}
        {!locationSearch.code && (
          <div className={classes.login}>
            <Link
              href={`${config.OAUTH_AUTH_URL}?${
                new URLSearchParams(googleAuthLocationSearch as any).toString()
              }`}
            >
              <Button
                onClick={() => {}}
                variant="primary"
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'signIn' })}
                </Typography>
              </Button>
            </Link>
          </div>
        )}
      </Card>
    </div>
  );
}

interface IProps {
  onSignIn: (args: any) => void,
}

export default Login;
