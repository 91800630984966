import {
  RESET_REDUCERS_DATA,
} from '../constants/actionTypes';

const resetReducer = () => (dispatch: any) => dispatch({
  type: RESET_REDUCERS_DATA,
});

const forExport = {
  resetReducer,
};

export default forExport;
