import React, { useEffect, useMemo, useState } from 'react';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import CircularProgress from 'components/CircularProgress';
import HighlightText from 'components/HighlightText';
import IconClose from 'components/icons/Close';
import IconPlus from 'components/icons/Plus';
import IconRefresh from 'components/icons/Refresh';
import IconSearch from 'components/icons/Search';
import IconButton from 'components/IconButton';
import Link from 'components/Link';
import Loading from 'components/Loading';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import Table from 'components/Table';
import TableBody from 'components/TableBody';
import TableCell from 'components/TableCell';
import TableCellHead from 'components/TableCellHead';
import TableHead from 'components/TableHead';
import TablePagination from 'components/TablePagination';
import TableRow from 'components/TableRow';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useChangePage from 'hooks/useChangePage';
import useDebounce from 'hooks/useDebounce';
import useIsMobile from 'hooks/useIsMobile';
import useLocationSearch from 'hooks/useLocationSearch';
import useTheme from 'hooks/useTheme';

import actionsContractors from '../actions/contractors';
import actionsSetup from '../actions/setup';
import paginationSizesList, * as paginationSizes
  from '../constants/paginationSizes';
import statuses from '../constants/statusTypes';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionsInnerContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
}));

const TABLE_COLUMNS = {
  documents: 'DOCUMENTS',
  email: 'EMAIL',
  name: 'NAME',
  phone: 'PHONE',
};

const formatLink = (href: string) => (href.startsWith('http'))
  ? href
  : `https://${href}`;

function Contractors() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();
  const changePage = useChangePage();
  const isMobile = useIsMobile();
  const locationSearch = useLocationSearch();
  const pageIndex = +locationSearch.pageIndex || 0;
  const pageSize = +locationSearch.pageSize || paginationSizes.SMALL;

  const {
    isFailed: isFailedContractors,
    isFailedRefresh,
    isFetching: isFetchingContractors,
    isFetchingRefresh,
    isSuccessRefresh,
    list: contractors,
    totalCount: contractorsTotalCount,
  } = useSelector(({
    contractors: reducerContractors,
  }: any) => reducerContractors);

  const [state, setState] = useState({
    compactColumnIndex: 1,
    searchText: '',
    showErrorAfterSaveAlert: false,
    showSuccessAfterSaveAlert: false,
    status: statuses.ACTIVE,
  });

  const debouncedSearchText = useDebounce({ value: state.searchText });
  const isFetching = isFetchingContractors || isFetchingRefresh;

  const tableRows = useMemo(() => (contractors
    .map((contractor: any) => ({
      contractorId: contractor.id,
      [TABLE_COLUMNS.name]: { value: contractor.name },
      [TABLE_COLUMNS.email]: { value: contractor.email },
      [TABLE_COLUMNS.phone]: { value: contractor.phone },
      [TABLE_COLUMNS.documents]: {
        value: contractor.documentsLink
          ? formatLink(contractor.documentsLink)
          : '',
      },
    }))
  ), [contractors]);

  useEffect(() => setState({
    ...state,
    showErrorAfterSaveAlert: isFailedRefresh,
    showSuccessAfterSaveAlert: isSuccessRefresh,
  }), [isFetchingRefresh]);

  useEffect(() => {
    dispatch(actionsContractors.fetchContractors({
      pageIndex,
      pageSize,
      searchText: debouncedSearchText,
      status: state.status,
    }));
  }, [
    pageIndex,
    pageSize,
    state.status,
    debouncedSearchText,
  ]);

  useEffect(() => {
    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({
            id: 'contractors',
          })}
          variant="text"
        />
      </Breadcrumbs>
      <div className={classes.actionsContainer}>
        <div className={classes.actionsInnerContainer}>
          <Select
            onChange={({ target }) => setState({
              ...state,
              status: target.value,
            })}
            size="small"
            value={state.status}
            variant="outlined"
          >
            {Object
              .keys(statuses)
              .map(status => (
                <MenuItem value={status}>
                  <Typography>
                    {formatMessage({
                      id: `status.${status}`,
                    })}
                  </Typography>
                </MenuItem>
              ))}
          </Select>
          <TextField
            AdornmentStart={<IconSearch size={24} />}
            onChange={({ target }) => {
              if (pageIndex !== 0) {
                changePage({
                  locationSearch: {
                    ...locationSearch,
                    pageIndex: 0,
                  },
                  replace: true,
                });
              }
              setState({
                ...state,
                searchText: target.value,
              });
            }}
            placeholder={formatMessage({ id: 'search' })}
            size="small"
            value={state.searchText}
          />
        </div>
        <Tooltip
          title={formatMessage({ id: 'refresh' })}
        >
          <IconButton
            disabled={isFetching}
            onClick={() => {
              dispatch(actionsContractors.fetchRefreshContractors({
                pageIndex,
                pageSize,
                searchText: debouncedSearchText,
                status: state.status,
              }));
              if (pageIndex !== 0) {
                changePage({
                  locationSearch: {
                    ...locationSearch,
                    pageIndex: 0,
                  },
                  replace: true,
                });
              }
              setState({
                ...state,
                searchText: '',
              });
            }}
          >
            {isFetching
              ? <CircularProgress size={24} />
              : <IconRefresh size={24} />
            }
          </IconButton>
        </Tooltip>
      </div>
      <Card>
        <CardTitle>
          <Typography color="secondary">
            {formatMessage({ id: 'create.subtitle' })}
          </Typography>
          <Button
            onClick={() => changePage({
              pathname: 'details',
            })}
            variant='primary'
            startIcon={(
              <IconPlus
                color='button'
                size={20}
              />
            )}
          >
            <Typography color='inherit'>
              {formatMessage({ id: 'add' })}
            </Typography>
          </Button>
        </CardTitle>
      </Card>
      <div>
        {/* List */}
        {(isFetchingContractors || isFailedContractors)
          && !contractors.length
          && (
            <Loading variant={isFailedContractors ? 'error' : 'loading'}>
              {isFailedContractors && (
                <Typography color="secondary" variant="subtitle">
                  {formatMessage({ id: 'loading.error' })}
                </Typography>
              )}
            </Loading>
          )}
        {(!isFetchingContractors && !isFailedContractors)
          && !contractors.length
          && (
            <Loading variant="noData">
              <Typography color="secondary" variant="subtitle">
                {formatMessage({ id: 'loading.noData' })}
              </Typography>
            </Loading>
          )}
        {!!contractors.length && (
          <Card disablePaddings>
            {(isFetchingContractors || isFailedContractors )
              && (
                <Loading variant={isFailedContractors ? 'error' : 'loading'}>
                  {isFailedContractors && (
                    <Typography color="secondary" variant="subtitle">
                      {formatMessage({ id: 'loading.error' })}
                    </Typography>
                  )}
                </Loading>
              )}
            {!isFetchingContractors && !isFailedContractors && (
              <Table
                compactColumnIndex={state.compactColumnIndex}
                compact={isMobile}
                fixed
                onChangeCompactColumnIndex={(index: number) => setState({
                  ...state,
                  compactColumnIndex: index,
                })}
              >
                <TableHead>
                  <TableRow>
                    <TableCellHead
                      compactVariant="static"
                    >
                      <Typography
                        color='secondary'
                        noWrap
                        variant='caption'
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.name}`,
                        })}
                      </Typography>
                    </TableCellHead>
                    <TableCellHead
                      compactVariant="dynamic"
                    >
                      <Typography
                        color='secondary'
                        noWrap
                        variant='caption'
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.email}`,
                        })}
                      </Typography>
                    </TableCellHead>
                    <TableCellHead
                      compactVariant="dynamic"
                    >
                      <Typography
                        color='secondary'
                        noWrap
                        variant='caption'
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.phone}`,
                        })}
                      </Typography>
                    </TableCellHead>
                    <TableCellHead
                      compactVariant="dynamic"
                    >
                      <Typography
                        color='secondary'
                        noWrap
                        variant='caption'
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.documents}`,
                        })}
                      </Typography>
                    </TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((row: any) => (
                    <TableRow
                      hover
                      onClick={() => changePage({
                        pathname: `details/${row.contractorId}`,
                      })}
                    >
                      <TableCell>
                        <HighlightText search={debouncedSearchText}>
                          <Typography>
                            {row[TABLE_COLUMNS.name].value}
                          </Typography>
                        </HighlightText>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap>
                          {row[TABLE_COLUMNS.email].value}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row[TABLE_COLUMNS.phone].value}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Link
                          onClick={event => event.stopPropagation()}
                          underline
                          href={row[TABLE_COLUMNS.documents].value}
                          target="_blank"
                        >
                          <Typography noWrap>
                            {row[TABLE_COLUMNS.documents].value}
                          </Typography>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            <TablePagination
              count={contractorsTotalCount}
              page={pageIndex}
              onPageChange={(event, pageIndex) => changePage({
                locationSearch: {
                  ...locationSearch,
                  pageIndex,
                },
                replace: true,
              })}
              onRowsPerPageChange={({ target }) => changePage({
                locationSearch: {
                  ...locationSearch,
                  pageIndex: 0,
                  pageSize: target.value,
                },
                replace: true,
              })}
              rowsPerPage={pageSize}
              rowsPerPageOptions={paginationSizesList}
              labelRowsPerPage={(
                <Typography
                  color="secondary"
                  variant="caption"
                >
                  {formatMessage({
                    id: isMobile
                      ? 'contractorsPerPage.short'
                      : 'contractorsPerPage',
                  })}
                </Typography>
              )}
            />
          </Card>
        )}
      </div>

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={() => setState({
          ...state,
          showSuccessAfterSaveAlert: false,
        })}
        open={state.showSuccessAfterSaveAlert}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {formatMessage({ id: 'loading.successRefresh' })}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar open={state.showErrorAfterSaveAlert}>
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {formatMessage({ id: 'loading.errorRefresh' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={() => setState({
                ...state,
                showErrorAfterSaveAlert: false,
              })}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default Contractors;
