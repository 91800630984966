export const REQUEST_REPORTS = 'REQUEST_REPORTS';
export const RECEIVE_REPORTS = 'RECEIVE_REPORTS';
export const ERROR_RECEIVE_REPORTS = 'ERROR_RECEIVE_REPORTS';

export const REQUEST_DOWNLOAD_REPORT = 'REQUEST_DOWNLOAD_REPORT';
export const SUCCESS_DOWNLOAD_REPORT = 'SUCCESS_DOWNLOAD_REPORT';
export const ERROR_DOWNLOAD_REPORT = 'ERROR_DOWNLOAD_REPORT';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';

export const REQUEST_EXPORT_REPORT = 'REQUEST_EXPORT_REPORT';
export const SUCCESS_EXPORT_REPORT = 'SUCCESS_EXPORT_REPORT';
export const ERROR_EXPORT_REPORT = 'ERROR_EXPORT_REPORT';
