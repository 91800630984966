import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import InvoicesPage from 'pages/invoices';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';

function Invoices() {
  return (
    <PageContainer>
      <PageAccessValidator
        neededAuthorities={authoritiesUI.INVOICES}
      >
        <InvoicesPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default Invoices;
