export const HOURLY = 'HOURLY';
export const MONTHLY = 'MONTHLY';
export const FIXED = 'FIXED';

const list = [
  HOURLY,
  MONTHLY,
  FIXED,
];

export default list;
