import React from 'react';
import AccordionDetailsMui from '@mui/material/AccordionDetails';
import { makeStyles } from 'tss-react/mui';
import useTheme from 'hooks/useTheme';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  paddingNone: {
    padding: '0px',
  },
}));

const AccordionDetails = ({
  children,
  disablePadding = false,
}: IProps) => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  return (
    <AccordionDetailsMui
      className={disablePadding ? classes.paddingNone : ''}
    >
      {children}
    </AccordionDetailsMui>
  );
};

interface IProps {
  children: React.ReactNode,
  disablePadding?: boolean,
}

export default AccordionDetails;
