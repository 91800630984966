export const EUR = 'EUR';
export const UAH = 'UAH';
export const USD = 'USD';

const LIST = [
  EUR,
  UAH,
  USD,
];

export default LIST;
