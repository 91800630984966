export const CONFIRMED = 'CONFIRMED';
export const ERROR = 'ERROR';
export const NEW = 'NEW';
export const SENT = 'SENT';
export const ON_PAYMENT = 'ON_PAYMENT';

const list = [
  NEW,
  CONFIRMED,
  SENT,
  ON_PAYMENT,
];

export default list;
