import React from 'react';
import SvgIcon from '../SvgIcon';
import useTheme from 'hooks/useTheme';

/* eslint-disable max-len */
const Refresh = ({ color = 'page', size = 32 }: IProps) => {
  const { theme } = useTheme();
  const actualColor = theme.icon.color[color] || color;
  return (
    <SvgIcon
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
      viewBox="0 0 24 24"
    >
      <path
        fill={actualColor}
        d="M 12 6 v 3 l 4 -4 l -4 -4 v 3 c -4.42 0 -8 3.58 -8 8 c 0 1.57 0.46 3.03 1.24 4.26 L 6.7 14.8 c -0.45 -0.83 -0.7 -1.79 -0.7 -2.8 c 0 -3.31 2.69 -6 6 -6 Z m 6.76 1.74 L 17.3 9.2 c 0.44 0.84 0.7 1.79 0.7 2.8 c 0 3.31 -2.69 6 -6 6 v -3 l -4 4 l 4 4 v -3 c 4.42 0 8 -3.58 8 -8 c 0 -1.57 -0.46 -3.03 -1.24 -4.26 Z"
      />
    </SvgIcon>
  );
};

interface IProps {
  color?: 'header' | 'page' | string;
  size?: number;
}

export default Refresh;
