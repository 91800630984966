import { combineReducers } from 'redux';

import authorities from './authorities';
import users from './users';

const rootReducer = combineReducers({
  authorities,
  users,
});

export default rootReducer;
