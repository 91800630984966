import { Provider } from 'react-redux';
import {
  IntlProvider as ReactIntlProvider,
} from 'react-intl';
import { DEFAULT_LANGUAGE, locales } from 'constants/languages';
import React, { useMemo } from 'react';
import reduxUtils from 'utils/redux';
import useLocationSearch from 'hooks/useLocationSearch';

import CompensationTypes from './containers/CompensationTypes';
import getMessages from './intl/index';
import rootReducer from './reducers';

const store = reduxUtils.configureStore(rootReducer);

function Index(props: any) {
  const { lang } = useLocationSearch();
  const messages = useMemo(() => getMessages(lang), [lang]);
  return (
    <ReactIntlProvider
      defaultLocale={locales[DEFAULT_LANGUAGE]}
      locale={locales[lang]}
      messages={messages}
    >
      <Provider store={store}>
        <CompensationTypes {...props} />
      </Provider>
    </ReactIntlProvider>
  );
}

export default Index;
