import {
  ERROR_RECEIVE_SETTINGS_DETAILS,
  ERROR_RECEIVE_SETTINGS_LIST,
  ERROR_UPDATE_SETTINGS,
  RECEIVE_SETTINGS_DETAILS,
  RECEIVE_SETTINGS_LIST,
  REQUEST_SETTINGS_DETAILS,
  REQUEST_SETTINGS_LIST,
  REQUEST_UPDATE_SETTINGS,
  RESET_REDUCERS_DATA,
  SUCCESS_UPDATE_SETTINGS,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  details: null,
  isFailed: false,
  isFailedDetails: false,
  isFailedUpdate: false,
  isFetching: false,
  isFetchingDetails: false,
  isFetchingUpdate: false,
  isFinishedDetails: false,
  isFinishedUpdate: false,
  list: [],
};

const convertSettingsItem = (item: any) => ({
  id: item.id,
  name: item.name,
  type: item.type,
});

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_SETTINGS_LIST: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_SETTINGS_LIST: {
      const settings = action.payload;
      const list = settings.map((item: any) => convertSettingsItem(item));

      return {
        ...state,
        isFetching: false,
        list,
      };
    }

    case ERROR_RECEIVE_SETTINGS_LIST: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_SETTINGS_DETAILS: {
      return {
        ...state,
        isFailedDetails: false,
        isFetchingDetails: true,
        isFinishedDetails: false,
      };
    }

    case RECEIVE_SETTINGS_DETAILS: {
      const settingsItem = action.payload;
      const details = {
        ...convertSettingsItem(settingsItem),
        value: settingsItem.value,
      };

      return {
        ...state,
        details,
        isFetchingDetails: false,
        isFinishedDetails: true,
      };
    }

    case ERROR_RECEIVE_SETTINGS_DETAILS: {
      return {
        ...state,
        isFailedDetails: true,
        isFetchingDetails: false,
        isFinishedDetails: true,
      };
    }

    case REQUEST_UPDATE_SETTINGS: {
      return {
        ...state,
        isFailedUpdate: false,
        isFetchingUpdate: true,
        isFinishedUpdate: false,
      };
    }

    case SUCCESS_UPDATE_SETTINGS: {
      const settings = action.payload;
      const list = settings.map((item: any) => convertSettingsItem(item));

      return {
        ...state,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
        list,
      };
    }

    case ERROR_UPDATE_SETTINGS: {
      return {
        ...state,
        isFailedUpdate: true,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
