import {
  ERROR_RECEIVE_CLIENTS_AND_PROJECTS,
  RECEIVE_CLIENTS_AND_PROJECTS,
  REQUEST_CLIENTS_AND_PROJECTS,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const errorReceiveClientsAndProjects = () => ({
  type: ERROR_RECEIVE_CLIENTS_AND_PROJECTS,
});

const receiveClientsAndProjects = (clientsAndProjects: any) => ({
  payload: clientsAndProjects,
  type: RECEIVE_CLIENTS_AND_PROJECTS,
});

const requestClientsAndProjects = () => ({
  type: REQUEST_CLIENTS_AND_PROJECTS,
});

const getClientsAndProjects = () => {
  const {
    BASE_URL,
    PROJECT_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${PROJECT_SERVICE}`)
    .then(({ data }) => data);
};

const fetchClientsAndProjects = () => (dispatch: any) => {
  dispatch(requestClientsAndProjects());
  return getClientsAndProjects()
    .then(clients => dispatch(receiveClientsAndProjects(clients)))
    .catch(() => dispatch(errorReceiveClientsAndProjects()));
};

const exportFunctions = {
  fetchClientsAndProjects,
};

export default exportFunctions;
