import {
  CHANGE_AUTHORITIES,
  ERROR_RECEIVE_USER,
  ERROR_SIGN_IN,
  ERROR_SIGN_OUT,
  RECEIVE_USER,
  REQUEST_SIGN_IN,
  REQUEST_SIGN_OUT,
  REQUEST_USER,
  SUCCESS_SIGN_IN,
  SUCCESS_SIGN_OUT,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';
import storage from 'utils/storage';

const errorReceiveUser = () => ({
  type: ERROR_RECEIVE_USER,
});

const getUser = () => {
  const {
    AUTH_SERVICE,
    BASE_URL,
  } = config;

  return axios
    .get(`${BASE_URL}${AUTH_SERVICE}/profile`)
    .then(({ data }) => data);
};

const receiveUser = (user: any) => ({
  payload: user,
  type: RECEIVE_USER,
});

const requestUser = () => ({
  type: REQUEST_USER,
});

const fetchUser = () => (dispatch: any) => {
  const isAuthorized = storage.getItem('isAuthorized');
  if (isAuthorized) {
    dispatch(requestUser());
    return getUser()
      .then(user => dispatch(receiveUser(user)))
      .catch(() => dispatch(errorReceiveUser()));
  }
  return Promise.resolve();
};

const errorSignIn = (error: any) => ({
  payload: error,
  type: ERROR_SIGN_IN,
});

const requestSignIn = () => ({
  type: REQUEST_SIGN_IN,
});

const successSignIn = (payload: any) => ({
  payload,
  type: SUCCESS_SIGN_IN,
});

const signIn = ({
  code,
}: any) => {
  const {
    BASE_URL,
    AUTH_SERVICE,
  } = config;
  return axios
    .post(
      `${BASE_URL}${AUTH_SERVICE}/login/google?code=${code}`
    ).then(({ data }) => data);
};

const fetchSignIn = ({
  code,
}: any) => (dispatch: any) => {
  dispatch(requestSignIn());
  return signIn({
    code,
  }).then(user => {
    storage.setItem('isAuthorized', true);
    dispatch(successSignIn(user));
  }).catch((error) => dispatch(errorSignIn(error)));
};

const errorSignOut = () => ({
  type: ERROR_SIGN_OUT,
});

const requestSignOut = () => ({
  type: REQUEST_SIGN_OUT,
});

const successSignOut = () => ({
  type: SUCCESS_SIGN_OUT,
});

const signOut = () => {
  const {
    AUTH_SERVICE,
    BASE_URL,
  } = config;
  return axios.post(
    `${BASE_URL}${AUTH_SERVICE}/logout`
  );
};

const fetchSignOut = () => (dispatch: any) => {
  dispatch(requestSignOut());
  return signOut()
    .then(() => {
      storage.removeItem('isAuthorized');
      dispatch(successSignOut());
    })
    .catch(() => dispatch(errorSignOut()));
};

const changeAuthorities = (
  authorities: any
) => (dispatch: any) => dispatch({
  payload: authorities,
  type: CHANGE_AUTHORITIES,
});

const exportFunctions = {
  changeAuthorities,
  fetchSignIn,
  fetchSignOut,
  fetchUser,
};

export default exportFunctions;
