import React from 'react';
import Login from './containers/Login';

function Index(props: any) {
  return (
    <Login {...props} />
  );
}

export default Index;
