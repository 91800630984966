import { combineReducers } from 'redux';

import payrolls from './payrolls';
import reports from './reports';

const rootReducer = combineReducers({
  payrolls,
  reports,
});

export default rootReducer;
