import {
  CLEAR_AFTER_FETCH_STATUS,
  RESET_REDUCERS_DATA,
} from '../constants/actionTypes';

const resetReducersData = () => (dispatch: any) => dispatch({
  type: RESET_REDUCERS_DATA,
});

const clearAfterFetchStatus = () => (dispatch: any) => dispatch({
  type: CLEAR_AFTER_FETCH_STATUS,
});

const exportFunctions = {
  clearAfterFetchStatus,
  resetReducersData,
};

export default exportFunctions;
