import React from 'react';
import TabMui from '@mui/material/Tab';

const Tab = ({
  disabled = false,
  label,
  value,
  ...props
}: IProps) => {
  return (
    <TabMui
      disabled={disabled}
      label={label}
      value={value}
      {...props}
    />
  );
};

interface IProps {
  disabled?: boolean,
  label: React.ReactNode,
  value: string,
}

export default Tab;
