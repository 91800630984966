import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import authorities from 'constants/authoritiesUI';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import Hover from 'components/Hover';
import IconCase from 'components/icons/Case';
import IconExpense from 'components/icons/Expense';
import IconInvoice from 'components/icons/Invoice';
import IconPayments from 'components/icons/Payments';
import IconPeople from 'components/icons/People';
import IconReport from 'components/icons/Report';
import IconSettings from 'components/icons/Settings';
import Link from 'components/Link';
import Typography from 'components/Typography';
import useAccessValidate from 'hooks/useAccessValidate';
import useIsMobile from 'hooks/useIsMobile';
import useTheme from 'hooks/useTheme';

import * as pages from 'constants/pages';
import pagesURLs from 'constants/pagesURLs';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(3)}px`,
  },
  containerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(3)}px`,
  },
  itemContainer: {
    display: 'flex',
    height: '200px',
    width: '250px',
  },
  itemContainerMobile: {
    display: 'flex',
    height: '200px',
  },
  itemContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
    justifyContent: 'center',
    padding: `${theme.spacing(3)}px`,
    width: '100%',
  },
}));

const items = [
  {
    authorities: [authorities.CONTRACTORS],
    icon: (<IconPeople size={40} />),
    labelIntlId: 'contractors',
    link: `${pagesURLs[pages.contractors]}`,
  },
  {
    authorities: [authorities.PAYROLLS],
    icon: (<IconPayments size={40} />),
    labelIntlId: 'payrolls',
    link: `${pagesURLs[pages.payrolls]}`,
  },
  {
    authorities: [authorities.CLIENTS_AND_PROJECTS],
    icon: (<IconCase size={40} />),
    labelIntlId: 'clientsAndProjects',
    link: `${pagesURLs[pages.clientsAndProjects]}`,
  },
  {
    authorities: [authorities.INVOICES],
    icon: (<IconInvoice size={40} />),
    labelIntlId: 'invoices',
    link: `${pagesURLs[pages.invoices]}`,
  },
  {
    authorities: [authorities.EXPENSES],
    icon: (<IconExpense size={40} />),
    labelIntlId: 'expenses',
    link: `${pagesURLs[pages.expenses]}`,
  },
  {
    authorities: [authorities.REPORTS_FINANCES],
    icon: (<IconReport size={40} />),
    labelIntlId: 'reports',
    link: `${pagesURLs[pages.reports]}`,
  },
  {
    authorities: [
      authorities.SETTINGS_FINANCES,
      authorities.SETTINGS_USERS,
    ],
    icon: (<IconSettings size={40}/>),
    labelIntlId: 'settings',
    link: `${pagesURLs[pages.settings]}`,
  },
];

function Default() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const validateAccess = useAccessValidate();
  const isMobile = useIsMobile();
  const availableItems = items
    .filter(item => validateAccess(item.authorities));

  return (
    <div
      className={isMobile ? classes.containerMobile : classes.container}
    >
      {availableItems.map(item => (
        <Link to={{ pathname: item.link }}>
          <div
            className={isMobile
              ? classes.itemContainerMobile
              : classes.itemContainer}
          >
            <Card disablePaddings>
              <Hover>
                <div className={classes.itemContent}>
                  {item.icon}
                  <Typography
                    color="secondary"
                    variant="title"
                  >
                    <strong>
                      {formatMessage({
                        id: item.labelIntlId,
                      })}
                    </strong>
                  </Typography>
                </div>
              </Hover>
            </Card>
          </div>
        </Link>
      ))}
      {!availableItems.length && (
        <Card>
          <CardTitle>
            <Typography
              color="secondary"
              variant="title"
            >
              {formatMessage({
                id: 'noItems.0',
              })}
            </Typography>
          </CardTitle>
        </Card>
      )}
    </div>
  );
}

export default Default;
