import React from 'react';
import TableRowMui from '@mui/material/TableRow';
import { makeStyles } from 'tss-react/mui';
import useTheme from '../../hooks/useTheme';
import classNames from 'classnames';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  clickable: {
    cursor: 'pointer',
  },
  showTableCellActions: {
    '&:active, &:focus, &:hover': {
      '& .table-cell-actions': {
        display: 'flex',
        gap: `${theme.spacing(2)}px`,
        justifyContent: 'center',
        visibility: 'visible',
      },
    },
  },
}));

const TableRow = ({
  children,
  hover = false,
  onClick,
  selected = false,
}: IProps) => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);

  return (
    <TableRowMui
      className={classNames(
        classes.showTableCellActions,
        onClick ? classes.clickable : ''
      )}
      onClick={onClick}
      hover={hover}
      selected={selected}
    >
      {children}
    </TableRowMui>
  );
};

TableRow.pswName = 'TableRow';

interface IProps {
  children: React.ReactNode;
  hover?: boolean;
  onClick?: (event: any) => void;
  selected?: boolean;
}

export default TableRow;
