import React from 'react';
import SvgIcon from '../SvgIcon';
import useTheme from 'hooks/useTheme';

const People = ({ color = 'page', size = 32 }: IProps) => {
  const { theme } = useTheme();
  const actualColor = theme.icon.color[color] || color;
  return (
    <SvgIcon
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
      viewBox="0 0 24 24"
    >
      <path
        // eslint-disable-next-line max-len
        d="M 21 5 v 14 h 2 V 5 h -2 Z m -4 14 h 2 V 5 h -2 v 14 Z M 14 5 H 2 c -0.55 0 -1 0.45 -1 1 v 12 c 0 0.55 0.45 1 1 1 h 12 c 0.55 0 1 -0.45 1 -1 V 6 c 0 -0.55 -0.45 -1 -1 -1 Z M 8 7.75 c 1.24 0 2.25 1.01 2.25 2.25 S 9.24 12.25 8 12.25 S 5.75 11.24 5.75 10 S 6.76 7.75 8 7.75 Z M 12.5 17 h -9 v -0.75 c 0 -1.5 3 -2.25 4.5 -2.25 s 4.5 0.75 4.5 2.25 V 17 Z"
        fill={actualColor}
      />
    </SvgIcon>
  );
};

interface IProps {
  color?: 'header' | 'page' | string;
  size?: number;
}

export default People;
