import {
  ERROR_RECEIVE_SETTINGS_DETAILS,
  ERROR_RECEIVE_SETTINGS_LIST,
  ERROR_UPDATE_SETTINGS,
  RECEIVE_SETTINGS_DETAILS,
  RECEIVE_SETTINGS_LIST,
  REQUEST_SETTINGS_DETAILS,
  REQUEST_SETTINGS_LIST,
  REQUEST_UPDATE_SETTINGS,
  SUCCESS_UPDATE_SETTINGS,
} from '../constants/actionTypes';
import config from 'config';
import axios from 'axios';

const requestSettingsList = () => ({
  type: REQUEST_SETTINGS_LIST,
});

const receiveSettingsList = (settings: any) => ({
  payload: settings,
  type: RECEIVE_SETTINGS_LIST,
});

const errorReceiveSettingsList = () => ({
  type: ERROR_RECEIVE_SETTINGS_LIST,
});

const getSettingsList = () => {
  const {
    BASE_URL,
    COMMON_SETTINGS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${COMMON_SETTINGS_SERVICE}`)
    .then(({ data }) => data);
};

const fetchSettingsList = () => (dispatch: any) => {
  dispatch(requestSettingsList());

  return getSettingsList()
    .then(settings => dispatch(receiveSettingsList(settings)))
    .catch(() => dispatch(errorReceiveSettingsList()));
};

const requestSettingsDetails = () => ({
  type: REQUEST_SETTINGS_DETAILS,
});

const receiveSettingsDetails = (details: any) => ({
  payload: details,
  type: RECEIVE_SETTINGS_DETAILS,
});

const errorReceiveSettingsDetails = () => ({
  type: ERROR_RECEIVE_SETTINGS_DETAILS,
});

const getSettingsDetails = ({ id }: any) => {
  const {
    BASE_URL,
    COMMON_SETTINGS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${COMMON_SETTINGS_SERVICE}/${id}`)
    .then(({ data }) => data);
};

const fetchSettingsDetails = ({ id }: any) => (dispatch: any) => {
  dispatch(requestSettingsDetails());

  return getSettingsDetails({ id })
    .then((details) => dispatch(receiveSettingsDetails(details)))
    .catch(() => dispatch(errorReceiveSettingsDetails()));
};

const requestUpdateSettings = () => ({
  type: REQUEST_UPDATE_SETTINGS,
});

const successUpdateSettings = (settings: any) => ({
  payload: settings,
  type: SUCCESS_UPDATE_SETTINGS,
});

const errorUpdateSettings = () => ({
  type: ERROR_UPDATE_SETTINGS,
});

const updateSettings = ({
  id,
  value,
}: any) => {
  const {
    BASE_URL,
    COMMON_SETTINGS_SERVICE,
  } = config;

  return axios
    .put(
      `${BASE_URL}${COMMON_SETTINGS_SERVICE}/${id}`,
      { value }
    );
};

const fetchUpdateSettings = ({
  id,
  value,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateSettings());

  return updateSettings({
    id,
    value,
  })
    .then(() => getSettingsList()
      .then((settings) => dispatch(successUpdateSettings(settings)))
      .catch(() => dispatch(errorReceiveSettingsList())))
    .catch(() => dispatch(errorUpdateSettings()));
};

const exportFunctions = {
  fetchSettingsDetails,
  fetchSettingsList,
  fetchUpdateSettings,
};

export default exportFunctions;
