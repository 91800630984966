import React, { useEffect, useMemo, useState } from 'react';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import CircularProgress from 'components/CircularProgress';
import IconClose from 'components/icons/Close';
import IconDownload from 'components/icons/Download';
import IconExport from 'components/icons/Export';
import IconButton from 'components/IconButton';
import Link from 'components/Link';
import Loading from 'components/Loading';
import MenuItem from 'components/MenuItem';
import moment from 'moment';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useTheme from 'hooks/useTheme';
import 'moment/locale/uk';

import dateRangeList, * as dateRangeTypes from '../constants/dateRangeTypes';
import actionsReports from '../actions/reports';
import actionsSetup from '../actions/setup';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  actionButtons: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
  },
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  exportSuccess: {
    display: 'flex',
    gap: `${theme.spacing(0.5)}px`,
  },
  reportsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
  },
  select: {
    width: '150px',
  },
  selectQuarter: {
    width: '55px',
  },
  selectRange: {
    width: '110px',
  },
  selectYear: {
    width: '80px',
  },
}));

const SELECT_TYPE = {
  DATE_RANGE: 'dateRange',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
};

const MIN_YEAR = 2023;
const CURRENT_YEAR = moment().year();
const DEFAULT_MONTH = moment().month() + 1;
const DEFAULT_QUARTER = moment().quarter() === 1
  ? moment().endOf('year').quarter()
  : moment().quarter() - 1;
const DEFAULT_YEAR = moment().month() ? CURRENT_YEAR : CURRENT_YEAR - 1;

const months = Array
  .from(new Array(12).keys())
  .map(key => key + 1);

const quarters = Array
  .from(new Array(4).keys())
  .map(key=> key + 1);

const years = [
  CURRENT_YEAR,
  ...Array
    .from(new Array(CURRENT_YEAR - MIN_YEAR).keys())
    .map(index => MIN_YEAR + index)
    .reverse(),
];

function Reports() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();

  const {
    errorMessage,
    exportedReportFolderLink,
    isFailed: isFailedReports,
    isFailedDownload,
    isFailedExport,
    isFetching: isFetchingReports,
    isFetchingDownload,
    isFetchingExport,
    isSuccessExport,
    list: reportsList,
  } = useSelector(({ reports: reducerReports }) => reducerReports);

  const [state, setState] = useState({
    dateRange: dateRangeTypes.MONTHLY,
    month: DEFAULT_MONTH,
    quarter: DEFAULT_QUARTER,
    reportId: '',
    showErrorAfterSaveAlert: false,
    showSuccessAfterExportAlert: false,
    year: DEFAULT_YEAR,
  });

  const reports = useMemo(() => (
    reportsList.filter((report: any) => report.dateRange === state.dateRange)
  ), [reportsList, state.dateRange]);

  const onChangeSelector = (type: string, value: any) => {
    setState({
      ...state,
      showErrorAfterSaveAlert: false,
      showSuccessAfterExportAlert: false,
      [type]: value,
    });
  };

  useEffect(() => setState({
    ...state,
    showSuccessAfterExportAlert: isSuccessExport,
  }), [isSuccessExport]);

  useEffect(() => setState({
    ...state,
    showErrorAfterSaveAlert: isFailedDownload || isFailedExport,
  }), [isFetchingDownload, isFailedExport]);

  useEffect(() => {
    dispatch(actionsReports.fetchReports());
    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'reports' })}
          variant="text"
        />
      </Breadcrumbs>
      <div className={classes.actionsContainer}>
        <div className={classes.selectRange}>
          <Select
            disabled={isFetchingDownload}
            fullWidth
            onChange={({ target }) =>
              onChangeSelector(SELECT_TYPE.DATE_RANGE, target.value)}
            size="small"
            value={state.dateRange}
            variant="outlined"
          >
            {dateRangeList.map((range: any) => (
              <MenuItem value={range}>
                <Typography>
                  {formatMessage({ id: `range.${range}` })}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </div>
        {state.dateRange === dateRangeTypes.MONTHLY && (
          <div className={classes.select}>
            <Select
              disabled={isFetchingDownload}
              fullWidth
              onChange={({ target }) =>
                onChangeSelector(SELECT_TYPE.MONTH, target.value)}
              size="small"
              value={state.month}
              variant="outlined"
            >
              {months.map((month) => (
                <MenuItem value={month}>
                  <Typography>
                    {formatMessage({ id: `month.${month}` })}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        {state.dateRange === dateRangeTypes.QUARTERLY && (
          <div className={classes.selectQuarter}>
            <Select
              disabled={isFetchingDownload}
              fullWidth
              onChange={({ target }) =>
                onChangeSelector(SELECT_TYPE.QUARTER, target.value)}
              size="small"
              value={state.quarter}
              variant="outlined"
            >
              {quarters.map((quarter) => (
                <MenuItem value={quarter}>
                  <Typography>
                    {quarter}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        <div className={classes.selectYear}>
          <Select
            disabled={isFetchingDownload}
            fullWidth
            onChange={({ target }) =>
              onChangeSelector(SELECT_TYPE.YEAR, target.value)}
            size="small"
            value={state.year}
            variant="outlined"
          >
            {years.map(year => (
              <MenuItem value={year}>
                <Typography>
                  {year}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {(isFetchingReports || isFailedReports) && (
        <Loading variant={isFailedReports ? 'error' : 'loading'}>
          {isFailedReports && (
            <Typography color="secondary" variant="subtitle">
              {formatMessage({ id: 'loading.error' })}
            </Typography>
          )}
        </Loading>
      )}
      {(!isFetchingReports && !isFailedReports)
        && !reports.length
        && (
          <Loading variant="noData">
            <Typography color="secondary" variant="subtitle">
              {formatMessage({ id: 'loading.noData' })}
            </Typography>
          </Loading>
        )}
      {(!isFetchingReports && !isFailedReports) && (
        <div className={classes.reportsContainer}>
          {reports.map((report: any) => (
            <Card>
              <CardTitle>
                <Typography noWrap>
                  {report.description}
                </Typography>
                <div className={classes.actionButtons}>
                  <Tooltip
                    title={formatMessage({
                      id: 'exportReport',
                    })}
                  >
                    <IconButton
                      disabled={!report.exportable}
                      disableHoverSpace
                      onClick={() => {
                        const isMonthly = state
                          .dateRange === dateRangeTypes.MONTHLY;
                        dispatch(actionsReports.fetchExportReport({
                          id: report.id,
                          month: isMonthly ? state.month : null,
                          quarter: isMonthly ? null : state.quarter,
                          year: state.year,
                        }));
                        setState({
                          ...state,
                          reportId: report.id,
                        });
                      }}
                    >
                      {report.exportable && (isFetchingExport
                        ? <CircularProgress size={24} />
                        : <IconExport size={24} />)}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={formatMessage({
                      id: 'downloadReport',
                    })}
                  >
                    <IconButton
                      disabled={isFetchingDownload}
                      disableHoverSpace
                      onClick={() => {
                        const isMonthly = state
                          .dateRange === dateRangeTypes.MONTHLY;
                        dispatch(actionsReports.fetchDownloadReport({
                          id: report.id,
                          month: isMonthly ? state.month : null,
                          quarter: isMonthly ? null : state.quarter,
                          year: state.year,
                        }));
                        setState({
                          ...state,
                          reportId: report.id,
                        });
                      }}
                    >
                      {isFetchingDownload && report.id === state.reportId
                        ? <CircularProgress size={24} />
                        : <IconDownload size={24} />}
                    </IconButton>
                  </Tooltip>
                </div>
              </CardTitle>
            </Card>
          ))}
        </div>
      )}

      {/* ALERTS */}
      <Snackbar open={state.showSuccessAfterExportAlert}>
        <Card variant="success">
          <CardTitle>
            <div className={classes.exportSuccess}>
              <Typography color="success">
                {formatMessage({
                  id: 'export.success',
                })}
              </Typography>
              <Link
                href={exportedReportFolderLink}
                target="_blank"
                underline
              >
                <Typography color="success">
                  {formatMessage({
                    id: 'folder.link',
                  })}
                </Typography>
              </Link>
            </div>
            <IconButton
              disableHoverSpace
              onClick={() => setState({
                ...state,
                showSuccessAfterExportAlert: false,
              })}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar open={state.showErrorAfterSaveAlert}>
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {errorMessage
                ? formatMessage(
                  { id: 'error' },
                  { error: errorMessage }
                )
                : formatMessage({ id: 'export.error' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={() => setState({
                ...state,
                showErrorAfterSaveAlert: false,
              })}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default Reports;
