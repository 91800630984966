import React from 'react';
import { makeStyles } from 'tss-react/mui';
import MenuItemMUI from '@mui/material/MenuItem';
import useTheme from 'hooks/useTheme';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
}));

function MenuItem({
  children,
  onClick,
  selected = false,
  value,
}: IProps) {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);

  return (
    <MenuItemMUI
      onClick={onClick}
      selected={selected}
      value={value}
    >
      <div className={classes.container}>
        {children}
      </div>
    </MenuItemMUI>
  );
}

interface IProps {
  children: React.ReactNode,
  onClick?: (event: any) => void,
  selected?: boolean,
  value?: any,
}

export default MenuItem;
