import React from 'react';
import TableCellMui from '@mui/material/TableCell';
import useTheme from 'hooks/useTheme';
import { makeStyles } from 'tss-react/mui';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  container: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
  },
}));


const TableCell = ({
  align = 'inherit',
  children,
  variant = 'body',
}: IProps) => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  return (
    <TableCellMui
      align={align}
      className={classes.container}
      variant={variant}
    >
      {children}
    </TableCellMui>
  );
};

interface IProps {
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  children: React.ReactNode | React.ReactNode[];
  variant?: 'body' | 'footer' | 'head';
}

export default TableCell;
