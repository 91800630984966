import {
  ERROR_CREATE_COMPENSATION,
  ERROR_DELETE_COMPENSATION,
  ERROR_RECEIVE_COMPENSATIONS,
  ERROR_UPDATE_COMPENSATION,
  RECEIVE_COMPENSATIONS,
  REQUEST_COMPENSATIONS,
  REQUEST_CREATE_COMPENSATION,
  REQUEST_DELETE_COMPENSATION,
  REQUEST_UPDATE_COMPENSATION,
  RESET_REDUCERS_DATA,
  SUCCESS_CREATE_COMPENSATION,
  SUCCESS_DELETE_COMPENSATION,
  SUCCESS_UPDATE_COMPENSATION,
} from '../constants/actionTypes';

import moment from 'moment';

type Action = {
  payload: any,
  type: string,
}

const getMonth = (value: any) => !!value
  ? moment(value).month() + 1
  : value;

const getYear = (value: any) => !!value
  ? moment(value).year()
  : value;

const convertCompensation = (compensation: any) => ({
  comment: compensation.comment,
  currency: compensation.currency,
  duration: compensation.periodicity.duration,
  fromMonth: getMonth(compensation.periodicity.from),
  fromYear: getYear(compensation.periodicity.from),
  id: compensation.id,
  rateMeasure: compensation.rateMeasure,
  sum: compensation.sum,
  toMonth: getMonth(compensation.periodicity.to),
  toYear: getYear(compensation.periodicity.to),
  typeId: compensation.typeId,
});

const initialState = {
  errorMessage: '',
  isFailed: false,
  isFailedCreate: false,
  isFailedDelete: false,
  isFailedUpdate: false,
  isFetching: false,
  isFetchingCreate: false,
  isFetchingDelete: false,
  isFetchingUpdate: false,
  isFinishedCreate: false,
  isFinishedDelete: false,
  isFinishedUpdate: false,
  list: [],
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_COMPENSATIONS: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_COMPENSATIONS: {
      const compensations = action.payload;

      const list = (compensations || [])
        .map((compensation: any) => convertCompensation(compensation))
        .reverse();

      return {
        ...state,
        isFetching: false,
        list,
      };
    }

    case ERROR_RECEIVE_COMPENSATIONS: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_UPDATE_COMPENSATION: {
      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: true,
        isFinishedUpdate: false,
      };
    }

    case SUCCESS_UPDATE_COMPENSATION: {
      const compensations = action.payload;

      const list = compensations
        .map((compensation: any) => convertCompensation(compensation))
        .reverse();

      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
        list,
      };
    }

    case ERROR_UPDATE_COMPENSATION: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedUpdate: true,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
      };
    }

    case REQUEST_CREATE_COMPENSATION: {
      return {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: true,
        isFinishedCreate: false,
      };
    }

    case SUCCESS_CREATE_COMPENSATION: {
      const compensations = action.payload;

      const list = compensations
        .map((compensation: any) => convertCompensation(compensation))
        .reverse();

      return {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: false,
        isFinishedCreate: true,
        list,
      };
    }

    case ERROR_CREATE_COMPENSATION: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedCreate: true,
        isFetchingCreate: false,
        isFinishedCreate: true,
      };
    }

    case REQUEST_DELETE_COMPENSATION: {
      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: true,
        isFinishedDelete: false,
      };
    }

    case SUCCESS_DELETE_COMPENSATION: {
      const compensations = action.payload;

      const list = compensations
        .map((compensation: any) => convertCompensation(compensation))
        .reverse();

      return {
        ...state,
        errorMessage: '',
        isFailedDelete: false,
        isFetchingDelete: false,
        isFinishedDelete: true,
        list,
      };
    }

    case ERROR_DELETE_COMPENSATION: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedDelete: true,
        isFetchingDelete: false,
        isFinishedDelete: true,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
