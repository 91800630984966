export const REQUEST_SETTINGS_LIST = 'REQUEST_SETTINGS_LIST';
export const RECEIVE_SETTINGS_LIST = 'RECEIVE_SETTINGS_LIST';
export const ERROR_RECEIVE_SETTINGS_LIST = 'ERROR_RECEIVE_SETTINGS_LIST';

export const REQUEST_SETTINGS_DETAILS = 'REQUEST_SETTINGS_DETAILS';
export const RECEIVE_SETTINGS_DETAILS = 'RECEIVE_SETTINGS_DETAILS';
export const ERROR_RECEIVE_SETTINGS_DETAILS = 'ERROR_RECEIVE_SETTINGS_DETAILS';

export const REQUEST_UPDATE_SETTINGS = 'REQUEST_UPDATE_SETTINGS';
export const SUCCESS_UPDATE_SETTINGS = 'SUCCESS_UPDATE_SETTINGS';
export const ERROR_UPDATE_SETTINGS = 'ERROR_UPDATE_SETTINGS';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
