import { combineReducers } from 'redux';

import invoices from '../reducers/invoices';


const rootReducer = combineReducers({
  invoices,
});

export default rootReducer;
