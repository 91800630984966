import axios from 'axios';
import config from 'config';
import storage from 'utils/storage';
import {
  SUCCESS_SIGN_OUT,
} from 'app/constants/actionTypes';

axios.interceptors.request.use((params) => ({
  ...params,
  withCredentials: 'withCredentials' in params
    ? params.withCredentials
    : true,
}));

const signOut = (store: any) => {
  return axios.post(
    `${config.BASE_URL}${config.AUTH_SERVICE}/logout`,
    null
  ).finally(() => {
    storage.removeItem('isAuthorized');
    store.dispatch({
      type: SUCCESS_SIGN_OUT,
    });
  });
};

const addReduxInterceptors = (store: any) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      switch (error.response?.status) {
        case 401: {
          signOut(store);
          return Promise.reject(error);
        }
        default: {
          return Promise.reject(error);
        }
      }
    }
  );
};

export {
  addReduxInterceptors
};

export default axios;
