import React from 'react';
import { makeStyles } from 'tss-react/mui';
import TooltipMui from '@mui/material/Tooltip';
import useTheme from '../../hooks/useTheme';

const getClasses = makeStyles<any>()((_) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
  },
}));

type TPlacement = 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

const Tooltip = ({
  arrow = false,
  children,
  placement = 'bottom',
  title,
}: IProps) => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);

  return (
    <TooltipMui
      arrow={arrow}
      disableInteractive
      title={title}
      placement={placement}
    >
      <div className={classes.container}>
        {children}
      </div>
    </TooltipMui>
  );
};

interface IProps {
  arrow?: boolean,
  children: React.ReactNode,
  placement?: TPlacement,
  title: React.ReactNode,
}

export default Tooltip;
