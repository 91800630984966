export const ACTIVE = 'ACTIVE';
export const DECLINED = 'DECLINED';
export const INACTIVE = 'INACTIVE';
export const PENDING = 'PENDING';

const LIST = [
  ACTIVE,
  DECLINED,
  INACTIVE,
  PENDING,
];

export default LIST;
