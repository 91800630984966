import React from 'react';
import ChipMui from '@mui/material/Chip';
import useTheme from 'hooks/useTheme';

const Chip = ({
  color = 'default',
  disabled = false,
  label,
  onClick,
}: IProps) => {
  const { theme } = useTheme();
  return (
    <ChipMui
      color={color}
      disabled={disabled}
      label={label}
      onClick={onClick}
      size="small"
      sx={{ borderRadius: `${theme.spacing(0.5)}px` }}
    />
  );
};

interface IProps {
  color?: 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
  disabled?: boolean,
  label: string,
  onClick?: (event: any) => void,
}

export default Chip;
