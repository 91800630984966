import {
  ERROR_RECEIVE_EXPENSES,
  ERROR_UPDATE_EXPENSES,
  RECEIVE_EXPENSES,
  REQUEST_EXPENSES,
  REQUEST_UPDATE_EXPENSES,
  RESET_REDUCERS_DATA,
  SUCCESS_UPDATE_EXPENSES,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  isFailed: false,
  isFailedUpdate: false,
  isFetching: false,
  isFetchingUpdate: false,
  isSuccessUpdate: false,
  list: [],
};

const convertExpenseItem = (item: any) => ({
  monthNumber: item.monthNumber,
  sumFact: item.sumFact,
  sumPlanned: item.sumPlanned,
});

const convertExpense = (expense: any) => ({
  comment: expense.comment,
  currency: expense.currency,
  id: expense.costItemId,
  monthSums: expense.monthSums
    .map((item : any) => convertExpenseItem(item)),
  name: expense.costItemName,
});

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_EXPENSES: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_EXPENSES: {
      const expenses = action.payload;
      const list = expenses.map((expense: any) => convertExpense(expense));

      return {
        ...state,
        isFetching: false,
        list,
      };
    }

    case ERROR_RECEIVE_EXPENSES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_UPDATE_EXPENSES: {
      return {
        ...state,
        isFailedUpdate: false,
        isFetchingUpdate: true,
        isSuccessUpdate: false,
      };
    }

    case SUCCESS_UPDATE_EXPENSES: {
      const expenses = action.payload;
      const list = expenses.map((expense: any) => convertExpense(expense));

      return {
        ...state,
        isFetchingUpdate: false,
        isSuccessUpdate: true,
        list,
      };
    }

    case ERROR_UPDATE_EXPENSES: {
      return {
        ...state,
        isFailedUpdate: true,
        isFetchingUpdate: false,
        isSuccessUpdate: false,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
