import { PopoverProps } from '@mui/material/Popover';
import React from 'react';
import PopperMUI from '@mui/material/Popper';

type TPlacement = 'auto-end'
  | 'auto-start'
  | 'auto'
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

function Popper({
  anchorEl,
  children,
  open,
  placement = 'auto',
}: IProps) {
  return (
    <PopperMUI
      anchorEl={anchorEl}
      open={open}
      placement={placement}
      sx={{
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),' +
          '0px 1px 1px 0px rgba(0,0,0,0.14),' +
          '0px 1px 3px 0px rgba(0,0,0,0.12)',
        pointerEvents: 'none',
      }}
    >
      {children}
    </PopperMUI>
  );
}

interface IProps {
  anchorEl: PopoverProps['anchorEl'],
  children: React.ReactElement,
  open: boolean,
  placement?: TPlacement,
}

export default Popper;
