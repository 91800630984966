import React from 'react';
import TabsMui from '@mui/material/Tabs';
import useTheme from 'hooks/useTheme';

const Tabs = ({
  children,
  onChange,
  value,
}: IProps) => {
  const { theme } = useTheme();
  return (
    <TabsMui
      onChange={onChange}
      sx={{
        background: `${theme.tabs.background}`,
        boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.2)',
      }}
      value={value}
    >
      {children}
    </TabsMui>
  );
};

interface IProps {
  children: React.ReactNode,
  onChange: (event: React.SyntheticEvent, value: any) => void,
  value: string,
}

export default Tabs;
