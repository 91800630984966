import React from 'react';
import authoritiesUI from 'constants/authoritiesUI';
import ClientsAndProjectsPage from 'pages/clientsAndProjects';
import PageContainer from 'components/PageContainer';
import PageAccessValidator from 'components/PageAccessValidator';

function ClientsAndProjects() {
  return (
    <PageContainer>
      <PageAccessValidator
        neededAuthorities={authoritiesUI.CLIENTS_AND_PROJECTS}
      >
        <ClientsAndProjectsPage />
      </PageAccessValidator>
    </PageContainer>
  );
}

export default ClientsAndProjects;
