export const REQUEST_ITEMS = 'REQUEST_ITEMS';
export const RECEIVE_ITEMS = 'RECEIVE_ITEMS';
export const ERROR_RECEIVE_ITEMS = 'ERROR_RECEIVE_ITEMS';

export const REQUEST_CREATE_ITEM = 'REQUEST_CREATE_ITEM';
export const SUCCESS_CREATE_ITEM = 'SUCCESS_CREATE_ITEM';
export const ERROR_CREATE_ITEM = 'ERROR_CREATE_ITEM';

export const REQUEST_UPDATE_ITEM = 'REQUEST_UPDATE_ITEM';
export const SUCCESS_UPDATE_ITEM = 'SUCCESS_UPDATE_ITEM';
export const ERROR_UPDATE_ITEM = 'ERROR_UPDATE_ITEM';

export const REQUEST_DELETE_ITEM = 'REQUEST_DELETE_ITEM';
export const SUCCESS_DELETE_ITEM = 'SUCCESS_DELETE_ITEM';
export const ERROR_DELETE_ITEM = 'ERROR_DELETE_ITEM';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
