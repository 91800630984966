import React from 'react';
import { makeStyles } from 'tss-react/mui';
import TableCellMui from '@mui/material/TableCell';
import useTheme from '../../hooks/useTheme';

/**
 * Component that MUST be contained by TableHead and TableRow
 * this component is adding a style to fix the colum width into TableCell
 * <TableHead>
 *   <TableRow>
 *     <TableCellHead></TableCellHead>
 *   </TableRow>
 * </TableHead>
 */
const getClasses = makeStyles<any>()((_, theme: any) => ({
  bars: {
    width: '80px',
  },
  bigField: {
    width: '250px',
  },
  change: {
    width: '50px',
  },
  compactField: {
    width: '128px',
  },
  icon: {
    width: '24px',
  },
  largeField: {
    width: '350px',
  },
  mediumField: {
    width: '150px',
  },
  smallChange: {
    width: '30px',
  },
  smallField: {
    width: '100px',
  },
  value: {
    width: '60px',
  },
}));

export const compactVariants = {
  dynamic: 'dynamic',
  static: 'static',
};

const TableCellHead = ({
  children,
  compactVariant,
  size,
  sortDirection = false,
}: IProps) => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);

  return (
    <TableCellMui
      className={ size ? classes[size] : '' }
      variant="head"
      sortDirection={sortDirection}
    >
      {children}
    </TableCellMui>
  );
};

type CompactVariant = typeof compactVariants[keyof typeof compactVariants]

interface IProps {
  children: React.ReactNode,
  compactVariant?: CompactVariant,
  size?: 'bars'
    | 'bigField'
    | 'change'
    | 'compactField'
    | 'icon'
    | 'largeField'
    | 'mediumField'
    | 'smallChange'
    | 'smallField'
    | 'value',
  sortDirection?: 'asc' | 'desc' | false,
}
export default TableCellHead;
